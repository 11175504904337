package net.gorillagroove.db

import kotlin.Double
import kotlin.String

public data class GetEstimatedByteUsage(
  public val expr: Double?
) {
  public override fun toString(): String = """
  |GetEstimatedByteUsage [
  |  expr: $expr
  |]
  """.trimMargin()
}
