package net.gorillagroove.sync

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.serialization.Serializable
import net.gorillagroove.util.GGLog.logError
import kotlin.jvm.JvmInline

enum class OfflineAvailabilityType(
    val displayName: String,
) {
    NORMAL("Cached Temporarily"),
    AVAILABLE_OFFLINE("Always Available Offline"),
    ONLINE_ONLY("Online Only"),
    UNKNOWN("Unknown")
    ;

    fun toRawType() = RawOfflineAvailabilityType(this.name)

    companion object {
        fun findByDisplayName(displayName: String): OfflineAvailabilityType {
            return OfflineAvailabilityType.entries.find { it.displayName == displayName }
                ?: throw IllegalArgumentException("No OfflineAvailabilityType found for displayName $displayName!")
        }

        fun displayedTypes(): List<OfflineAvailabilityType> {
            return listOf(AVAILABLE_OFFLINE, NORMAL, ONLINE_ONLY)
        }
    }
}

@Serializable
@JvmInline
value class RawOfflineAvailabilityType(val value: String) {
    fun asEnumeratedType(): OfflineAvailabilityType {
        return try {
            OfflineAvailabilityType.valueOf(value)
        } catch (e: Throwable) {
            this.logError("Unknown enumerated type value '$value' for enum '${OfflineAvailabilityType::class.simpleName}'")
            OfflineAvailabilityType.UNKNOWN
        }
    }
}


object RawOfflineAvailabilityTypeAdapter : ColumnAdapter<RawOfflineAvailabilityType, String> {
    override fun decode(databaseValue: String) = RawOfflineAvailabilityType(databaseValue)
    override fun encode(value: RawOfflineAvailabilityType) = value.value
}
