@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import mainScope
import net.gorillagroove.discovery.ImportService
import net.gorillagroove.discovery.TrackImportRequest
import net.gorillagroove.track.MetadataService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onClickSuspend
import onInputSuspend
import onSubmitSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import queryId
import show


private val urlSpinner get() = document.querySelector("#youtube-download .loading-spinner") as HTMLElement?
private val playlistSpinner get() = document.getElementById("playlist-download-spinner") as HTMLElement?

private val urlInput get() = document.getElementById("track-url") as HTMLInputElement
private val nameInput get() = document.getElementById("track-name") as HTMLInputElement
private val artistInput get() = document.getElementById("track-artist") as HTMLInputElement
private val featuringInput get() = document.getElementById("track-featuring") as HTMLInputElement
private val albumInput get() = document.getElementById("track-album") as HTMLInputElement
private val trackNumberInput get() = document.getElementById("track-number") as HTMLInputElement
private val releaseYearInput get() = document.getElementById("track-year") as HTMLInputElement
private val genreInput get() = document.getElementById("track-genre") as HTMLInputElement

var metadataPreviewJob: Job? = null

fun YouTubeDownload() = document.create.div {
    id = "youtube-download"

    metadataPreviewJob?.cancel()

    form {
        onSubmitSuspend = {
            if (isPlaylistDownload()) {
                Dialog.show(PlaylistDownloadConfirmation())
            } else {
                doTheDownload()
            }
        }

        div("space-between") {
            id = "youtube-download-url-section"

            span("align-center") {
                label {
                    htmlFor = "track-url"

                    +"URL"
                }

                LoadingSpinner()
            }
            input(InputType.text) {
                id = "track-url"
                name = "track-url"

                required = true

                onInputFunction = {
                    metadataPreviewJob?.cancel()
                    metadataPreviewJob = mainScope.launch {
                        fetchMetadataPreview()
                    }
                }
            }
        }

        h4 {
            +"Optional Metadata"
        }

        small {
            +"An attempt to automatically parse metadata will be performed if fields are left empty"
        }

        Section("track-name", "Name")
        Section("track-artist", "Artist")
        Section("track-featuring", "Featuring")
        Section("track-album", "Album")
        Section("track-year", "Release Year")
        Section("track-number", "Track Number")
        Section("track-genre", "Genre")

        hr {}

        div {
            +"Crop art to square?"

            input(InputType.radio) {
                id = "crop-yes"
                name = "crop"
                value = "true"
                checked = true
            }
            label {
                htmlFor = "crop-yes"

                +"Yes"
            }

            input(InputType.radio) {
                id = "crop-no"
                name = "crop"
                value = "false"
                checked = false
            }
            label {
                htmlFor = "crop-no"

                +"No"
            }
        }

        div {
            ActionButton("youtube-download-button", "Download")
        }
    }
}

private fun PlaylistDownloadConfirmation() = document.create.div {
    id = "playlist-download"

    h3 {
        + "Playlist Download"
    }

    div("text-left") {
        label {
            id = "track-number-download-checkbox"

            input(InputType.checkBox)

            +"Use playlist order as track number"

            i("fa-solid fa-circle-question") {
                tooltip = "When checked, the metadata for the 'Track Number' property will be filled in based off the order in the YouTube playlist.\nEven if the automatic metadata parsing finds an album, it will be ignored in favor of the YouTube order"
                tooltipDelay = 100
            }
        }
    }

    div("text-left") {
        + "You are about to download every video off of this playlist. Are you sure that this is what you want to do?"
    }

    div("space-between") {
        button(classes = "primary") {
            + "Yes"

            onClickSuspend = {
                doTheDownload()
            }
        }

        button(classes = "primary") {
            + "No, just the one video only"

            onClickSuspend = {
                doTheDownload(stripPlaylist = true)
            }
        }

        button(classes = "flat") {
            + "What?"

            onClickFunction = {
                Dialog.remove()
            }
        }
    }

    div("d-none") {
        id = "playlist-download-spinner"

        div {
            + "Starting playlist download. This could take a moment."
        }

        div {
            LoadingSpinner(hidden = false)
        }
    }

    mainScope.launch {
        Tooltip.registerAll(document.queryId("playlist-download"))
    }
}

private fun FlowContent.Section(id: String, label: String) {
    div("space-between") {
        label {
            htmlFor = id

            + label
        }
        input(InputType.text) {
            this.id = id
            name = id
        }
    }
}

private fun isPlaylistDownload(): Boolean {
    val url = urlInput.value
    return url.contains("&list") || url.contains("?list")
}

private suspend fun fetchMetadataPreview() {
    val term = urlInput.value

    // No point in fetching preview of playlists
    if (term.contains("list")) {
        urlSpinner?.hide()
        return
    }

    if (term.isBlank()) {
        urlSpinner?.hide()
        return
    }

    urlSpinner!!.show()

    val response = try {
        MetadataService.getYouTubeMetadataPreview(term)
    } finally {
        // Things can get a little weird when you change the input multiple times
        if (term == urlInput.value) {
            urlSpinner?.hide()
        }
    }

    fun HTMLInputElement.setIfBlank(newValue: String) {
        this.value.ifBlank { this.value = newValue }
    }

    nameInput.setIfBlank(response.name)
    artistInput.setIfBlank(response.artist ?: "")
    albumInput.setIfBlank(response.album ?: "")
    releaseYearInput.setIfBlank(response.releaseYear?.toString() ?: "")
    trackNumberInput.setIfBlank(response.trackNumber?.toString() ?: "")
}

private fun isCroppedToSquare(): Boolean {
    val selectedRadio = document.querySelector("input[name=\"crop\"]:checked") as HTMLInputElement
    return selectedRadio.value.toBooleanStrict()
}

private suspend fun doTheDownload(
    usePlaylistOrderAsTrackNumber: Boolean = false,
    stripPlaylist: Boolean = false,
) {
    if (urlInput.value.isBlank()) {
        Toast.info("URL must not be blank")
        return
    }

    if (isActionButtonUpdating("youtube-download-button")) {
        return
    }


    val request = TrackImportRequest(
        url = urlInput.value,
        name = nameInput.value.takeIf { it.isNotBlank() },
        artist = artistInput.value.takeIf { it.isNotBlank() },
        featuring = featuringInput.value.takeIf { it.isNotBlank() },
        album = albumInput.value.takeIf { it.isNotBlank() },
        releaseYear = releaseYearInput.value.takeIf { it.isNotBlank() }?.toIntOrNull(),
        trackNumber = trackNumberInput.value.takeIf { it.isNotBlank() }?.toIntOrNull(),
        genre = genreInput.value.takeIf { it.isNotBlank() },
        cropArtToSquare = isCroppedToSquare(),
        usePlaylistOrderAsTrackOrder = usePlaylistOrderAsTrackNumber,
    )

    try {
        if (isPlaylistDownload()) {
            playlistSpinner!!.show()
        } else {
            actionButtonChangeState("youtube-download-button", isUpdating = true)
        }

        ImportService.downloadFromUrl(request, stripPlaylist)
    } catch (e: Exception) {
        GGLog.logError("Failed to start track download", e)
        Toast.error("Failed to start track download")

        playlistSpinner?.hide()
        actionButtonChangeState("youtube-download-button", isUpdating = false)

        return
    }

    Toast.info("Download started")

    Dialog.removeAll()
}
