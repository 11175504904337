@file:Suppress("FunctionName")

package components

import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import net.gorillagroove.track.Track
import net.gorillagroove.track.TrackService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onSubmitSuspend
import org.w3c.dom.HTMLInputElement
import query

fun TrackTrimModal(track: Track) = document.create.div {
    id = "trim-track-modal"

    h3 {
        + "Trim Track"
    }

    hr {  }

    div("small-text") {
        div { + "Minutes and seconds are required (e.g. 2:23)." }
        div { + "Milliseconds can be specified (e.g. 2:23.125)" }
        div { + "Hours can be specified (e.g. 1:02:03)" }
        div { + "Only one input needs to be filled out (but both can be)" }
    }

    hr {  }

    form {
        onSubmitSuspend = onSubmit@ {
            if (isActionButtonUpdating("trim-track-button")) {
                return@onSubmit
            }

            val start = document.query<HTMLInputElement>("#track-trim-start-time input").value.takeIf { it.isNotBlank() }
            val duration = document.query<HTMLInputElement>("#track-trim-duration input").value.takeIf { it.isNotBlank() }

            if (start == null && duration == null) {
                Toast.info("Start Time or Duration are required")
            } else {
                actionButtonChangeState("trim-track-button", isUpdating = true)

                try {
                    TrackService.trimTrack(track, start, duration)

                    Toast.success("Track trimmed successfully")

                    Dialog.remove()
                } catch (e: Exception) {
                    GGLog.logError("Failed to trim track!", e)
                    Toast.error("Failed to trim track")

                    actionButtonChangeState("trim-track-button", isUpdating = false)
                }
            }
        }

        label("space-between") {
            +"Start Time"

            id = "track-trim-start-time"

            input(classes = "ml-12", type = InputType.text) {
                placeholder = "0:20"
            }
        }

        label("space-between mt-6") {
            +"Duration"

            id = "track-trim-duration"

            input(classes = "ml-12", type = InputType.text) {
                placeholder = "5:02.500"
            }
        }

        div("mt-12") {
            ActionButton(id = "trim-track-button", "Trim")
        }
    }
}
