package net.gorillagroove.track

import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import net.gorillagroove.api.Api
import net.gorillagroove.api.TrackId
import net.gorillagroove.api.isBenignException
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logError

// It's kind of weird, but I am allowing 'track' to be nullable because the clients make
// a fake album for 'View All' functionality. It helps simplify the UI code, so I guess it's worth.
data class Album(val name: String, internal val track: Track?) {

    val trackId: TrackId? get() = track?.id

    private val lock = Mutex()

    /**
     * Gets the album art thumbnail and caches it for next time
     */
    // Providing <ByteArray?> generic to the lock is apparently required or else
    // in JS, it will return Unit instead of null for some reason.
    suspend fun getThumbnailAlbumArt(): ByteArray? = lock.withLock {
        val track = track ?: return null

        if (!track.isOwnTrack()) {
            val cachedArt = UserTrackService.getCachedArt(track.id)
            if (cachedArt != null) {
                return@withLock cachedArt
            }
        }

        val data = try {
            TrackService.getTrackData(track, setOf(TrackLinkType.THUMBNAIL_PNG))
        } catch (e: Exception) {
            if (e.isBenignException()) {
                logError("Could not get album art for benign reason")
            } else {
                logCrit("Could not get album art for an unknown reason!", e)
            }
            return@withLock null
        }

        if (data.thumbnailArtPng != null) {
            return data.thumbnailArtPng
        }

        if (data.thumbnailArtLinkPng != null) {
            if (!this.track.isOwnTrack()) {
                return@withLock UserTrackService.getAlbumArt(track.id, data.thumbnailArtLinkPng)
            }

            TrackCacheService.cacheTrack(track.id, data.thumbnailArtLinkPng, TrackLinkType.THUMBNAIL_PNG)

            return TrackCacheService.getCacheItemIfAvailable(track.id, TrackLinkType.THUMBNAIL_PNG) ?: run {
                logError("Could not load track cache of ${track.id.value} after attempting to cache it!")
                null
            }
        }

        return null
    }
}
