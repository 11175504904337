package net.gorillagroove.util

import kotlinx.datetime.*

typealias Seconds = Long

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object TimeUtil {
    // This just exists for testing
    internal var timeOverride: Instant? = null
    internal var timeZoneOverride: TimeZone? = null

    fun now(): Instant {
        return timeOverride ?: Clock.System.now()
    }

    fun LocalDate.Companion.current(): LocalDate {
        return now().toLocalDateTime(timeZoneOverride ?: TimeZone.currentSystemDefault()).date
    }
}
