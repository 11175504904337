package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.db.Database
import net.gorillagroove.api.UserId
import net.gorillagroove.db.DbSyncStatus
import net.gorillagroove.db.DbUser
import net.gorillagroove.sync.*

object UserSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.USER

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<UserResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            Database.db.transaction {
                changeSet.newAndModified.forEach {
                    Database.userDao.upsert(it.asUser())
                }
                changeSet.removed.forEach {
                    Database.userDao.delete(UserId(it))
                }
            }
        }
    }
}

@Serializable
data class UserResponse(
    val id: UserId,
    val name: String,
    val lastActivity: Instant?,
    val createdAt: Instant,
) {
    fun asUser() = DbUser(
        id = id,
        name = name,
        lastActivity = lastActivity,
        createdAt = createdAt
    )
}
