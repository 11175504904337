package net.gorillagroove.sync

import net.gorillagroove.sync.SyncDirection.*
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.Lock
import net.gorillagroove.util.use

/**
 * This is the priority queue that the SyncCoordinator uses to prioritize work. Multiple sync types,
 * directions, and explicit priories can be added, and the TaskQueue will figure it all out.
 *
 * Priority is based off of the following criteria IN ORDER:
 * 1) Tasks may not be considered at all while they are dependent on an Active task
 * 2) An explicitly high priority task is considered before a low priority one. This happens if the
 *    code that started the sync marked it as a high priority sync, which is usually a foreground action.
 * 3) Implicitly higher priority tasks happen before lower ones. Not all syncable entities are created
 *    equal; e.g. track changes are considered more important than review sources
 * 4) The task with the greater number of dependents syncs first. This is to help speed up the
 *    sync process in a multithreaded scenario, as it will unblock the largest amount of sync
 *    tasks for other threads to work on.
 *
 *  NOTE: This class is thread-safe, and access does not need to be synchronized.
 */
internal class TaskQueue {
    private val lock: Lock = Lock()

    private enum class AddTaskAction {
        ADD_DEPENDENCIES, UPDATE_PRIORITY
    }

    private val activeTasks = mutableListOf<SyncTask>()
    private val syncTasks = mutableListOf<SyncTask>()

    // This does not include active tasks
    val size: Int get() = syncTasks.size
    val hasPendingTasks: Boolean get() = size > 0
    val hasActiveTasks: Boolean get() = activeTasks.size > 0

    val syncDownTaskTypes: List<TaskQueueEntity>
        get() = (activeTasks + syncTasks).filter { it.syncDirection.includes(DOWN) }.map { it.type }

    fun addTasks(newSyncTasks: List<SyncTask>) = lock.use {
        logDebug("Adding new sync tasks: $newSyncTasks")

        val existingTasksByType = syncTasks.associateBy { it.type }
        val newTasksByType = newSyncTasks.associateBy { it.type }

        // STEP 1
        // Categorize what we need to do in order to accommodate this task.
        // The overwhelming majority of the time, the queue will be empty and everything will be ADD_DEPENDENCIES.
        // However, it is possible for existing sync entities to be pending. If it is, we need to check
        // if there are any updates that need to be made to that sync task to merge it with our new task.
        // If the old task was a sync down, but this one is a sync up, we need to merge these requests.
        // Or if both are sync down requests, we must make sure to keep the higher priority task.
        val newTasks = newSyncTasks.mapNotNull { newTask ->
            val existingTask = existingTasksByType[newTask.type] ?: return@mapNotNull newTask to AddTaskAction.ADD_DEPENDENCIES
            if (!existingTask.syncDirection.includes(newTask.syncDirection)) {
                return@mapNotNull newTask to AddTaskAction.ADD_DEPENDENCIES
            }
            return@mapNotNull if (existingTask.totalPriority < newTask.totalPriority) {
                logDebug("${newTask.type} task already exists, but its priority will be updated")
                newTask to AddTaskAction.UPDATE_PRIORITY
            } else {
                logDebug("Ignoring ${newTask.type} task as it is already covered")
                null
            }
        }.toMutableList()

        // STEP 2
        // Find unfulfilled sync dependencies and add them to our tasks that we must process.
        // e.g., if we were told to sync PlaylistTracks, then we better make sure to
        // sync Playlists and Tracks prior as PlaylistTracks rely on those
        val iterator = newTasks.listIterator()
        iterator.forEach { (newTask, action) ->
            // If the only thing changing is priority, then we do not need to add dependents.
            // Whatever dependents were on the queue already are fine.
            if (action == AddTaskAction.UPDATE_PRIORITY) {
                return@forEach
            }

            val existingTask = existingTasksByType[newTask.type]

            // We only need to add dependencies if this particular direction was not already covered.
            // AT LEAST one of these cases should always be true by this point.
            // Both will be true if this is a "BOTH" direction, with no existing sync task
            val shouldAddSyncUpTasks = newTask.supports(UP) && existingTask?.supports(UP) != true
            val shouldAddSyncDownTasks = newTask.supports(DOWN) && existingTask?.supports(DOWN) != true

            val dependencies = newTask.type.syncUpDependencies.map { it to UP } +
                    newTask.type.syncDownDependencies.map { it to DOWN }

            dependencies.forEach dependencyAdd@ { (dependency, direction) ->
                // If we are not adding the direction, then abort early
                if ((direction == UP && !shouldAddSyncUpTasks) || (direction == DOWN && !shouldAddSyncDownTasks)) {
                    return@dependencyAdd
                }

                // If this dependency is already covered by a new task we are trying to add,
                // then there is no need to add it a 2nd time.
                if (newTasksByType[dependency]?.supports(direction) == true) {
                    return@dependencyAdd
                }

                val newDependency = SyncTask(
                    type = dependency,
                    explicitPriority = newTask.explicitPriority,
                    syncDirection = direction,
                )
                // We add these to the iterator as we are iterating. This is crucial, as it will have
                // this task come into the loop later, and add its own dependencies recursively.
                iterator.add(newDependency to action)
                // If we don't do this, we will skip over the stuff we just added.
                iterator.previous()
            }
        }

        // STEP 3
        // Merge all tasks together that can be merged.
        // If tasks are the same entity and direction, they "merge" by taking the highest priority task.
        // If tasks are the same entity but differing directions, they create a new task that combines
        // their directions and takes the highest priority.
        // This has a side effect of a prior, normal priority sync down will become a high priority sync
        // if we add a high priority sync up. But this seems acceptable to keep sync up and down together.
        val typeToTasks = ((newTasks.map { it.first }) + syncTasks).groupBy { it.type }

        val mergedTasks = typeToTasks.map { (type, tasks) ->
            if (tasks.size == 1) {
                return@map tasks.first()
            }

            // If all tasks we are merging have the same direction, keep it. Else we are doing a 'BOTH'
            val direction = if (tasks.all { it.syncDirection == tasks.first().syncDirection }) {
                tasks.first().syncDirection
            } else {
                BOTH
            }

            val priority = tasks.maxOf { it.explicitPriority }

            return@map SyncTask(
                type = type,
                explicitPriority = priority,
                syncDirection = direction,
            )
        }

        // STEP 4
        // We now need to set up the dependency relationships between the merged tasks.
        // This is how tasks can guarantee they are processed before their dependents.
        val typeToMergedTask = mergedTasks.associateBy { it.type }
        mergedTasks.forEach { it.dependentTasks.clear() }
        mergedTasks.forEach { task ->
            val dependentTypes = mutableSetOf<TaskQueueEntity>()
            if (task.syncDirection.includes(UP)) {
                dependentTypes.addAll(task.type.syncUpDependencies)
            }
            if (task.syncDirection.includes(DOWN)) {
                dependentTypes.addAll(task.type.syncDownDependencies)
            }

            dependentTypes.forEach { type ->
                // Keep in mind that this can actually be null. There is no guarantee that
                // a dependent task is here, as it could have already completed if our queue
                // was not empty when we started adding things.
                typeToMergedTask[type]?.dependentTasks?.add(task)
            }
        }

        // STEP 5
        // With everything set, we can now sort our tasks and be done
        syncTasks.clear()

        val newTaskOrder = mergedTasks.sortedByDescending { it.totalPriority }
        logDebug("New task order: $newTaskOrder")
        syncTasks.addAll(newTaskOrder)
    }

    fun startTask(): SyncTask? = lock.use {
        val invalidEntityTypes = activeTasks.flatMap { task ->
            task.dependentTasks.map { it.type }
        }.toSet()

        val firstValidTaskIndex = syncTasks
            .indexOfFirst { !invalidEntityTypes.contains(it.type) }
            .takeIf { it > -1 }
            ?: return null

        val nextTask = syncTasks.removeAt(firstValidTaskIndex)
        activeTasks.add(nextTask)

        return nextTask
    }

    fun completeTask(task: SyncTask) = lock.use {
        logDebug("Marking task complete: $task")

        activeTasks.remove(task)
    }

    fun failTask(task: SyncTask): Set<TaskQueueEntity> = lock.use {
        logDebug("Marking task as failed: $task")

        activeTasks.remove(task)

        // We now need to recursively fail every task that depended on this task
        val failedTasks = mutableSetOf(task.type)
        task.dependentTasks.forEach {
            failedTasks.addAll(failDependentTask(it))
        }
        return failedTasks
    }

    private fun failDependentTask(task: SyncTask): Set<TaskQueueEntity> {
        syncTasks.remove(task)

        val failedTasks = mutableSetOf(task.type)
        task.dependentTasks.forEach {
            failedTasks.addAll(failDependentTask(it))
        }
        return failedTasks
    }

    override fun toString(): String {
        return "Active tasks: $activeTasks. Pending tasks $syncTasks"
    }
}
