package net.gorillagroove.db

import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver
import net.gorillagroove.db.shared.newInstance
import net.gorillagroove.db.shared.schema

public interface GGDatabase : Transacter {
  public val dbFailedListenQueries: DbFailedListenQueries

  public val dbLogLineQueries: DbLogLineQueries

  public val dbPlaylistQueries: DbPlaylistQueries

  public val dbPlaylistTrackQueries: DbPlaylistTrackQueries

  public val dbPlaylistUserQueries: DbPlaylistUserQueries

  public val dbReviewSourceQueries: DbReviewSourceQueries

  public val dbSyncStatusQueries: DbSyncStatusQueries

  public val dbTrackQueries: DbTrackQueries

  public val dbUserQueries: DbUserQueries

  public val dbUserFavoriteQueries: DbUserFavoriteQueries

  public val dbUserPermissionQueries: DbUserPermissionQueries

  public val dbUserSettingQueries: DbUserSettingQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = GGDatabase::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      DbFailedListenAdapter: DbFailedListen.Adapter,
      DbLogLineAdapter: DbLogLine.Adapter,
      DbPlaylistAdapter: DbPlaylist.Adapter,
      DbPlaylistTrackAdapter: DbPlaylistTrack.Adapter,
      DbPlaylistUserAdapter: DbPlaylistUser.Adapter,
      DbReviewSourceAdapter: DbReviewSource.Adapter,
      DbSyncStatusAdapter: DbSyncStatus.Adapter,
      DbTrackAdapter: DbTrack.Adapter,
      DbUserAdapter: DbUser.Adapter,
      DbUserFavoriteAdapter: DbUserFavorite.Adapter,
      DbUserPermissionAdapter: DbUserPermission.Adapter,
      DbUserSettingAdapter: DbUserSetting.Adapter
    ): GGDatabase = GGDatabase::class.newInstance(driver, DbFailedListenAdapter, DbLogLineAdapter,
        DbPlaylistAdapter, DbPlaylistTrackAdapter, DbPlaylistUserAdapter, DbReviewSourceAdapter,
        DbSyncStatusAdapter, DbTrackAdapter, DbUserAdapter, DbUserFavoriteAdapter,
        DbUserPermissionAdapter, DbUserSettingAdapter)
  }
}
