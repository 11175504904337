package components

import components.contextmenu.Corner
import components.contextmenu.appendAtPosition
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.html.HTMLTag
import kotlinx.html.dom.create
import kotlinx.html.id
import kotlinx.html.js.div
import mainScope
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import org.w3c.dom.events.MouseEvent
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

object Tooltip {
    fun registerElement(element: HTMLElement) {
        element.onmouseenter = event@{ event ->
            val message = element.getAttribute("tooltip") ?: return@event Unit
            val delay = element.getAttribute("tooltip-delay")?.toInt() ?: 300

            showTooltip(event, message, delay.milliseconds)
        }
        element.onmouseleave = { removeTooltip() }
    }

    fun registerAll(parent: HTMLElement) {
        parent.querySelectorAll("[tooltip]").asList().forEach {
            registerElement(it as HTMLElement)
        }
    }

    private var tooltipWindow: HTMLElement? = null
    private var delayJob: Job? = null

    private fun showTooltip(event: MouseEvent, message: String, delayDuration: Duration?) {
        removeTooltip()

        val menu = document.create.div(classes = "tooltip popup") {
            id = "tooltip-display"

            + message
        }

        fun showTooltip() {
            menu.appendAtPosition(event, corner = Corner.BOTTOM_LEFT)
            tooltipWindow = menu
        }

        if (delayDuration != null) {
            delayJob = mainScope.launch {
                delay(delayDuration)
                showTooltip()
            }
        } else {
            showTooltip()
        }
    }

    private fun removeTooltip() {
        delayJob?.cancel()
        tooltipWindow?.remove()
        tooltipWindow = null
    }
}

var HTMLElement.tooltip: String?
    get() = getAttribute("tooltip")
    set(newValue) {
        if (newValue == null) {
            removeAttribute("tooltip")
        } else {
            setAttribute("tooltip", newValue)
        }
    }

var HTMLTag.tooltip: String
    get() = this.attributes["tooltip"] ?: ""
    set(newValue) {
        this.attributes["tooltip"] = newValue
    }

var HTMLTag.tooltipDelay: Int
    get() = this.attributes["tooltip-delay"]?.toInt() ?: 0
    set(newValue) {
        this.attributes["tooltip-delay"] = newValue.toString()
    }
