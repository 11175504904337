package components

import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onSubmitFunction
import mainScope
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.review.ReviewQueueService
import net.gorillagroove.review.ReviewSource
import net.gorillagroove.review.ReviewSourceType
import org.w3c.dom.*
import show

private val table get() = document.getElementById("review-source-edit-table") as HTMLTableElement
private val tableBody get() = table.querySelector("table tbody") as HTMLElement

private val newSourceInput get() = document.getElementById("review-source-add-new-input") as HTMLInputElement

@Suppress("FunctionName")
fun ReviewSourceEdit() = document.create.div {
    id = "review-source-edit"

    h1 {
        + "Review Sources"
    }

    table("data-table") {
        id = "review-source-edit-table"

        thead {
            tr {
                th { + "Type" }
                th { + "Source" }
                th { }
            }
        }
        tbody {

        }
    }

    button(classes = "flat") {
        id = "add-review-source-button"

        + "Add review source"

        i("fa-solid fa-plus")

        onClickFunction = {
            toggleEditMode(true)
        }
    }

    mainScope.launch {
        render()
    }
}

private fun render() {
    tableBody.innerHTML = ""

    tableBody.append {
        val sources = ReviewQueueService.getEditableSources().groupBy { it.sourceType }
        val keys = sources.keys.sortedBy { it.name }

        var index = 0
        keys.forEach { key ->
            sources.getValue(key).forEach { source ->
                val theIndex = index
                tr {
                    td {
                        + source.sourceType.displayName
                    }
                    td {
                        + source.displayName
                    }
                    td("final-column") {
                        button(classes = "icon") {
                            onClickFunction = { deleteReviewSource(theIndex, source) }
                            i("fa-solid fa-xmark")
                        }
                    }
                }
                index++
            }
        }
    }
}

private fun saveNew() {
    val select = document.getElementById("review-source-add-new-type-select") as HTMLSelectElement
    val type = ReviewSourceType.valueOf(select.value)

    if (newSourceInput.value.length > 1) {
        toggleLoadingState(true)

        mainScope.launch {
            try {
                if (type == ReviewSourceType.ARTIST) {
                    ReviewQueueService.subscribeToSpotifyArtist(newSourceInput.value)
                } else if (type == ReviewSourceType.YOUTUBE_CHANNEL) {
                    ReviewQueueService.subscribeToYoutubeChannel(newSourceInput.value)
                }

                render()
                toggleEditMode(false)

                Toast.success("Review source added")
            } catch (e: Exception) {
                Toast.error("Failed to add review source")
                toggleLoadingState(false)
            }
        }
    }
}

private fun toggleEditMode(editing: Boolean) {
    val addButton = document.getElementById("add-review-source-button") as HTMLElement

    if (!editing) {
        document.getElementById("review-source-add-new-row")?.remove()
        addButton.show()
        return
    }

    addButton.hide()

    val row = document.create.tr {
        id = "review-source-add-new-row"

        td {
            select {
                id = "review-source-add-new-type-select"

                ReviewSourceType.values()
                    .filter { it.creatable }
                    .sortedBy { it.displayName }
                    .forEach { type ->
                        option {
                            value = type.name
                            + type.displayName
                        }
                    }

                onChangeFunction = { event ->
                    val select = event.currentTarget as HTMLSelectElement
                    val type = ReviewSourceType.valueOf(select.value)

                    newSourceInput.placeholder = when (type) {
                        ReviewSourceType.ARTIST -> "Alestorm"
                        ReviewSourceType.YOUTUBE_CHANNEL -> "https://www.youtube.com/user/MrSuicideSheep"
                        else -> ""
                    }
                }
            }
        }
        td {
            form {
                onSubmitFunction = { event ->
                    event.preventDefault()

                    saveNew()
                }

                input {
                    id = "review-source-add-new-input"

                    placeholder = "Alestorm"
                }
            }
        }
        td("final-column") {
            button(classes = "icon") {
                id = "add-source-save-icon"

                i("fa-solid fa-save")

                onClickFunction = {
                    saveNew()
                }
            }
        }
    }

    tableBody.append(row)
}

private fun toggleLoadingState(loading: Boolean) {
    newSourceInput.readOnly = loading

    val saveButton = document.getElementById("add-source-save-icon") as HTMLElement
    val cell = saveButton.closest("td") as HTMLElement

    if (loading) {
        saveButton.hide()

        cell.append(LoadingSpinner())
    } else {
        saveButton.show()

        cell.querySelector(".loading-spinner")?.remove()
    }
}

private fun deleteReviewSource(index: Int, reviewSource: ReviewSource) {
    toggleEditMode(false)

    val cell = tableBody.children[index]!!.querySelector(".final-column") as HTMLElement
    val closeButton = cell.querySelector(".fa-xmark")!!.closest("button") as HTMLElement

    closeButton.hide()

    cell.append(LoadingSpinner())

    mainScope.launch {
        try {
            ReviewQueueService.deleteReviewSource(reviewSource.id)

            Toast.success("Review source deleted")

            render()
        } catch (e: Exception) {
            Toast.error("Failed to delete review source")

            closeButton.show()

            cell.querySelector(".loading-spinner")?.remove()
        }
    }
}
