package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.UserId

public data class DbUser(
  public val id: UserId,
  public val name: String,
  public val lastActivity: Instant?,
  public val createdAt: Instant
) {
  public override fun toString(): String = """
  |DbUser [
  |  id: $id
  |  name: $name
  |  lastActivity: $lastActivity
  |  createdAt: $createdAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<UserId, Long>,
    public val lastActivityAdapter: ColumnAdapter<Instant, Long>,
    public val createdAtAdapter: ColumnAdapter<Instant, Long>
  )
}
