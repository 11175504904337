package net.gorillagroove.track

import kotlinx.datetime.Instant
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.TrackId
import net.gorillagroove.api.UserId
import net.gorillagroove.db.DbTrack
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.util.Formatter.toUserFormat

class Track(
    val id: TrackId = TrackId(0),
    val apiId: TrackApiId? = null,
    val userId: UserId = UserId(0),
    val reviewSourceId: ReviewSourceId? = null,
    var name: String = "",
    var artist: String = "",
    var featuring: String = "",
    var album: String = "",
    var trackNumber: Int? = null,
    var length: Int = 0,
    var releaseYear: Int? = 0,
    var genre: String = "",
    var playCount: Int = 0,
    var isPrivate: Boolean = false,
    var isHidden: Boolean = false,
    val addedToLibrary: Instant? = null,
    var lastPlayed: Instant? = null,
    var inReview: Boolean = false,
    var note: String = "",
    var audioCachedAt: Instant? = null,
    var artCachedAt: Instant? = null,
    var thumbnailCachedAt: Instant? = null,
    var offlineAvailability: OfflineAvailabilityType = OfflineAvailabilityType.NORMAL,
    var filesizeAudioOgg: Int = 0,
    var filesizeAudioMp3: Int = 0,
    var filesizeArtPng: Int = 0,
    var filesizeThumbnailPng: Int = 0,
    var startedOnDevice: Instant? = null,
    var lastReviewed: Instant? = null,
) : TrackSortable {
    fun isOwnTrack(): Boolean {
        return userId == CurrentUserStore.getInfo()?.id
    }

    val formattedAddedToLibrary: String by lazy {
        addedToLibrary?.toUserFormat() ?: ""
    }
    val formattedLastPlayed: String by lazy {
        lastPlayed?.toUserFormat() ?: ""
    }
    val filesizeAudio = when (TrackLinkType.standardAudioType) {
        TrackLinkType.AUDIO_MP3 -> filesizeAudioMp3
        TrackLinkType.AUDIO_OGG -> filesizeAudioOgg
        else -> throw IllegalArgumentException("Unexpected track link type when getting audio filesize!")
    }

    // This does not look at art thumbnails as that data is so small
    val cachedBytes: Int get() {
        val audioBytes = if (audioCachedAt != null) filesizeAudio else 0
        val artBytes = if (artCachedAt != null) filesizeArtPng else 0

        return audioBytes + artBytes
    }

    internal fun hasDataForType(trackLinkType: TrackLinkType): Boolean {
        return when (trackLinkType) {
            TrackLinkType.AUDIO_MP3 -> this.filesizeAudioMp3 > 0
            TrackLinkType.AUDIO_OGG -> this.filesizeAudioOgg > 0
            TrackLinkType.ART_PNG -> this.filesizeArtPng > 0
            TrackLinkType.THUMBNAIL_PNG -> this.filesizeThumbnailPng > 0
        }
    }

    internal fun updateFrom(other: Track) {
        this.name = other.name
        this.artist = other.artist
        this.featuring = other.featuring
        this.album = other.album
        this.trackNumber = other.trackNumber
        this.length = other.length
        this.releaseYear = other.releaseYear
        this.genre = other.genre
        this.playCount = other.playCount
        this.isPrivate = other.isPrivate
        this.isHidden = other.isHidden
        this.lastPlayed = other.lastPlayed
        this.inReview = other.inReview
        this.note = other.note
        this.audioCachedAt = other.audioCachedAt
        this.artCachedAt = other.artCachedAt
        this.thumbnailCachedAt = other.thumbnailCachedAt
        this.offlineAvailability = other.offlineAvailability
        this.filesizeAudioOgg = other.filesizeAudioOgg
        this.filesizeAudioMp3 = other.filesizeAudioMp3
        this.filesizeArtPng = other.filesizeArtPng
        this.filesizeThumbnailPng = other.filesizeThumbnailPng
        this.startedOnDevice = other.startedOnDevice
        this.lastReviewed = other.lastReviewed
    }
}

internal fun Track.toDbTrack() = DbTrack(id, apiId, userId, reviewSourceId, name, artist, featuring, album, trackNumber, length, releaseYear, genre, playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview, note, audioCachedAt, artCachedAt, thumbnailCachedAt, offlineAvailability.toRawType(), filesizeAudioOgg, filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed)

internal fun DbTrack.toTrack(overrideId: TrackId? = null) = Track(overrideId ?: id, apiId, userId, reviewSourceId, name, artist, featuring, album, trackNumber, length, releaseYear, genre, playCount, isPrivate, isHidden, addedToLibrary, lastPlayed, inReview, note, audioCachedAt, artCachedAt, thumbnailCachedAt, offlineAvailability.asEnumeratedType(), filesizeAudioOgg, filesizeAudioMp3, filesizeArtPng, filesizeThumbnailPng, startedOnDevice, lastReviewed )

internal fun List<DbTrack>.toTracks(): List<Track> = this.map { it.toTrack() }
