package net.gorillagroove.user

import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import net.gorillagroove.api.UserId
import net.gorillagroove.db.DbUser

class User(
    val id: UserId,
    val name: String,
    val lastActivity: Instant?,
    val createdAt: Instant
) {
    fun hasRecentActivity(currentTime: Instant = now()): Boolean {
        if (lastActivity == null) {
            return false
        }
        return (currentTime - lastActivity).inWholeDays < 90
    }
}

internal fun DbUser.toUser() = User(id, name, lastActivity, createdAt)
internal fun List<DbUser>.toUsers() = this.map { it.toUser() }
