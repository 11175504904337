package net.gorillagroove.localstorage

import kotlinx.browser.window

// This is basic Key->Value non-database storage implemented using the browser's LocalStorage:
actual object LocalStorage {
    actual fun writeBoolean(key: String, value: Boolean) = setValue(key, value)
    actual fun readBoolean(key: String, default: Boolean): Boolean = readBoolean(key) ?: default
    actual fun readBoolean(key: String): Boolean? {
        val item = getValue(key) ?: return null
        return item.toBooleanStrictOrNull()
            ?: throw IllegalStateException("Value for key '$key' could not be read as a boolean! Value was: '$item'")
    }

    actual fun writeString(key: String, value: String) = setValue(key, value)
    actual fun readString(key: String, default: String): String = readString(key) ?: default
    actual fun readString(key: String): String? = getValue(key)

    actual fun writeInt(key: String, value: Int) = setValue(key, value)
    actual fun readInt(key: String, default: Int): Int = readInt(key) ?: default
    actual fun readInt(key: String): Int? {
        val item = getValue(key) ?: return null
        return item.toIntOrNull()
            ?: throw IllegalStateException("Value for key '$key' could not be read as an int! Value was: '$item'")
    }

    actual fun writeLong(key: String, value: Long) = setValue(key, value)
    actual fun readLong(key: String, default: Long): Long = readLong(key) ?: default
    actual fun readLong(key: String): Long? {
        val item = getValue(key) ?: return null
        return item.toLongOrNull()
            ?: throw IllegalStateException("Value for key '$key' could not be read as a long! Value was: '$item'")
    }

    actual fun delete(key: String) = window.localStorage.removeItem(STORAGE_PREFIX + key)

    actual fun clear() {
        (0 until window.localStorage.length).map { index -> window.localStorage.key(index) }
            .filter { it != null && it.startsWith(STORAGE_PREFIX) }
            // Why can Kotlin not smart-cast the filter to List<String> ?
            // I shouldn't need this double bang on 'key'.
            .forEach { key -> window.localStorage.removeItem(key!!) }
    }

    private fun setValue(key: String, value: Any) {
        if (value is String || value is Int || value is Long || value is Boolean) {
            window.localStorage.setItem(STORAGE_PREFIX + key, value.toString())
        } else {
            throw IllegalArgumentException("Unsupported type provided to LocalStorage.setValue() with key '$key' and value: '$value'!")
        }
    }

    private fun getValue(key: String): String? {
        return window.localStorage.getItem(STORAGE_PREFIX + key)
    }
}