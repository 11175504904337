package net.gorillagroove.localstorage

// This is basic Key->Value non-database storage implemented as:
// 1) JS: LocalStorage
// 2) Android: SharedPreferences
// 3) iOS: UserDefaults
// 4) JVM: Writes to a random text file
expect object LocalStorage {
    fun writeBoolean(key: String, value: Boolean)
    fun readBoolean(key: String, default: Boolean): Boolean
    fun readBoolean(key: String): Boolean?

    fun writeString(key: String, value: String)
    fun readString(key: String, default: String): String
    fun readString(key: String): String?

    fun writeInt(key: String, value: Int)
    fun readInt(key: String, default: Int): Int
    fun readInt(key: String): Int?

    fun writeLong(key: String, value: Long)
    fun readLong(key: String, default: Long): Long
    fun readLong(key: String): Long?

    fun delete(key: String)

    // We really don't want to let a client clear the local storage as that would wreck our internal state.
    // It's probably a bad move that I store the library values in this exposed thing tbh. But w/e. Life marches on.
    internal fun clear()
}

// Depending on the platform, it might be necessary to prefix keys so that
// library-defined properties don't conflict with the storage mechanism of the host
const val STORAGE_PREFIX = "gg-common:"
