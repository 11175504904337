package components

import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onChangeFunction
import mainScope
import net.gorillagroove.api.UserId
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.history.SiteStats
import net.gorillagroove.user.UserService
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSelectElement
import query
import queryId

@Suppress("FunctionName")
fun UserYearInReview(): HTMLDivElement {
    return document.create.div {
        id = "user-stats"

        select {
            UserService.findAll().forEach { user ->
                option {
                    + user.name

                    value = user.id.value.toString()
                }
            }

            onChangeFunction = { event ->
                val select = event.currentTarget as HTMLSelectElement
                setStuff(UserId(select.value.toLong()))
            }
        }

        h1 { }

        div("space-around") {
            id = "stuff"
        }

        mainScope.launch {
            val select = document.query<HTMLSelectElement>("#user-stats select")
            setStuff(UserId(select.value.toLong()))
        }
    }
}

private fun setStuff(userId: UserId) = mainScope.launch {
    val stats = SiteStats.getUserStats(userId)

    val user = UserService.findById(userId)!!

    val usernameField = document.query<HTMLElement>("#user-stats h1")
    usernameField.innerText = "${user.name}'s 2023 Grooving"

    val stuffContainer = document.queryId<HTMLElement>("stuff")

    stuffContainer.innerHTML = ""

    stuffContainer.append {
        div("space-around") {
            div("section") {
                h3 {
                    +"Top songs"
                }

                table {
                    stats.mostListenedTracks.forEach { (track, plays) ->
                        tr {
                            td("text-left first") {
                                +track
                            }
                            td("text-right") {
                                + "$plays plays"
                            }
                        }
                    }
                }
            }

            div("section") {
                h3 {
                    +"Top artists"
                }

                table {
                    stats.mostListenedArtists.forEach { (artist, plays) ->
                        tr {
                            td("text-left first") {
                                +artist
                            }
                            td("text-right") {
                                + "$plays plays"
                            }
                        }
                    }
                }
            }
        }

        div("mt-12 space-around") {
            div("section") {
                h3 {
                    +"Top albums"
                }

                table {
                    stats.mostListenedAlbums.forEach { (album, plays) ->
                        tr {
                            td("text-left first") {
                                +album
                            }
                            td("text-right") {
                                + "$plays plays"
                            }
                        }
                    }
                }
            }
            div("section") {
                if (stats.distanceTraveled > 0) {
                    h3 {
                        +"Distance traveled"
                    }

                    div {
                        + "${stats.distanceTraveled / 1000} km"
                    }
                } else {
                    h3 {  }
                }
            }
        }
    }
}
