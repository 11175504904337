package net.gorillagroove.track

import kotlinx.coroutines.*
import net.gorillagroove.api.Api
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.TrackId
import net.gorillagroove.api.UserId
import net.gorillagroove.discovery.MultiTrackResponse
import net.gorillagroove.sync.strategies.TrackResponse
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog.logError

/**
 * This object is responsible for loading Tracks that belong to OTHER users than the logged-in user.
 * The Tracks are stored in a short-term cache to allow easy filtering and view changes (such as going
 * from an artist to an album view, without having to reload) and eventually are evicted from memory.
 */
@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
internal object UserTrackService {

    private val coroutineScope = CoroutineScope(Dispatchers.Default)
    private var cleanupJob: Job? = null

    // VisibleForTesting
    internal var cacheClearTimeMs = 120_000L

    private val trackCache = mutableMapOf<UserId, List<Track>>()
    private val albumArtCache = mutableMapOf<TrackId, ByteArray>()

    private var nextPseudoTrackId = Int.MAX_VALUE.toLong()

    suspend fun getAllTracksForUser(userId: UserId): List<Track> {
        if (userId == UserService.requireCurrentUserId()) {
            throw IllegalArgumentException("loadAllTracksForUser should not be called for the logged-in user")
        }

        trackCache[userId]?.let { cachedTracks ->
            resetCacheClearJob()
            return cachedTracks
        }

        val response = Api.get<MultiTrackResponse>("track/all", mapOf("userId" to userId.value))

        val pseudoIds = response.items.generatePseudoIds()
        val tracks = response.items.map { it.asTrack(pseudoIds) }

        val trackDtos = tracks.toTracks()

        trackCache[userId] = trackDtos
        resetCacheClearJob()

        return trackDtos
    }

    suspend fun getTrack(trackId: TrackApiId): Track {
        val cachedTrack = getCachedTrack(trackId)
        if (cachedTrack != null) {
            return cachedTrack
        }

        val trackResponse = Api.get<TrackResponse>("track/${trackId.value}")

        val pseudoIds = listOf(trackResponse).generatePseudoIds()

        return trackResponse.asTrack(pseudoIds).toTrack()
    }

    /**
     * This function exists largely as a place to put this giant ass comment
     *
     * ALL tracks need to eventually have a non-zero local ID. This is used by clients for a lot of things,
     * like highlighting the currently playing track in track list views. We can't use the API ID
     * for this, because we have added in support for local-only tracks now, so API IDs can be null.
     *
     * So now we have a situation where some tracks have no local ID (tracks from other users), and
     * some tracks with no API ID (local-only tracks). Yet we need some way to identify tracks uniquely
     * because they can coexist in the same views.
     *
     * There are several bad options for how to solve this, with the two leading options being:
     * 1) Assign a meaningless local ID that is so large it won't ever conflict with local tracks.
     * 2) Create a new property on Track for a "displayId" that is a prepended string. So for local tracks,
     *    the ID could be something like 'L-123' and user tracks could be 'U-123' or something.
     *
     * I am choosing to go with option 1, because I really hate comparing strings in a loop unless I have to.
     * So we are assigning the first temporary ID to be the max value of an int, and then counting down.
     * Until users have 32 million or whatever tracks, we're fine. And that seems a little unlikely.
     *
     * There is one more caveat to this, though. And that is the fact that a user can actually have the
     * track saved locally if it is stored on a playlist with multiple users. To elaborate:
     * we are User A. We share a playlist with User B. User B adds a track to this shared playlist.
     * We sync this track down, as it's on a playlist we are on. Now we go and view tracks for User B.
     * This fetches all of their tracks, and we end up in this assignPseudoIds() function. We will
     * ordinarily assign this track a fake ID, but we SHOULD assign it a real ID, as we have the track locally.
     */
    private fun List<TrackResponse>.generatePseudoIds(): Map<TrackApiId, TrackId> {
        val apiIds = this.map { it.id }
        val apiIdToLocalId = TrackService.findLocalIdForApiId(apiIds).toMutableMap()

        this.forEach { track ->
            if (apiIdToLocalId[track.id] == null) {
                apiIdToLocalId[track.id] = TrackId(--nextPseudoTrackId)
            }
        }
        return apiIdToLocalId
    }

    // If the local ID is less than the next pseudo ID, then we know it's a real track for real
    fun isTrackInDatabase(trackId: TrackId): Boolean {
        // I'm subtracting 8 here because we have a huge buffer to work with, and I'm afraid of race conditions... idk
        return trackId.value < nextPseudoTrackId - 8
    }

    private fun getCachedTrack(apiId: TrackApiId): Track? {
        trackCache.values.forEach { userCache ->
            return userCache.find { it.apiId == apiId }
        }
        return null
    }

    suspend fun getAlbumArt(trackId: TrackId, serverUrl: String): ByteArray? {
        return albumArtCache[trackId] ?: run {
            val foundArt = try {
                Api.download(serverUrl).data
            } catch (e: Exception) {
                logError("Failed to download album art")
                return@run null
            }

            albumArtCache[trackId] = foundArt
            foundArt
        }
    }

    fun getCachedArt(trackId: TrackId): ByteArray? {
        return albumArtCache[trackId]
    }

    // VisibleForTesting
    internal fun reset() {
        cleanupJob?.cancel()
        trackCache.clear()
    }

    private fun resetCacheClearJob() {
        cleanupJob?.cancel()
        cleanupJob = coroutineScope.launch {
            // Wait 2 minutes and clear the cache if we haven't fetched anything since then
            delay(cacheClearTimeMs)
            trackCache.clear()
            albumArtCache.clear()
        }
    }
}
