package components

import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import mainScope
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.history.SiteStats
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import queryId

@Suppress("FunctionName")
fun SiteYearInReview(): HTMLDivElement {
    return document.create.div {
        id = "site-stats"

        h1 {
            + "Gorilla Groove Site Stats"
        }
        h2 {
            + "2023!"
        }

        div("space-around") {
            id = "stuff"
        }

        mainScope.launch {
            val stats = SiteStats.getSiteStats()

            val stuffContainer = document.queryId<HTMLElement>("stuff")

            stuffContainer.append {
                div("section") {
                    h3 {
                        +"Songs played"
                    }

                    table {
                        stats.playCountByUser.forEach { (playCount, user) ->
                            tr {
                                td("text-left first") {
                                    + user
                                }
                                td("text-right") {
                                    + playCount.toString()
                                }
                            }
                        }
                    }
                }

                div("section") {
                    h3 {
                        +"Time spent listening"
                    }

                    table {
                        stats.playtimeByUser.forEach { (playtime, user) ->
                            tr {
                                td("text-left first") {
                                    + user
                                }
                                td("text-right") {
                                    + "${(playtime / 60 / 60)} hours"
                                }
                            }
                        }
                    }
                }

                div("section") {
                    h3 {
                        +"Total time listened by platform"
                    }

                    table {
                        stats.playtimeByDevice.forEach { (playtime, platform) ->
                            tr {
                                td("text-left first") {
                                    + when (platform.asEnumeratedType()) {
                                        DeviceType.WEB -> "Web"
                                        DeviceType.ANDROID -> "Android"
                                        DeviceType.IPHONE -> "iOS"
                                        else -> ""
                                    }
                                }
                                td("text-right") {
                                    + "${(playtime / 60 / 60)} hours"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
