package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.db.*
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.sync.*

object PlaylistSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.PLAYLIST

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<PlaylistResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            Database.db.transaction {
                changeSet.newAndModified.forEach {
                    Database.playlistDao.upsert(it.asDbPlaylist())
                }
                changeSet.removed.forEach {
                    PlaylistService.deletePlaylistInternal(PlaylistId(it))
                }
            }
        }
    }
}

@Serializable
data class PlaylistResponse(
    val id: PlaylistId,
    val name: String,
    val createdAt: Instant,
    val updatedAt: Instant,
) {
    fun asDbPlaylist() = DbPlaylist(
        id = id,
        name = name,
        createdAt = createdAt,
        updatedAt = updatedAt,
    )
}
