@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.h3
import kotlinx.html.js.div
import mainScope
import net.gorillagroove.playlist.PlaylistService
import net.gorillagroove.track.Track
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError

fun AddToPlaylist(tracks: List<Track>) = document.create.div {
    val select = Multiselect(data = PlaylistService.findAll(), "playlists") { it.name }
    val trackName = if (tracks.size == 1) "'${tracks.first().name}'" else "${tracks.size} tracks"

    h3 {
        + "Add to Playlist"
    }

    div("space-between") {
        select.render(this, classes = "mr-12 mt-6")

        ActionButton("add-tracks-to-playlist", "Add", classes = "ml-12") {
            val playlists = select.getSelectedItems()
            if (playlists.isEmpty()) {
                return@ActionButton
            }

            actionButtonChangeState("add-tracks-to-playlist", isUpdating = true)

            mainScope.launch {
                val playlistName =
                    if (playlists.size == 1) "'${playlists.first().name}'" else "${playlists.size} playlists"

                try {
                    PlaylistService.addTracks(tracks.map { it.id }, playlists.map { it.id })
                } catch (e: Exception) {
                    Toast.error("Failed to add $trackName to $playlistName")
                    GGLog.logError("Failed to add tracks to playlists!", e)

                    actionButtonChangeState("add-tracks-to-playlist", isUpdating = false)
                    return@launch
                }

                Toast.success("Added $trackName to $playlistName")
                Dialog.remove()
            }
        }
    }

    mainScope.launch {
        select.open()
    }
}
