import components.GenericActionDialog
import components.Multiselect
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import net.gorillagroove.reporting.DialogEventBus
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.File

object Dialog {
    private var dialogsOpened = 0

    private const val SUPPORTED_DIALOGS = 2

    fun init() {
        document.body!!.append {
            repeat(SUPPORTED_DIALOGS) { createDialog(count = it + 1) }
        }

        DialogEventBus.registerDialogEventHandler { event ->
            val modal = GenericActionDialog(
                message = event.message,
                acceptText = event.yesText,
                rejectText = event.noText,
                onAccept = { event.yesAction() },
                onReject = { event.noAction() },
            )

            show(modal)
        }
    }

    private fun TagConsumer<HTMLElement>.createDialog(count: Int) {
        div("d-none dialog-container") {
            id = "dialog-container-$count"

            onClickFunction = { remove() }

            div("dialog-item-wrapper") {
                onClickFunction = {
                    it.stopPropagation()

                    Multiselect.close()
                }

                button(classes = "icon close-button") {
                    i("fa-solid fa-xmark")

                    onClickFunction = { remove() }
                }

                div("dialog-item-container")
            }
        }
    }

    private fun getCurrentContainer(): HTMLElement {
        return document.getElementById("dialog-container-$dialogsOpened") as HTMLElement
    }

    private fun getCurrentItemContainer(): HTMLElement {
        return getCurrentContainer().querySelector(".dialog-item-container") as HTMLElement
    }

    fun isShowing(): Boolean = dialogsOpened > 0

    fun show(element: HTMLElement) {
        if (dialogsOpened + 1 > SUPPORTED_DIALOGS) {
            throw IllegalStateException("Too many dialogs attempted to be opened!")
        }

        dialogsOpened++

        getCurrentItemContainer().append(element)
        getCurrentContainer().classList.remove("d-none")
    }

    fun remove() {
        getCurrentItemContainer().removeChildren()
        getCurrentContainer().classList.add("d-none")

        dialogsOpened--
    }

    fun removeAll() {
        while (dialogsOpened > 0) {
            remove()
        }
    }

    fun promptFileUpload(multiple: Boolean = false, onSubmit: suspend (List<File>) -> Unit) {
        val inputId = "dialog-file-upload"
        val element = document.getElementById(inputId) as HTMLElement? ?: run {
            val newInput = document.create.input(InputType.file, classes = "d-none") {
                id = inputId
                accept = "audio/*"
                onChangeFunction = { e ->
                    val input = e.currentTarget as HTMLInputElement
                    val files = input.files!!.asList()

                    mainScope.launch {
                        onSubmit(files)
                    }
                }
                onClickFunction = { it.stopPropagation() }
                this.multiple = multiple
            }

            document.body!!.append(newInput)

            newInput
        }

        element.click()
    }
}
