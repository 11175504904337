package net.gorillagroove.discovery

import net.gorillagroove.api.Api
import net.gorillagroove.api.DownloadResponse

object BrowserExtension {
    suspend fun downloadFireFoxExtension(): DownloadResponse {
        return Api.download(Api.BASE_URL + "file/firefox-extension/1.0.1")
    }
}
