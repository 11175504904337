package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.TrackId
import net.gorillagroove.api.UserId
import net.gorillagroove.sync.RawOfflineAvailabilityType

public data class DbTrack(
  public val id: TrackId,
  public val apiId: TrackApiId?,
  public val userId: UserId,
  public val reviewSourceId: ReviewSourceId?,
  public val name: String,
  public val artist: String,
  public val featuring: String,
  public val album: String,
  public val trackNumber: Int?,
  public val length: Int,
  public val releaseYear: Int?,
  public val genre: String,
  public val playCount: Int,
  public val isPrivate: Boolean,
  public val isHidden: Boolean,
  public val addedToLibrary: Instant?,
  public val lastPlayed: Instant?,
  public val inReview: Boolean,
  public val note: String,
  public val audioCachedAt: Instant?,
  public val artCachedAt: Instant?,
  public val thumbnailCachedAt: Instant?,
  public val offlineAvailability: RawOfflineAvailabilityType,
  public val filesizeAudioOgg: Int,
  public val filesizeAudioMp3: Int,
  public val filesizeArtPng: Int,
  public val filesizeThumbnailPng: Int,
  public val startedOnDevice: Instant?,
  public val lastReviewed: Instant?
) {
  public override fun toString(): String = """
  |DbTrack [
  |  id: $id
  |  apiId: $apiId
  |  userId: $userId
  |  reviewSourceId: $reviewSourceId
  |  name: $name
  |  artist: $artist
  |  featuring: $featuring
  |  album: $album
  |  trackNumber: $trackNumber
  |  length: $length
  |  releaseYear: $releaseYear
  |  genre: $genre
  |  playCount: $playCount
  |  isPrivate: $isPrivate
  |  isHidden: $isHidden
  |  addedToLibrary: $addedToLibrary
  |  lastPlayed: $lastPlayed
  |  inReview: $inReview
  |  note: $note
  |  audioCachedAt: $audioCachedAt
  |  artCachedAt: $artCachedAt
  |  thumbnailCachedAt: $thumbnailCachedAt
  |  offlineAvailability: $offlineAvailability
  |  filesizeAudioOgg: $filesizeAudioOgg
  |  filesizeAudioMp3: $filesizeAudioMp3
  |  filesizeArtPng: $filesizeArtPng
  |  filesizeThumbnailPng: $filesizeThumbnailPng
  |  startedOnDevice: $startedOnDevice
  |  lastReviewed: $lastReviewed
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<TrackId, Long>,
    public val apiIdAdapter: ColumnAdapter<TrackApiId, Long>,
    public val userIdAdapter: ColumnAdapter<UserId, Long>,
    public val reviewSourceIdAdapter: ColumnAdapter<ReviewSourceId, Long>,
    public val addedToLibraryAdapter: ColumnAdapter<Instant, Long>,
    public val lastPlayedAdapter: ColumnAdapter<Instant, Long>,
    public val audioCachedAtAdapter: ColumnAdapter<Instant, Long>,
    public val artCachedAtAdapter: ColumnAdapter<Instant, Long>,
    public val thumbnailCachedAtAdapter: ColumnAdapter<Instant, Long>,
    public val offlineAvailabilityAdapter: ColumnAdapter<RawOfflineAvailabilityType, String>,
    public val startedOnDeviceAdapter: ColumnAdapter<Instant, Long>,
    public val lastReviewedAdapter: ColumnAdapter<Instant, Long>
  )
}
