package net.gorillagroove.playlist

import kotlinx.datetime.Instant
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.PlaylistUserId
import net.gorillagroove.api.UserId
import net.gorillagroove.db.DbPlaylistUser

class PlaylistUser (
    val id: PlaylistUserId,
    val playlistId: PlaylistId,
    val userId: UserId,
    val ownershipType: PlaylistOwnershipType,
    val createdAt: Instant,
    val updatedAt: Instant
)

internal fun DbPlaylistUser.toPlaylistUser() = PlaylistUser(id, playlistId, userId, ownershipType.asEnumeratedType(), createdAt, updatedAt)
internal fun List<DbPlaylistUser>.toPlaylistUsers() = this.map { it.toPlaylistUser() }
