package components

import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import mainScope
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError

// TODO delete after it's been deployed for a while. It just cleans up my failed service worker.
object OnDemandTrackCacheListener {
    fun unregister() {
        mainScope.launch {
            try {
                window.navigator.serviceWorker.getRegistrations().await().forEach { registration ->
                    registration.unregister()
                }
            } catch (_: Throwable) { }
        }
    }
}
