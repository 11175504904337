package net.gorillagroove.hardware

import kotlinx.serialization.Serializable
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.track.AudioFormat
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.PlatformUtil
import kotlin.jvm.JvmInline

// I needed some DeviceUtil stuff to be "expect" and some not to be, so I had to make
// two different objects. The intention, though, is to have DeviceUtil delegate to this
// PlatformDeviceUtil so that callers don't have to remember what is where
internal expect object PlatformDeviceUtil {
    fun getDeviceName(): String?

    fun getDeviceType(): DeviceType

    fun getDefaultAudioFormat(): AudioFormat
}

internal object DeviceUtil {
    // There are potentially better results to be had here by using platform-specific solutions,
    // but actual hardware identifiers are fairly invasive, and privacy-minded platforms have removed,
    // or are beginning to remove, the ability to even access them. As such, it seems most prudent
    // to just use a multiplatform UUID identifier that does not persist between reinstalls.
    // It might make sense for the API backend to automatically merge devices if they have the
    // same preferred device name and device type, which should help mitigate some issues
    // with an ephemeral device ID (namely that devices clutter up over time with reinstalls)
    fun getDeviceId(): String {
        return LocalStorage.readString("device_id") ?: run {
            val newId = PlatformUtil.generateUuid()
            LocalStorage.writeString("device_id", newId)
            newId
        }
    }

    fun getDeviceName() = PlatformDeviceUtil.getDeviceName()

    fun getDeviceType() = PlatformDeviceUtil.getDeviceType()
}

// TODO I should really differentiate between iPads and iPhones.
// The iOS codebase already has a function to tell them apart
enum class DeviceType(
    val displayName: String,

    // If this is false, then the socket should only be used when actively playing music.
    // This is the expected behavior of mobile clients. However, web and desktop clients
    // keep the socket alive forever, to show who else is listening to music.
    internal val keepSocketAlive: Boolean,

    val isMobile: Boolean = false,
) {
    WEB("Web", true),
    ANDROID("Android", false, true),
    IPHONE("iOS", false, true),
    DESKTOP_JVM("Desktop", true),
    UNKNOWN("Unknown", true),
    ;

    fun toRawType() = RawDeviceType(this.name)

    companion object {
        @Suppress("unused")
        fun getSelectableTypes(): List<DeviceType> {
            return entries.toSet()
                .minus(UNKNOWN)
                .minus(DESKTOP_JVM)
                .sortedByDescending { it.displayName }
        }
    }
}

@Serializable
@JvmInline
value class RawDeviceType(val value: String) {
    fun asEnumeratedType(): DeviceType {
        return try {
            DeviceType.valueOf(value)
        } catch (e: Throwable) {
            this.logError("Unknown enumerated type value '$value' for enum '${DeviceType::class.simpleName}'")
            DeviceType.UNKNOWN
        }
    }
}
