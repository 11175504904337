package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.PlaylistTrackId
import net.gorillagroove.api.TrackId

public data class DbPlaylistTrack(
  public val id: PlaylistTrackId,
  public val playlistId: PlaylistId,
  public val trackId: TrackId,
  public val sortOrder: Int,
  public val createdAt: Instant
) {
  public override fun toString(): String = """
  |DbPlaylistTrack [
  |  id: $id
  |  playlistId: $playlistId
  |  trackId: $trackId
  |  sortOrder: $sortOrder
  |  createdAt: $createdAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<PlaylistTrackId, Long>,
    public val playlistIdAdapter: ColumnAdapter<PlaylistId, Long>,
    public val trackIdAdapter: ColumnAdapter<TrackId, Long>,
    public val createdAtAdapter: ColumnAdapter<Instant, Long>
  )
}
