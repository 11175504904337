package components

import AuthUtil
import PageRouter
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import mainScope
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.sync.SyncableEntity
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLProgressElement

private val entityIndicator: Element get() = document.getElementById("entity-indicator")!!
private val firstSyncProgress get() = document.getElementById("first-sync-progress") as HTMLProgressElement

@Suppress("FunctionName")
fun FirstSync(): HTMLElement {
    val element = document.create.div {
        id = "first-sync"

        h2 {
            + "Performing first time setup"
        }

        div("sync-content") {
            div {
                div {
                    id = "entity-indicator"

                    + "Beginning sync..."
                }

                progress("thumbless") {
                    id = "first-sync-progress"
                    max = "1"
                    value = "0"
                }
            }
        }
    }

    mainScope.launch {
        if (!AuthService.isAuthenticated()) {
            PageRouter.navigateTo("/login")
            return@launch
        } else if (SyncCoordinator.hasFirstSynced()) {
            PageRouter.navigateTo("/")
            return@launch
        }

        val totalEntities = SyncableEntity.values().size
        var totalSynced = 0

        val result = SyncCoordinator.sync { entity, percentDone ->
            entityIndicator.textContent = "Synced '${entity.displayName}'"

            if (percentDone == 1.0) {
                totalSynced++

                firstSyncProgress.value = (totalSynced.toDouble() / totalEntities)
            }
        }

        if (result.wasSuccessful) {
            AuthUtil.setupAuthenticatedUser()
            PageRouter.navigateTo("/")
        } else {
            AuthUtil.beforeLogout()
            AuthService.logout()
            PageRouter.navigateTo("/login")
        }
    }

    return element
}
