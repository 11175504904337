package net.gorillagroove.sync


enum class SyncDirection {
    DOWN, UP, BOTH;

    fun includes(syncDirection: SyncDirection) = this == BOTH || this == syncDirection
}

// Any new priorities added should be in the order of their priority value.
// You can't override compareTo on enums for whatever dumb reason.
enum class SyncPriority(val value: Int) {
    STANDARD(0),
    ELEVATED(1),
}

interface TaskQueueEntity {
    val implicitPriority: SyncPriority
    val syncDownDependencies: Set<TaskQueueEntity>
    val syncUpDependencies: Set<TaskQueueEntity>
    val apiName: String
}

enum class SyncableEntity(
    val displayName: String,
    override val implicitPriority: SyncPriority = SyncPriority.STANDARD,
    override val syncDownDependencies: Set<TaskQueueEntity> = emptySet(),
    override val syncUpDependencies: Set<TaskQueueEntity> = emptySet(),
) : TaskQueueEntity {
    USER("Users"),
    REVIEW_SOURCE("Review sources"),
    USER_SETTING("Settings"),
    TRACK("Tracks", implicitPriority = SyncPriority.ELEVATED, syncDownDependencies = setOf(USER, REVIEW_SOURCE)),
    PLAYLIST("Playlists"),
    PLAYLIST_TRACK("Playlist tracks", syncDownDependencies = setOf(PLAYLIST, TRACK)),
    PLAYLIST_USER("Playlist users", syncDownDependencies = setOf(PLAYLIST, USER)),
    USER_FAVORITE("Favorites", syncDownDependencies = setOf(USER)),
    USER_PERMISSION("Permissions", syncDownDependencies = setOf(USER)),
    ;

    override val apiName: String = name
}
