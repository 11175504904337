package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.UserPermissionId
import net.gorillagroove.user.permission.RawUserPermissionType

public data class DbUserPermission(
  public val id: UserPermissionId,
  public val type: RawUserPermissionType,
  public val createdAt: Instant
) {
  public override fun toString(): String = """
  |DbUserPermission [
  |  id: $id
  |  type: $type
  |  createdAt: $createdAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<UserPermissionId, Long>,
    public val typeAdapter: ColumnAdapter<RawUserPermissionType, String>,
    public val createdAtAdapter: ColumnAdapter<Instant, Long>
  )
}
