package net.gorillagroove.sync

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.serialization.Serializable
import net.gorillagroove.util.GGLog.logError
import kotlin.jvm.JvmInline

enum class UserFavoriteType {
    ARTIST,
    ALBUM,
    UNKNOWN
    ;

    fun toRawType() = RawUserFavoriteType(this.name)
}

@Serializable
@JvmInline
value class RawUserFavoriteType(val value: String) {
    fun asEnumeratedType(): UserFavoriteType {
        return try {
            UserFavoriteType.valueOf(value)
        } catch (e: Exception) {
            this.logError("Unknown enumerated type value '$value' for enum '${UserFavoriteType::class.simpleName}'")
            UserFavoriteType.UNKNOWN
        }
    }
}

object RawUserFavoriteTypeAdapter : ColumnAdapter<RawUserFavoriteType, String> {
    override fun decode(databaseValue: String) = RawUserFavoriteType(databaseValue)
    override fun encode(value: RawUserFavoriteType) = value.value
}
