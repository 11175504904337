package util

import components.Footer
import kotlinx.browser.document
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.html.dom.create
import kotlinx.html.js.a
import mainScope
import net.gorillagroove.api.TrackId
import net.gorillagroove.db.asByteArray
import net.gorillagroove.track.ChangeType
import net.gorillagroove.track.NowPlayingService
import net.gorillagroove.track.Track
import net.gorillagroove.track.TrackService
import org.khronos.webgl.Int8Array
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.File

object ByteUtil {
    // This really shouldn't be here, but I made bad decisions
    fun init() {
        TrackService.registerEventHandler { event ->
            // Invalidate the art cache if it changed so that we can reload the good stuff.
            if (event.changeType != ChangeType.UPDATED) {
                return@registerEventHandler
            }

            val track = event.tracks.find { it.id == NowPlayingService.currentTrack?.id }
                ?: return@registerEventHandler

            // Yes I know this is technically not perfect. I should use the art's last updated date, but I, long ago,
            // apparently decided to not save this into the database. So I don't have it. But this is fine 99.9% of the time.
            if (track.filesizeArtPng != artBytes) {
                invalidateBlobCache()

                // This arguably doesn't belong here in a "util" file, but I'm feelin' pretty lazy
                if (track.id == NowPlayingService.currentTrack?.id) {
                    mainScope.launch {
                        Footer.refreshDisplayedArt()
                    }
                }
            }
        }
    }

    private var lastBlobTrackId: TrackId? = null
    private var lastBlobUrl: String? = null
    private var artBytes: Int? = null

    fun ByteArray.displayOnElement(track: Track, element: HTMLElement, forceDisplay: Boolean = false) {
        val url = if (forceDisplay || track.id != lastBlobTrackId) {
            if (lastBlobUrl != null) {
                // Clean it up or this is a memory leak
                URL.revokeObjectURL(lastBlobUrl!!)
            }
            val url = URL.createObjectURL(this.toBlob())

            lastBlobTrackId = track.id
            lastBlobUrl = url
            artBytes = track.filesizeArtPng
            url
        } else {
            lastBlobUrl!!
        }

        element.style.backgroundImage = """url("$url")"""
    }

    private fun invalidateBlobCache() {
        if (lastBlobUrl == null) {
            return
        }

        URL.revokeObjectURL(lastBlobUrl!!)
        lastBlobTrackId = null
        lastBlobUrl = null
    }

    fun ByteArray.toBlob(): Blob {
        val int8 = this.unsafeCast<Int8Array>()
        return Blob(arrayOf(int8))
    }

    fun ByteArray.download(filename: String) {
        val link = document.create.a {
            href = URL.createObjectURL(this@download.toBlob())
        }
        link.download = filename
        link.click()
    }

    suspend fun File.asByteArray(): ByteArray {
        return (this.asDynamic() as Blob).asByteArray()
    }

    suspend fun Blob.asByteArray(): ByteArray {
        val buffer = (this as components.Blob).arrayBuffer().await()
        return Int8Array(buffer).asByteArray()
    }
}