package net.gorillagroove.api

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

// These really have no business being Longs. It's a waste of memory.
// But SQLDelight really doesn't seem to want you to use Ints with value classes so whatever.
// It's not like the extra 4 bytes are really going to matter.
@JvmInline @Serializable value class UserId(val value: Long)
@JvmInline @Serializable value class TrackId(val value: Long)
@JvmInline @Serializable value class TrackApiId(val value: Long)
@JvmInline @Serializable value class SyncStatusId(val value: Long)
@JvmInline @Serializable value class FailedListenId(val value: Long)
@JvmInline @Serializable value class PlaylistId(val value: Long)
@JvmInline @Serializable value class PlaylistTrackId(val value: Long)
@JvmInline @Serializable value class PlaylistUserId(val value: Long)
@JvmInline @Serializable value class ReviewSourceId(val value: Long)
@JvmInline @Serializable value class LogLineId(val value: Long)
@JvmInline @Serializable value class UserSettingId(val value: Long)
@JvmInline @Serializable value class UserSettingApiId(val value: Long)
@JvmInline @Serializable value class UserFavoriteId(val value: Long)
@JvmInline @Serializable value class UserPermissionId(val value: Long)
@JvmInline @Serializable value class DeviceId(val value: Long)
@JvmInline @Serializable value class BackgroundTaskId(val value: Long)
@JvmInline @Serializable value class VersionHistoryId(val value: Long)
@JvmInline @Serializable value class CrashReportId(val value: Long)
@JvmInline @Serializable value class TrackHistoryId(val value: Long)
