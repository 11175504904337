package net.gorillagroove.api

import io.ktor.client.plugins.websocket.*
import io.ktor.http.*
import io.ktor.websocket.*
import kotlinx.coroutines.*
import kotlinx.coroutines.channels.ClosedReceiveChannelException
import kotlinx.serialization.*
import kotlinx.serialization.json.*
import net.gorillagroove.api.Api.addAuthorization
import net.gorillagroove.api.Api.defaultConfiguration
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.discovery.BackgroundTaskService
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.PlatformDeviceUtil
import net.gorillagroove.track.NowListeningService
import net.gorillagroove.util.CoroutineUtil.CancelledJob
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.Lock
import net.gorillagroove.util.use
import kotlin.time.Duration.Companion.seconds

typealias SocketEventHandler = (eventType: WebSocketResponse) -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object ApiSocket {
    private val coroutineScope = CoroutineScope(Dispatchers.Default)

    private var socket: WebSocketSession? = null

    private var socketEventHandler: SocketEventHandler? = null

    private val lock: Lock = Lock()

    val isActive get() = socket?.isActive == true

    // This is apparently different from socket.isActive. You can kill the backend and the socket is still "active",
    // just unable to get a connection. So this is more accurate of what something might want to know.
    var isConnected = false
        private set

    internal val keepSocketAlive = DeviceUtil.getDeviceType().keepSocketAlive

    fun registerSocketEventHandler(socketEventHandler: SocketEventHandler) {
        this.socketEventHandler = socketEventHandler
    }

    private var socketIsConnectingJob: Job? = null

    private var attemptingToBeOnline: Boolean = false

    // This is unused as you can't really test this without passing in a Job.
    // But it is what is exposed in the public api.
    // Mobile apps should not need to invoke this. This is really meant for Web
    // (or any other long-running client) so invoke once they are bootstrapped.
    // Mobile clients only keep the socket alive while they are playing music, and
    // that process is handled by the SDK.
    @Suppress("unused")
    fun connect(
        eventTypes: Set<EventType> = EventType.getDefaultTypes(),
        onConnect: (success: Boolean) -> Unit
    ) {
        attemptingToBeOnline = true

        lock.use {
            if (socketIsConnectingJob == null && !isActive) {
                socketIsConnectingJob = Job()
                connect(eventTypes, socketIsConnectingJob)
            }
        }

        coroutineScope.launch {
            try {
                socketIsConnectingJob?.join()
                socketIsConnectingJob = null

                this@ApiSocket.logDebug("Socket was connected to")
                onConnect(true)
            } catch (e: Exception) {
                this@ApiSocket.logError("Failed to connect to socket")
                onConnect(false)
            }
        }
    }

    fun disconnect() {
        attemptingToBeOnline = false

        coroutineScope.launch {
            val socket = socket
            ApiSocket.socket = null
            socket?.close()
        }
    }

    private fun broadcastDisconnect() {
        logInfo("Socket disconnected")

        isConnected = false
        socketEventHandler?.invoke(ConnectionLostSocketResponse())
    }

    internal fun connect(
        eventTypes: Set<EventType> = EventType.getDefaultTypes(),
        onConnectedJob: Job? = null,
    ): Job {
        val queryString = mapOf("event-types" to eventTypes).toQueryString()
        return coroutineScope.launch {
            try {
                Api.client.wss(
                    host = Api.hostOverride ?: Api.PROD_HOST,
                    path = "/api/socket$queryString",
                    request = {
                        addAuthorization()
                        this.url.protocol = if (Api.useHttps) URLProtocol.WSS else URLProtocol.WS
                    }
                ) {
                    socket = this

                    onConnectedJob?.cancel()

                    while (ApiSocket.isActive) {
                        // Even if I null out the socket first before closing it, we still hit this
                        // ClosedReceiveChannelException 100% of the time that we disconnect because
                        // the socket is already attempting to receive() the next thing, and it's just waiting.
                        // I guess just catch it to ignore it. It's closed because we closed it. It's fine.
                        this@ApiSocket.logInfo("About to read message")
                        val message = try {
                            socket?.incoming?.receive() as? Frame.Text
                        } catch (e: ClosedReceiveChannelException) {
                            broadcastDisconnect()

                            if (keepSocketAlive) {
                                this@ApiSocket.logInfo("Attempting to reconnect socket after 30 seconds")
                                delay(30.seconds)
                                if (ApiSocket.isActive) {
                                    continue
                                } else {
                                    connect()
                                    return@wss
                                }
                            } else {
                                socket?.close()
                                socket = null
                                return@wss
                            }
                        }

                        if (message == null) {
                            // This seems to be normal in the browser when you refresh the page?
                            this@ApiSocket.logError("Could not receive Socket message as Frame.Text type!")
                            continue
                        } else {
                            // I'm really not sure where to put this thing that says you're connected.
                            // This socket stuff is pretty jank. I am just going to assume that if you got
                            // a message, that you're connected I guess. Good thing all client types listen
                            // for a CONNECTION_ESTABLISHED message from the Api. So this should always be true.
                            if (!isConnected) {
                                this@ApiSocket.logInfo("Socket connected")
                                NowListeningService.sendCurrentListenState()
                            }
                            isConnected = true
                        }

                        handleMessage(message)
                    }
                }
            } catch (e: Exception) {
                if (e.isNoInternetException()) {
                    this@ApiSocket.logError("Socket could not stay open because of a lack of internet")
                } else {
                    this@ApiSocket.logError("Socket failed to stay open for an unknown reason!", e)
                }

                broadcastDisconnect()
                onConnectedJob?.cancel()

                // If we are on a device type that tries to keep the socket alive, reopen it after we encounter an error
                if (keepSocketAlive) {
                    this@ApiSocket.logDebug("Waiting 30 seconds and attempting to reconnect")

                    delay(30.seconds)

                    if (AuthService.isAuthenticated() && !ApiSocket.isActive && attemptingToBeOnline) {
                        this@ApiSocket.logDebug("Attempting socket reconnect")
                        connect()
                    }
                }
            }
        }
    }

    private val serializer = Json {
        defaultConfiguration()
        classDiscriminator = "messageType"
    }

    internal fun sendMessage(message: WebSocketRequest): Job {
        val socket = socket ?: run {
            logInfo("Not sending socket message of type ${message::class.simpleName} as the socket is null")
            return CancelledJob()
        }

        if (!socket.isActive) {
            logError("Not sending socket message of type ${message::class.simpleName} because the socket is no longer active")
            return CancelledJob()
        }

        return coroutineScope.launch {
            try {
                socket.send(serializer.encodeToString(message))
            } catch (e: Exception) {
                this@ApiSocket.logError("Failed to send socket message", e)
            }
        }
    }

    // visibleForTesting
    internal fun deserializeMessage(message: Frame.Text): WebSocketResponse {
        val json = message.readText()
        println(json)
        return serializer.decodeFromString(json)
    }

    // visibleForTesting
    internal fun handleMessage(message: Frame.Text) {
        val decoded = deserializeMessage(message)
        when (decoded) {
            is NowListeningSocketResponse -> NowListeningService.processNowListeningResponse(decoded)
            is BackgroundTaskSocketResponse -> BackgroundTaskService.processSocketResponse(decoded)
            is ConnectionLostSocketResponse -> {
                NowListeningService.clear()
            }
            else -> {}
        }

        // Catch this or else the client code can kill our socket connection by doing something dumb.
        // Actually this doesn't SEEM to work, at least in JS. The exception thrown during this callback
        // somehow happens during "socket?.incoming?.receive()"? I hate this Socket library, like, a lot.
        try {
            socketEventHandler?.invoke(decoded)
        } catch (e: Throwable) {
            logError("Exception encountered in socket callback invocation!", e)
        }
    }
}

enum class EventType {
    // CONNECTION_LOST is not an event sent from the API. We send it from the library itself.
    NOW_PLAYING, REMOTE_PLAY, REVIEW_QUEUE, CONNECTION_ESTABLISHED, CONNECTION_LOST, BACKGROUND_TASK;

    companion object {
        fun getDefaultTypes(): Set<EventType> {
            return when (PlatformDeviceUtil.getDeviceType()) {
                DeviceType.WEB -> entries.toSet().minus(CONNECTION_LOST)
                else -> setOf(CONNECTION_ESTABLISHED)
            }
        }
    }
}
