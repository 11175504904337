package components.contextmenu

import components.TrackTable
import net.gorillagroove.track.TrackColumn
import net.gorillagroove.track.TrackSort
import org.w3c.dom.events.MouseEvent

fun openTrackColumnContextMenu(event: MouseEvent) {
    val definition = TrackSort.columnOptions

    // We don't want to give users the ability to customize these special columns. So remove them from any saved state and re-add them
    val columns = definition.filterNot { (column, _) ->
        column == TrackColumn.SORT_IDENTIFIER || column == TrackColumn.CLIENT_CUSTOM
    }

    val checkedIndexes = columns.mapIndexedNotNull { index, column ->
        return@mapIndexedNotNull if (column.second.enabled) {
            index
        } else {
            null
        }
    }

    ContextMenu.openCheckboxMenu(
        event = event,
        checkedIndexes = checkedIndexes.toSet(),
        options = columns.map { it.first.displayName }
    ) { index, isChecked ->
        val (_, columnDefinition) = columns[index]

        columnDefinition.enabled = isChecked

        TrackSort.columnOptions = definition

        TrackTable.fullRender(preserveExpansion = true)
    }
}
