package net.gorillagroove.history

import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.UserId
import net.gorillagroove.hardware.RawDeviceType

// TODO this entire thing is very subject to change. I just made this for GG's 2023 wrap-up,
//  but I plan to add more stats and not allow you to look at other user's stats and crap.
//  Right now it's locked down on the backend to only allow my own user to access this
object SiteStats {
    suspend fun getSiteStats(): SiteWideStatsResponse {
        return Api.get("track-history/test")
    }

    suspend fun getUserStats(userId: UserId): UserStatsResponse {
        return Api.get("track-history/test/${userId.value}")
    }
}

@Serializable
data class SiteWideStatsResponse(
    val playtimeByUser: List<LongByStringStat>,
    val playtimeByDevice: List<PlaytimeByDeviceTypeStat>,
    val playCountByUser: List<LongByStringStat>
)
@Serializable
data class UserStatsResponse(
    val mostListenedTracks: Map<String, Int>,
    val mostListenedArtists: Map<String, Int>,
    val mostListenedAlbums: Map<String, Int>,
    val distanceTraveled: Double
)

@Serializable
data class LongByStringStat(
    val longStat: Long,
    val stringStat: String,
)
@Serializable
data class PlaytimeByDeviceTypeStat(
    val playtime: Long,
    val deviceType: RawDeviceType,
)
