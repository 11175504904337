package net.gorillagroove.util

import kotlinx.coroutines.*
import net.gorillagroove.util.GGLog.logDebug
import kotlin.time.Duration

class Timer(
    private val period: Duration,
    private val onTick: () -> Unit
) {
    private val scope = CoroutineScope(Dispatchers.Default)
    private var job: Job? = null

    fun start() {
        if (job != null) {
            return
        }

        logDebug("Starting timer with period: $period")
        job = scope.launch {
            while (job?.isActive == true) {
                delay(period.inWholeMilliseconds)
                onTick()
            }
        }
    }

    fun stop() {
        job?.cancel()
        job = null
    }
}
