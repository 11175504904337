package net.gorillagroove.db

import kotlin.Long
import kotlin.String

public data class GetDistinctAlbums(
  public val album: String,
  public val min: Long?
) {
  public override fun toString(): String = """
  |GetDistinctAlbums [
  |  album: $album
  |  min: $min
  |]
  """.trimMargin()
}
