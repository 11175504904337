package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.LogLineId

public data class DbLogLine(
  public val id: LogLineId,
  public val timestamp: Instant,
  public val tag: String,
  public val severity: Long,
  public val message: String
) {
  public override fun toString(): String = """
  |DbLogLine [
  |  id: $id
  |  timestamp: $timestamp
  |  tag: $tag
  |  severity: $severity
  |  message: $message
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<LogLineId, Long>,
    public val timestampAdapter: ColumnAdapter<Instant, Long>
  )
}
