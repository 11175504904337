package components

import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import mainScope
import net.gorillagroove.discovery.BackgroundTaskItem
import net.gorillagroove.discovery.BackgroundTaskService
import net.gorillagroove.discovery.BackgroundTaskStatus
import net.gorillagroove.discovery.BackgroundTaskType
import net.gorillagroove.util.withoutExtension
import onClickSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLProgressElement
import query
import show

private val progressText get() = document.getElementById("progress-text") as HTMLElement?
private val progressContainer get() = document.getElementById("background-task-progress-container") as HTMLElement?
private val progressBar get() = document.getElementById("background-task-progress") as HTMLProgressElement?
private val backgroundTaskTableBody get() = document.querySelector("#background-task-popup tbody") as HTMLElement

@Suppress("FunctionName")
fun DIV.BackgroundTaskProgress() = div("d-none") {
    id = "background-task-progress-container"

    progress("thumbless") {
        id = "background-task-progress"
        max = "1"
        value = "0.5"
    }

    span {
        id = "progress-text"

        + "Processing 0 of 0"
    }

    div("popup") {
        id = "background-task-popup"

        table("full-width") {
            thead {
                tr {
                    th(classes = "full-width") { + "Item" }
                    th { + "Status" }
                    th(classes = "text-center") {
                        button(classes = "flat nowrap") {
                            + "Cancel All"

                            onClickSuspend = { BackgroundTaskService.cancelAll() }
                        }
                    }
                }
            }
            tbody {
            }
        }
    }

    mainScope.launch {
        BackgroundTaskService.refreshTaskState()

        BackgroundTaskProgress.render()
        BackgroundTaskProgress.renderPopup()
    }
}

object BackgroundTaskProgress {
    init {
        BackgroundTaskService.registerBackgroundTaskChangeHandler { task ->
            if (task.status == BackgroundTaskStatus.FAILED) {
                when (task.type) {
                    BackgroundTaskType.FILE_UPLOAD -> Toast.error("Failed to upload '${task.description}'")
                    BackgroundTaskType.YT_DOWNLOAD -> Toast.error("Failed to download '${task.description}'")
                    else -> Toast.error("Failed to process '${task.description}'")
                }
            } else if (task.status == BackgroundTaskStatus.COMPLETE) {
                Toast.success("Successfully finished '${task.description}'")
            }

            render()
            renderItem(task)
        }
    }

    fun render() {
        val text = progressText ?: return

        if (BackgroundTaskService.totalDownloads == 0) {
            progressContainer?.hide()
        } else {
            progressBar?.value = BackgroundTaskService.currentDownload.toDouble() / BackgroundTaskService.totalDownloads
            text.innerText = "Processing ${BackgroundTaskService.currentDownload + 1} of ${BackgroundTaskService.totalDownloads}"

            progressContainer?.show()
        }
    }

    private fun renderItem(task: BackgroundTaskItem) {
        if (progressText == null) {
            return
        }

        val row = backgroundTaskTableBody.querySelector("""tr[taskId="${task.id.value}"]""")
        if (row == null) {
            backgroundTaskTableBody.append(createRow(task))
        } else if (task.status == BackgroundTaskStatus.REMOVED) {
            row.remove()
        } else {
            val descriptionEl = row.query<HTMLElement>(".description")
            val statusEl = row.query<HTMLElement>(".status")
            val buttonEl = row.query<HTMLElement>(".icon")

            descriptionEl.innerText = task.description
            statusEl.innerText = task.status.displayName
            if (task.status.cancelable) buttonEl.show() else buttonEl.hide()
        }
    }

    fun renderPopup() {
        backgroundTaskTableBody.innerHTML = ""

        BackgroundTaskService.tasks.forEach { task ->
            backgroundTaskTableBody.append(createRow(task))
        }
    }

    private fun createRow(task: BackgroundTaskItem) = document.create.tr {
        attributes["taskId"] = task.id.value.toString()

        td("description") { + if (task.clientSideOnly) task.description.withoutExtension() else task.description }
        td("status") { + task.status.displayName }
        td("text-center") {
            val hiddenClass = if (task.status.cancelable) "" else "d-none"
            button(classes = "icon $hiddenClass") {
                i("fa-solid fa-xmark")

                onClickSuspend = {
                    BackgroundTaskService.cancelTasks(listOf(task.id))
                }
            }
        }
    }
}
