package net.gorillagroove.review

import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.track.*
import net.gorillagroove.util.findIndex

class ReviewSession(
    var allReviewSources: Map<ReviewSourceId, ReviewSource>,
    var sourcesNeedingReview: MutableList<ReviewSource>,
    var reviewSourceToTrackCount: MutableMap<ReviewSourceId, Int>,
    var visibleTracks: MutableList<Track> = mutableListOf(),
) {
    var activeSource: ReviewSource? = null
        internal set

    fun setNextActiveSource() {
        NowPlayingService.currentTrack?.takeIf { it.inReview }?.let { currentlyPlayingReviewTrack ->
            val reviewSourceId = currentlyPlayingReviewTrack.reviewSourceId!!
            if ((reviewSourceToTrackCount[reviewSourceId] ?: 0) > 0) {
                setActiveSource(reviewSourceId)
                return
            }
        }

        val sourcesByType = sourcesNeedingReview.groupBy { it.sourceType }
        // This is the priority in which we automatically show queues: user -> artist -> youtube
        val sourceToUse = listOf(ReviewSourceType.USER_RECOMMEND, ReviewSourceType.ARTIST, ReviewSourceType.YOUTUBE_CHANNEL).map { sourceType ->
            return@map sourcesByType[sourceType]?.firstOrNull()
        }.filterNotNull().firstOrNull()

        if (sourceToUse != null) {
            setActiveSource(sourceToUse.id)
        } else {
            activeSource = null
        }
    }

    fun setActiveSource(newSourceId: ReviewSourceId) {
        val newActiveSource = allReviewSources.getValue(newSourceId)
        activeSource = newActiveSource

        visibleTracks = ReviewQueueService.getTracksNeedingReviewOnSource(newActiveSource.id).toMutableList()
        reviewSourceToTrackCount[newSourceId] = visibleTracks.size // Just make sure it's up-to-date since we know the real size anyway

        // Though it shouldn't really happen, it's possible that our local state is out of sync because of background things changing tracks.
        // If there ended up not being tracks to review for this source, try the next one
        if (visibleTracks.isEmpty()) {
            setNextActiveSource()
        }
    }

    internal fun playNextAfterReview(track: Track, wasPlaying: Boolean) {
        val reviewSourceId = track.reviewSourceId!!

        val newTrackCount = reviewSourceToTrackCount.getValue(reviewSourceId) - 1
        reviewSourceToTrackCount[reviewSourceId] = newTrackCount

        if (newTrackCount == 0) {
            sourcesNeedingReview.removeAll { it.id == reviewSourceId }
        }

        fun removeAndPlayNext() {
            val index = NowPlayingService.tracks.findIndex { it.id == track.id }
            if (index != null) {
                NowPlayingService.removeTracksByIndex(listOf(index))
            }
        }

        if (activeSource?.id == reviewSourceId) {
            visibleTracks.removeAll { it.id == track.id }

            if (visibleTracks.isEmpty()) {
                setNextActiveSource()
                if (visibleTracks.isNotEmpty()) {
                    NowPlayingService.setNowPlayingTracks(
                        tracks = visibleTracks,
                        playFromIndex = if (wasPlaying) 0 else null,
                    )
                }
            } else {
                removeAndPlayNext()
            }
        } else {
            removeAndPlayNext()
        }
    }

    fun getSortedVisibleTracks(
        sort: List<Pair<TrackColumn, SortDirection>> = listOf(TrackColumn.SORT_IDENTIFIER to SortDirection.ASC)
    ): List<Track> {
        return TrackSort.sortTracks(visibleTracks, sort)
    }
}
