@file:Suppress("FunctionName")

package components

import PageRouter
import Toast
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.form
import mainScope
import net.gorillagroove.authentication.PasswordResetService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onSubmitSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import queryId
import kotlin.time.Duration.Companion.seconds

fun PasswordReset() = document.create.div {
    id = "password-reset"

    div {
        id = "password-reset-content"
    }

    mainScope.launch {
        val key = PageRouter.currentPathVariables.getValue("key")
        val response = try {
            PasswordResetService.getPasswordChange(key)
        } catch (e: Exception) {
            Toast.error("Could not find password reset")
            return@launch
        }

        document.queryId<HTMLElement>("password-reset-content").append {
            h2 {
                + "Hi, ${response.username}. Let's change your password"
            }

            form {
                onSubmitSuspend = onSubmit@{
                    val password1 = document.queryId<HTMLInputElement>("reset-password-input-1").value
                    val password2 = document.queryId<HTMLInputElement>("reset-password-input-2").value

                    if (password1 != password2) {
                        Toast.info("Passwords do not match")
                        return@onSubmit
                    }

                    try {
                        actionButtonChangeState("password-change-submit", isUpdating = true)

                        PasswordResetService.changePassword(password1, key)
                    } catch (e: Exception) {
                        Toast.error("Failed to change password")
                        GGLog.logError("Failed to change password!", e)

                        actionButtonChangeState("password-change-submit", isUpdating = false)
                        return@onSubmit
                    }

                    actionButtonChangeState("password-change-submit", isUpdating = false)

                    Toast.success("Password change was a success.")

                    delay(2.seconds)

                    PageRouter.navigateTo("/login")
                }

                label {
                    + "Password"

                    input(InputType.password) {
                        id = "reset-password-input-1"

                        minLength = "9"
                    }
                }

                label {
                    + "Password... again"

                    input(InputType.password) {
                        id = "reset-password-input-2"

                        minLength = "9"
                    }
                }

                label("text-left") {
                    small {
                        + "I'll think about using a password manager this time"
                    }

                    input(InputType.checkBox) {  }
                }

                div("mt-12") {
                    ActionButton("password-change-submit", "Reset")

                    div("mt-12") {
                        small {
                            +"Encrypted with military-grade gorillas"
                        }
                    }
                }
            }
        }
    }
}
