package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.PlaylistTrackId
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.TrackId
import net.gorillagroove.db.*
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.sync.*
import net.gorillagroove.track.TrackService
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog.logDebug

object PlaylistTrackSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.PLAYLIST_TRACK

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<PlaylistTrackResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            // If this is null, it would have to mean we got logged out during a sync, which should not
            // be possible if we cancel the sync job when a logout is performed.
            val currentUserId = UserService.requireCurrentUserId()

            Database.db.transaction {
                val apiIds = changeSet.newAndModified.map { it.track.id }
                val trackApiIdToLocalId = TrackService.findLocalIdForApiId(apiIds).toMutableMap()

                changeSet.newAndModified.forEach { response ->
                    // If we are not the owner of the track, then it is important that we save it.
                    // (Other users' tracks are not managed by the TrackSynchronizer)
                    if (response.track.userId != currentUserId) {
                        val track = response.track.asTrack(trackApiIdToLocalId)
                        TrackService.save(track).also { localId ->
                            trackApiIdToLocalId[response.track.id] = localId
                        }
                    }
                    playlistTrackDao.upsert(response.asPlaylistTrack(trackApiIdToLocalId))
                }

                if (changeSet.removed.isNotEmpty()) {
                    val ptIds = changeSet.removed.map { PlaylistTrackId(it) }
                    val playlistTracks = playlistTrackDao.findByIds(ptIds).many()

                    val unownedTracksBeingRemoved = trackDao.findByIds(playlistTracks.map { it.trackId })
                        .many()
                        .filterNot { it.userId == currentUserId }
                        .map { it.id }
                        .toSet()

                    ptIds.forEach { playlistTrackDao.delete(it) }

                    // If all the tracks being deleted were our own, then we do not need to do any more checking.
                    if (unownedTracksBeingRemoved.isEmpty()) {
                        return@transaction
                    }

                    logDebug("PlaylistTracks with Tracks we do not own were deleted: $unownedTracksBeingRemoved")

                    // When a Track that we own is deleted, that change comes down through the TrackSync.
                    // However, if someone else added a Track to a Playlist that we are on, then we sync down Tracks that
                    // do not belong to us. We do not delete these Tracks via the TrackSync, as the Track isn't actually deleted.
                    // We instead have to listen for the PlaylistTrack being deleted, and delete the Track afterwards.
                    // HOWEVER, that same Track could be on multiple playlists, so the fact that it was deleted
                    // from one Playlist does NOT guarantee that we should delete the Track.
                    // So now we need to check if the tracks that got deleted still have any active references,
                    // and only go ahead with the deletion if they do not.

                    val activeTrackIds = playlistTrackDao.findByTrackIds(unownedTracksBeingRemoved)
                        .many()
                        .map { it.trackId }
                        .toSet()

                    logDebug("Of the Tracks being removed, the following were still active: $activeTrackIds")

                    val tracksToDelete = unownedTracksBeingRemoved - activeTrackIds

                    logDebug("Proceeding to delete the tracks: $tracksToDelete")
                    trackDao.deleteByIds(tracksToDelete)
                }
            }
        }
    }
}

@Serializable
data class PlaylistTrackResponse(
    val id: PlaylistTrackId,
    val track: TrackResponse,
    val playlistId: PlaylistId,
    val sortOrder: Int,
    val createdAt: Instant,
) {
    fun asPlaylistTrack(apiTrackIdsToLocalIds: Map<TrackApiId, TrackId>) = DbPlaylistTrack(
        id = id,

        trackId = apiTrackIdsToLocalIds[track.id]
            // I shouldn't need to do this, but due to what seems like a KotlinJS bug in production, the map of
            // value classes gets weirded out, and it won't find stuff so I do a query to the DB. This does
            // not reproduce in a unit test for whatever reason.
            // IDK man. I'd just try removing this and adding a track to a playlist once we are on
            // a later version of Kotlin / kotlinx.serialization and pray to the gods it works without the ?:
            ?: TrackService.findLocalIdForApiId(listOf(track.id)).getValue(track.id),
        playlistId = playlistId,
        sortOrder = sortOrder,
        createdAt = createdAt,
    )
}
