package net.gorillagroove.hardware

import kotlinx.browser.window
import net.gorillagroove.track.AudioFormat

internal actual object PlatformDeviceUtil {
    val isSafari by lazy {
        window.navigator.vendor.contains("Apple")
    }

    actual fun getDeviceName(): String? {
        // Simply no way to get anything useful in a browser in 2022.
        // Omitting the name will have the API provide a default LOTR name, which is still ok
        return null
    }

    actual fun getDeviceType() = DeviceType.WEB

    // This won't work for mobile safari. They do not have OGG support.
    // But I was running into annoying issues trying to regex the user agent to change
    // this to MP3 for mobile safari only (as desktop safari supports OGG). Nobody should
    // realistically be using mobile browsers for GG any longer anyway, as the apps exist
    // and are significantly better than the awful browser experience.
    actual fun getDefaultAudioFormat() = if (isSafari) AudioFormat.MP3 else AudioFormat.OGG
}
