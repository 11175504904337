package net.gorillagroove.user.permission

import net.gorillagroove.db.Database.userPermissionDao
import net.gorillagroove.db.many
import net.gorillagroove.db.oneOrNull

object PermissionService {
    fun hasPermission(permissionType: UserPermissionType): Boolean {
        return userPermissionDao.findByType(permissionType.toRawType()).oneOrNull() != null
    }

    fun getOwnPermissions(): List<UserPermission> {
        return userPermissionDao.findAll().many().map { it.toUserPermission() }
    }
}
