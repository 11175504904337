@file:Suppress("FunctionName")

package components

import PageRouter
import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.form
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.user.AccountService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onSubmitSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import queryId
import show

fun AccountCreation() = document.create.div {
    var postCreateEmail = ""

    id = "account-creation"

    div {
        id = "account-creation-content"
    }

    div("d-none") {
        id = "account-creation-success"

        h2 {
            + "Let's begin our Journey, "
            strong { id = "post-create-username" }
        }

        img(classes = "animation-pulse") {
            src = "/assets/logo.png"
        }

        @Suppress("UNUSED_PARAMETER")
        fun navigateToLogin(e: Event) {
            PageRouter.navigateTo("/login?email=$postCreateEmail")
        }

        div {
            button(classes = "primary mr-12") {
                + "Yes"
                onClickFunction = ::navigateToLogin
            }

            button(classes = "primary ml-12") {
                + "Also Yes"
                onClickFunction = ::navigateToLogin
            }
        }
    }

    mainScope.launch {
        val key = PageRouter.currentPathVariables.getValue("key")
        val content = document.queryId<HTMLElement>("account-creation-content")

        val inviteLink = try {
            AccountService.getInvitation(key)
        } catch (e: Exception) {
            content.innerText = "No invitation was found with this link. It may have expired"
            return@launch
        }

        document.queryId<HTMLElement>("account-creation-content").append {
            if (inviteLink.alreadyUsed) {
                content.innerText = "This invitation has already been used"
                return@append
            }

            h2 {
                + "You were invited by ${inviteLink.invitingUserName} to "
                strong { + "Start Groovin'" }
            }

            form {
                onSubmitSuspend = onSubmit@{
                    val password1 = document.queryId<HTMLInputElement>("account-create-password-input-1").value
                    val password2 = document.queryId<HTMLInputElement>("account-create-password-input-2").value

                    if (password1 != password2) {
                        Toast.info("Passwords do not match")
                        return@onSubmit
                    }

                    val username = document.queryId<HTMLInputElement>("account-create-username").value
                    val email = document.queryId<HTMLInputElement>("account-create-email").value

                    try {
                        actionButtonChangeState("account-create-submit", isUpdating = true)

                        AccountService.createAccount(username = username, email = email, password = password1, inviteKey = key)
                    } catch (e: Exception) {
                        Toast.error("Failed to create account")
                        GGLog.logError("Failed to create account!", e)

                        actionButtonChangeState("account-create-submit", isUpdating = false)
                        return@onSubmit
                    }

                    postCreateEmail = email

                    actionButtonChangeState("account-create-submit", isUpdating = false)

                    document.queryId<HTMLElement>("post-create-username").innerText = username

                    content.hide()
                    document.queryId<HTMLElement>("account-creation-success").show()
                }

                label {
                    + "Username"

                    input(InputType.text) {
                        id = "account-create-username"
                        required = true
                    }
                }

                label {
                    + "Email"

                    input(InputType.email) {
                        id = "account-create-email"
                        required = true
                    }
                }

                label {
                    + "Password"

                    input(InputType.password) {
                        id = "account-create-password-input-1"

                        minLength = "8"
                        required = true
                    }
                }

                label {
                    + "Password... again"

                    input(InputType.password) {
                        id = "account-create-password-input-2"

                        minLength = "8"
                        required = true
                    }
                }

                div("mt-12") {
                    div("mt-12 mb-12") {
                        small {
                            +"Encrypted with military-grade gorillas"
                        }
                    }

                    ActionButton("account-create-submit", "Take me to the Groove Zone")
                }
            }
        }
    }
}
