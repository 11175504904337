package net.gorillagroove.review

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.serialization.Serializable
import net.gorillagroove.util.GGLog.logError
import kotlin.jvm.JvmInline

enum class ReviewSourceType(val displayName: String, val creatable: Boolean) {
    USER_RECOMMEND("User Recommendation", creatable = false),
    YOUTUBE_CHANNEL("YouTube Channel", creatable = true),
    ARTIST("Artist", creatable = true),
    UNKNOWN("Unknown", creatable = false),
    ;

    fun toRawType() = RawReviewSourceType(this.name)
}

@Serializable
@JvmInline
value class RawReviewSourceType(val value: String) {
    fun asEnumeratedType(): ReviewSourceType {
        return try {
            ReviewSourceType.valueOf(value)
        } catch (e: Throwable) {
            this.logError("Unknown enumerated type value '$value' for enum '${ReviewSourceType::class.simpleName}'")
            ReviewSourceType.UNKNOWN
        }
    }
}

object RawReviewSourceTypeAdapter : ColumnAdapter<RawReviewSourceType, String> {
    override fun decode(databaseValue: String) = RawReviewSourceType(databaseValue)
    override fun encode(value: RawReviewSourceType) = value.value
}
