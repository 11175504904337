@file:Suppress("FunctionName")

package components

import hide
import invisible
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import net.gorillagroove.user.AccountService
import net.gorillagroove.user.UserService
import net.gorillagroove.util.GGLog.logError
import org.w3c.dom.HTMLElement
import queryId
import show

fun UserInviteModal() = document.create.div {
    id = "user-invite-modal"

    h3 { + "Invite User" }

    div {
        id = "user-invite-text"

        + "Invite a new user to start grooving?"
    }

    textArea(classes = "full-width d-none") {
        id = "user-invite-link"
    }

    ActionButton("user-invite-button", "Invite", classes = "mt-12") {
        actionButtonChangeState("user-invite-button", isUpdating = true)

        val link = try {
            window.location.origin + AccountService.invite()
        } catch (e: Exception) {
            Toast.error("Failed to get user invitation")
            logError("Failed to get user invitation!", e)

            actionButtonChangeState("user-invite-button", isUpdating = false)

            return@ActionButton
        }

        val button = document.getElementById("user-invite-button") ?: return@ActionButton
        button.invisible()

        document.queryId<HTMLElement>("user-invite-text").innerText = "Send this link to the future Groover"
        document.queryId<HTMLElement>("user-invite-link").apply {
            this.innerText = link
            this.show()
        }
        document.queryId<HTMLElement>("user-invite-button").hide()
        document.queryId<HTMLElement>("copy-to-clipboard").show()
    }

    button(classes = "flat d-none mt-12") {
        id = "copy-to-clipboard"

        + "Copy to clipboard"

        onClickFunction = {
            val link = document.queryId<HTMLElement>("user-invite-link").innerHTML
            window.navigator.clipboard.writeText(link)

            Toast.info("Link copied to clipboard")
        }
    }
}
