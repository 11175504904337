package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.UserFavoriteId
import net.gorillagroove.sync.RawUserFavoriteType

public data class DbUserFavorite(
  public val id: UserFavoriteId,
  public val type: RawUserFavoriteType,
  public val value_: String,
  public val createdAt: Instant,
  public val updatedAt: Instant
) {
  public override fun toString(): String = """
  |DbUserFavorite [
  |  id: $id
  |  type: $type
  |  value_: $value_
  |  createdAt: $createdAt
  |  updatedAt: $updatedAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<UserFavoriteId, Long>,
    public val typeAdapter: ColumnAdapter<RawUserFavoriteType, String>,
    public val createdAtAdapter: ColumnAdapter<Instant, Long>,
    public val updatedAtAdapter: ColumnAdapter<Instant, Long>
  )
}
