package net.gorillagroove.track

import io.ktor.client.network.sockets.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.api.Api
import net.gorillagroove.api.FailedListenId
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.isNoInternetException
import net.gorillagroove.db.*
import net.gorillagroove.sync.strategies.TrackResponse
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.Lock
import net.gorillagroove.util.use
import net.gorillagroove.track.NowPlayingEventType.*
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.PlatformUtil
import net.gorillagroove.util.TimeUtil.now

// This is the percentage of a track we must listen to in order for it to qualify as "being listened to"
const val TARGET_LISTEN_PERCENT = 0.6

data class LocationPoint(val latitude: Double, val longitude: Double)
typealias LocationGatheringHandler = () -> LocationPoint

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
internal object MarkListenedService {
    private val coroutineScope = CoroutineScope(Dispatchers.Default)

    private var totalTimeListened = 0.0
    private var targetListenTime = Double.MAX_VALUE
    private var lastListenTime = 0.0

    internal var trackListenedTo = false
    private var locationHandlerWarningEmitted = false

    // Although probably technically possible, dealing with creating a KMP implementation
    // for location gathering on each OS sounds pretty awful due to how locked down it all
    // tends to be, and how different each device's requirements are. As a result, if a
    // particular consumer of this library supports location gathering, they can set this
    // optional handler instead, and we will use it if it has been set.
    internal var locationGatheringHandler: LocationGatheringHandler? = null

    private val lock: Lock = Lock()

    internal fun registerTrackChangeListener() {
        NowPlayingService.registerEventHandler { event ->
            val eventType = event.type
            if (
                eventType == CURRENT_TRACK_CHANGED
                || eventType == ALL_TRACKS_CHANGED
                || eventType == PLAYBACK_STOPPED
            ) {
                reset()
            }
        }
    }

    fun updateCurrentTrackPlayPosition(time: Double) = lock.use {
        // Maybe one day I will make it so that you can listen to something multiple times,
        // but today is not that day. So for now, once it's been listened a single time we no longer care.
        if (trackListenedTo) {
            return@use
        }

        val currentTrack = NowPlayingService.currentTrack ?: run {
            return@use
        }

        val timeElapsed = time - lastListenTime
        lastListenTime = time

        if (timeElapsed < 0) {
            logDebug("Not updating total listen time, as it moved backwards")
        } else if (timeElapsed > 5) {
            logDebug("Not updating total listen time, as it moved forward excessively")
        } else {
            totalTimeListened += timeElapsed
        }

        if (totalTimeListened > targetListenTime) {
            trackListenedTo = true

            if (locationGatheringHandler == null && !locationHandlerWarningEmitted) {
                logWarn("No location gathering handler has been configured. Location will not be saved")
                locationHandlerWarningEmitted = true
            }

            currentTrack.apiId?.let { id ->
                if (!GGCommonInternal.isIntegrationTesting) {
                    coroutineScope.launch {
                        sendListenRequest(id)
                    }
                }
            }
        }
    }

    // VisibleForTesting
    internal suspend fun sendListenRequest(currentTrackId: TrackApiId) {
        val locationPoint = locationGatheringHandler?.invoke()
        val request = MarkListenedRequest(
            trackId = currentTrackId,
            timeListenedAt = now(),
            ianaTimezone = PlatformUtil.getIanaTimezone(),
            latitude = locationPoint?.latitude,
            longitude = locationPoint?.longitude
        )

        if (OfflineModeService.offlineModeEnabled) {
            logDebug("Offline mode was enabled while attempting to mark Track ${currentTrackId.value} as listened to. This will be sent later")
            Database.failedListenDao.insert(request.toFailedListen())

            Database.forceSave()
            return
        }

        logInfo("Marking Track ${currentTrackId.value} as listened to")
        try {
            val updatedTrack = Api.post<TrackResponse>("track/mark-listened", request).asTrack()

            TrackService.save(updatedTrack)

            TrackService.broadcastTrackChange(listOf(updatedTrack.toTrack()), ChangeType.UPDATED)
        } catch (e: Exception) {
            if (e.isNoInternetException()) {
                logError("Timed out while marking Track ${currentTrackId.value} as listened to. This will be retried")
                Database.failedListenDao.insert(request.toFailedListen())

                Database.forceSave()
            } else {
                // If the track we are marking listened has been deleted on the API side, then this could be normal
                logError("Failed to mark track ${currentTrackId.value} as listened to. This will not be retried", e)
            }
        }
    }

    internal suspend fun retryFailedListens() {
        if (OfflineModeService.offlineModeEnabled) {
            return
        }

        val failedListens = Database.failedListenDao.findAll().many()
        if (failedListens.isEmpty()) {
            return
        }

        logInfo("Found ${failedListens.size} FailedListen(s) to retry")

        failedListens.forEach { failedListen ->
            val request = MarkListenedRequest(
                trackId = failedListen.trackId,
                timeListenedAt = failedListen.timeListenedAt,
                ianaTimezone = failedListen.ianaTimezone,
                latitude = failedListen.latitude,
                longitude = failedListen.longitude
            )

            try {
                Api.post<Unit>("track/mark-listened", request)
                Database.failedListenDao.delete(failedListen.id)

                Database.forceSave()
            } catch (e: Exception) {
                if (e !is ConnectTimeoutException) {
                    // If the track we are marking listened has been deleted on the API side, then this could be normal
                    logError("Failed to mark track ${request.trackId.value} as listened to. This will not be retried", e)
                    Database.failedListenDao.delete(failedListen.id)

                    Database.forceSave()
                }
            }
        }

        logDebug("Done retrying FailedListens")
    }

    private fun reset() = lock.use {
        totalTimeListened = 0.0
        lastListenTime = 0.0
        trackListenedTo = false

        targetListenTime = NowPlayingService.currentTrack?.length?.times(TARGET_LISTEN_PERCENT) ?: Double.MAX_VALUE
    }
}

@Serializable
internal data class MarkListenedRequest(
    val trackId: TrackApiId,
    val timeListenedAt: Instant,
    val ianaTimezone: String,
    val latitude: Double?,
    val longitude: Double?
) {
    fun toFailedListen() = DbFailedListen(
        id = FailedListenId(0),
        trackId = trackId,
        timeListenedAt = timeListenedAt,
        ianaTimezone = ianaTimezone,
        latitude = latitude,
        longitude = longitude
    )
}

