package net.gorillagroove.track

object RemotePlayService {
}

enum class RemotePlayType {
    PLAY_SET_SONGS,
    PLAY_NEXT,
    PLAY_PREVIOUS,
    ADD_SONGS_NEXT,
    ADD_SONGS_LAST,
    PAUSE,
    PLAY,
    SEEK,
    SHUFFLE_ENABLE,
    SHUFFLE_DISABLE,
    REPEAT_ENABLE,
    REPEAT_DISABLE,
    SET_VOLUME,
    MUTE,
    UNMUTE,
}
