package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.UserFavoriteId
import net.gorillagroove.db.Database
import net.gorillagroove.db.Database.userFavoriteDao
import net.gorillagroove.db.DbSyncStatus
import net.gorillagroove.db.DbUserFavorite
import net.gorillagroove.sync.*
import net.gorillagroove.sync.SyncDownStrategy
import net.gorillagroove.sync.fetchSyncEntities

object UserFavoriteSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.USER_FAVORITE

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<UserFavoriteResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            Database.db.transaction {
                changeSet.newAndModified.forEach {
                    val setting = it.asDbRecord()
                    userFavoriteDao.upsert(setting)
                }
                changeSet.removed.forEach {
                    userFavoriteDao.delete(UserFavoriteId(it))
                }
            }
        }
    }
}

@Serializable
internal data class UserFavoriteResponse(
    val id: UserFavoriteId,
    val type: RawUserFavoriteType,
    val value: String,
    val deleted: Boolean,
    val createdAt: Instant,
    val updatedAt: Instant,
) {
    fun asDbRecord() = DbUserFavorite(
        id = id,
        type = type,
        value_ = value,
        createdAt = createdAt,
        updatedAt = updatedAt,
    )
}
