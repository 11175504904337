package net.gorillagroove.localstorage

import kotlinx.browser.document
import kotlinx.browser.window
import net.gorillagroove.hardware.PlatformDeviceUtil.isSafari
import net.gorillagroove.util.Seconds

object SiteCookies {

    fun addCookie(name: String, value: String, duration: Seconds = ONE_YEAR) {
        if (isSafari) {
            document.cookie = "${name}=${value};max-age=${duration};path=/;SameSite=Lax"
        } else {
            document.cookie = "${name}=${value};max-age=${duration};path=/;SameSite=None;Secure=true"
        }
    }

    fun deleteCookie(name: String) {
        document.cookie = "$name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    }

    fun getCookieValue(name: String): String? {
        val allCookies = "; " + document.cookie
        val parts = allCookies.split("; $name=")

        return if (parts.size == 2) {
            parts
                .last()
                .split(";")
                .first()
        } else {
            null
        }
    }
}
