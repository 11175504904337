package net.gorillagroove.discovery

import io.ktor.http.*
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.BackgroundTaskId
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.stripQueryParams
import net.gorillagroove.track.Track
import net.gorillagroove.track.toTrack
import net.gorillagroove.sync.strategies.TrackResponse
import net.gorillagroove.track.TrackService
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo

object ImportService {
    suspend fun importUserTracks(ids: List<TrackApiId>): List<Track> {
        require(ids.isNotEmpty()) { "At least one ID is required!" }

        logInfo("Importing tracks: ${ids.map { it.value }}")
        return Api.post<MultiTrackResponse>("track/import", MultiTrackIdRequest(ids))
            .items
            .map { it.addNewTrack() }
    }

    suspend fun uploadTrack(trackData: ByteArray, filename: String): BackgroundTaskResponse {
        return uploadTrack(trackData, filename, taskItem = null)
    }

    suspend fun replaceTrackAudio(trackData: ByteArray, filename: String, replacedTrackId: TrackApiId): BackgroundTaskResponse {
        return uploadTrack(trackData, filename, taskItem = null, replacedTrackId = replacedTrackId)
    }

    private suspend fun uploadTrack(
        trackData: ByteArray,
        filename: String,
        taskItem: BackgroundTaskItem?,
        replacedTrackId: TrackApiId? = null
    ): BackgroundTaskResponse {
        logInfo("Starting upload of track $filename of size ${trackData.size}")

        val task = taskItem ?: createUploadTask(filename).also { newTask ->
            BackgroundTaskService.addBackgroundTasks(listOf(newTask))
        }

        BackgroundTaskService.changeClientOnlyBackgroundItem(task.id, BackgroundTaskStatus.UPLOADING)

        val response = try {
            val url = "background-task/" + if (replacedTrackId != null) "${replacedTrackId.value}/audio-upload-replacement" else "upload"
            Api.upload<BackgroundTaskResponseInternal>(url, "file", filename, trackData)
        } catch (e: Exception) {
            BackgroundTaskService.changeClientOnlyBackgroundItem(task.id, BackgroundTaskStatus.FAILED)
            throw e
        }

        val serverTask = response.getConvertedItems().first()
        BackgroundTaskService.changeClientOnlyBackgroundItem(task.id, serverTask = serverTask)

        return response.asPublic()
    }

    suspend fun uploadTracks(filenames: List<String>, fetchTrackData: suspend (String) -> ByteArray) {
        logDebug("Preparing upload of ${filenames.size} track(s)")

        val taskItems = filenames.map { createUploadTask(it) }
        BackgroundTaskService.addBackgroundTasks(taskItems)

        taskItems.forEach { item ->
            val refreshedItem = BackgroundTaskService.idToTask[item.id] ?: run {
                logError("Could not find refreshed background task while bulk uploading!")
                return@forEach
            }

            if (refreshedItem.status.finished) {
                return@forEach
            }

            val filename = item.description
            val data = fetchTrackData(filename)
            try {
                uploadTrack(data, filename, item)
            } catch (e: Exception) {
                logError("Failed to upload track $filename!", e)
            }
        }
    }

    private fun createUploadTask(filename: String): BackgroundTaskItem {
        return BackgroundTaskItem(
            id = BackgroundTaskId((--BackgroundTaskService.nextClientSideId).toLong()),
            clientSideOnly = true,
            status = BackgroundTaskStatus.PENDING_UPLOAD,
            type = BackgroundTaskType.FILE_UPLOAD,
            description = filename,
        )
    }

    suspend fun downloadFromUrl(request: TrackImportRequest, stripPlaylist: Boolean = false): BackgroundTaskResponse {
        val modifiedRequest = if (stripPlaylist) {
            val newUrl = Url(request.url).stripQueryParams("list", "index")
            request.copy(url = newUrl)
        } else {
            request
        }

        logInfo("Starting download of Track from URL ${modifiedRequest.url}")
        return Api.post<BackgroundTaskResponseInternal>("background-task/youtube-dl", modifiedRequest).also { response ->
            BackgroundTaskService.addBackgroundTasks(response.getConvertedItems())
        }.asPublic()
    }

    suspend fun downloadFromMetadata(request: MetadataImportRequest): BackgroundTaskResponse {
        logInfo("Starting metadata download of Track with name ${request.name} from artist ${request.artist}")
        return Api.post<BackgroundTaskResponseInternal>("background-task/metadata-dl", request).also { response ->
            BackgroundTaskService.addBackgroundTasks(response.getConvertedItems())
        }.asPublic()
    }

    private fun TrackResponse.addNewTrack(): Track {
        val track = this.asTrack()
        logDebug("Upserting track with ID ${track.id.value}")
        val newId = TrackService.save(track, useTransaction = true)
        return track.toTrack(overrideId = newId)
    }
}

@Serializable
data class TrackImportRequest(
    val url: String,
    val name: String? = null,
    val artist: String? = null,
    val album: String? = null,
    val releaseYear: Int? = null,
    val trackNumber: Int? = null,
    val genre: String? = null,
    val featuring: String? = null,
    val cropArtToSquare: Boolean = false,
    val note: String? = null,
    val artUrl: String? = null,
    val usePlaylistOrderAsTrackOrder: Boolean = false,
)

@Suppress("unused")
@Serializable
class MetadataImportRequest(
    val name: String,
    val artist: String,
    val album: String,
    val releaseYear: Int,
    val trackNumber: Int,
    val albumArtLink: String? = null,
    val length: Int,
    val artistQueueName: String? = null,
    val addToReview: Boolean = false
)
