package net.gorillagroove.util

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.db.Database
import net.gorillagroove.db.DbLogLine
import net.gorillagroove.api.LogLineId
import net.gorillagroove.api.isBenignException
import net.gorillagroove.db.Database.logLineDao
import net.gorillagroove.db.many
import net.gorillagroove.db.one
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.reporting.ProblemReportService
import net.gorillagroove.sync.SyncCoordinator

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object GGLog {
    var maxLogSize = 5_242_880 // 5 MiB
    var crashOnCrit: Boolean = false // Consumers of the library should set to true on their DEBUG builds

    internal var estimatedLogSize = 0.0

    internal var logTrimAmount = 5000L

    @Suppress("MemberVisibilityCanBePrivate")
    var minimumLogLevel = LogLevel.DEBUG

    private val coroutineScope = CoroutineScope(Dispatchers.Default)

    private val logBuffer = mutableListOf<DbLogLine>()

    private val lock: Lock = Lock()

    var standardOutLogging = true
    var databaseLogging = true

    private var initialized = false

    internal fun init() {
        if (GGCommonInternal.isIntegrationTesting || initialized) {
            return
        }

        initialized = true
        // Writing log files out to the database is relatively expensive, and they are only used when someone is viewing
        // on-device logs or sending in a problem report.
        // As such, we hold the logs in a buffer and periodically dump them to the db in bulk on a background thread.
        coroutineScope.launch {
            // This DB query is expensive, so I am doing it on the background thread here
            estimatedLogSize = logLineDao.getEstimatedByteUsage().one().expr ?: 0.0

            while (true) {
                delay(1000)
                flush()
            }
        }
    }

    fun Any.logDebug(message: String, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message, LogLevel.DEBUG)
    }
    fun Any.logInfo(message: String, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message, LogLevel.INFO)
    }
    fun Any.logWarn(message: String, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message, LogLevel.WARNING)
    }
    fun Any.logError(e: Throwable, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, e.getLoggedMessage(), LogLevel.ERROR)
    }
    fun Any.logError(message: String, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message, LogLevel.ERROR)
    }
    fun Any.logError(message: String, e: Throwable, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message + "\n${e.getLoggedMessage()}", LogLevel.ERROR)
    }
    fun Any.logCrit(message: String, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message, LogLevel.CRITICAL)
    }
    fun Any.logCrit(message: String, e: Throwable, customTag: String? = null) {
        logMessage(customTag ?: this.logTag, message + "\n${e.getLoggedMessage()}", LogLevel.CRITICAL)
    }

    // I ran into situations where some stuff had no simpleName, but their enclosing classes did.
    private val Any.logTag: String get() {
        val name = this::class.simpleName
        return if (name.isNullOrBlank()) "UNKNOWN" else name
    }

    private fun logMessage(tag: String, message: String, logLevel: LogLevel) {
        if (logLevel.severity < minimumLogLevel.severity) {
            return
        }

        // toString() on an instant will format it to UTC. There is currently no way to provide a custom format.
        // I am then choosing to eliminate the T in "2010-06-01T22:19:44.475Z" because these logs are meant
        // to be parsed by human eyes, and not having the date and time run together improves readability.
        val instant = now()

        logToStandardOut(instant, tag, message, logLevel)
        logToDatabase(instant, tag, message, logLevel)

        if (logLevel == LogLevel.CRITICAL) {
            if (ProblemReportService.showCriticalErrors) {
                ProblemReportService.showErrorDialog(message)
            }

            if (crashOnCrit) {
                throw CriticalLogException(message)
            }

            if (!GGCommonInternal.isIntegrationTesting) {
                coroutineScope.launch {
                    handleFatalError()
                }
            }
        }
    }

    private fun logToStandardOut(time: Instant, tag: String, message: String, logLevel: LogLevel) {
        if (!standardOutLogging) {
            return
        }

        val formattedTime = time.toString().replace("T", " ")

        val standardOutLine = "$formattedTime [$tag] [${logLevel.logName}]: $message"

        PlatformLogger.logLine(logLevel, tag, standardOutLine)
    }

    private fun logToDatabase(time: Instant, tag: String, message: String, logLevel: LogLevel) {
        if (!databaseLogging || !Database.isInitialized) {
            return
        }

        val logLine = DbLogLine(
            id = LogLineId(0),
            timestamp = time,
            tag = tag,
            severity = logLevel.severity.toLong(),
            message = message
        )

        val newDiskUsage = logLine.estimatedDiskUsage
        lock.use {
            estimatedLogSize += newDiskUsage
            logBuffer.add(logLine)
        }
    }

    fun flush(forceFlush: Boolean = false, ignoreLogTrim: Boolean = false) = lock.use {
        if (!ignoreLogTrim && estimatedLogSize > maxLogSize && Database.isInitialized) {
            logInfo("About to trim $logTrimAmount logs...")
            logLineDao.deleteOldestNLogs(logTrimAmount)

            val oldEstimatedSize = estimatedLogSize
            val newSize = (logLineDao.getEstimatedByteUsage().one().expr ?: 0.0) + logBuffer.sumOf { it.estimatedDiskUsage }

            estimatedLogSize = newSize

            logInfo("Log table shrank from $oldEstimatedSize to $newSize, saving ${oldEstimatedSize - newSize} bytes")
        }

        // FIXME this is, far and away, the stupidest thing I've done in this entire library.
        //  There is a bug that somehow can cause first sync to deadlock. I don't know how.
        //  I don't know why. But it doesn't seem to happen if I don't log to the database.
        //  I've tried wrapping the database transactions in a Mutex so that they don't try
        //  to run at the same time. Didn't work. I've tried removing all explicit transactions,
        //  and that lead to incorrect results from 'last_insert_rowid' for no good reason.
        //  I've tried making it so the logger has a different database connection, which sort
        //  of works. Instead of hanging, I get SQLITE_BUSY errors. If I could guarantee that
        //  only the logger would get this error, I would just roll with that. But it's equally
        //  likely that the more important syncing process gets the error.
        //  This is beyond frustrating and I am just going to hope that updating a bunch of
        //  libraries in the future will address this.
        if (!forceFlush && SyncCoordinator.isSyncing()) {
            return@use
        }

        logLineDao.transaction {
            // SqlDelight does not offer support for actual bulk insertion right now, and it kind of
            // seems like they probably never will add support. This is a bummer, but supposedly doing
            // a bunch of inserts in a single transaction with one compiled query is pretty fast.
            // There is no alternative, so this is what we will have to do regardless.
            logBuffer.forEach { logLine ->
                logLineDao.insert(logLine)
            }
        }
        logBuffer.clear()
    }

    // Intentionally left public. But I'm too lazy to write a unit test for what basically just
    // calls two functions that were unit tested elsewhere.
    // This is public because the Android and iOS apps will send logs when they are about to crash
    @Suppress("MemberVisibilityCanBePrivate")
    suspend fun handleFatalError() {
        // Log trimming can be a bit expensive, and we want to get this sent out ASAP. So, ignore it.
        flush(forceFlush = true, ignoreLogTrim = true)
        ProblemReportService.sendAutomatedProblemReport()
    }

    internal fun clearLogs() {
        logLineDao.deleteAll()
        logBuffer.clear()
        estimatedLogSize = 0.0
    }

    fun getLogLines(): List<DbLogLine> {
        return logLineDao.findAll().many()
    }
}

enum class LogLevel(val severity: Int, val logName: String) {
    DEBUG(1, "debug"),
    INFO(2, "info"),
    WARNING(3, "warn"),
    // I have no idea why I skipped 4. I'm guessing it was a mistake. But it is what it is now I guess.
    ERROR(5, "error"),
    CRITICAL(6, "crit");

    companion object {
        private val severityMap: Map<Int, LogLevel> by lazy {
            entries.associateBy { it.severity }
        }

        fun fromSeverity(priority: Int): LogLevel {
            return severityMap.getValue(priority)
        }
    }
}

// There is a long-winded comment about how we do this estimation in DbLogLine.sq.
// This just does basically the same calculation to keep it consistent
val DbLogLine.estimatedDiskUsage: Double get() {
    return ((this.message.length + this.tag.length) * 1.1) +
            6 + // Timestamp
            4 + // ID
            1 // Priority
}

class CriticalLogException(message: String): Throwable(message)

internal fun Throwable.getLoggedMessage(): String {
    return if (this.isBenignException()) {
        "${this::class.simpleName} - ${this.message}"
    } else {
        // The exceptions that get generated on web are pretty sparse. The simplename is helpful here.
        if (DeviceUtil.getDeviceType() == DeviceType.WEB) {
            "${this::class.simpleName} - ${this.message}" + "\n" + this.stackTraceToString()
        } else {
            this.stackTraceToString()
        }
    }
}
