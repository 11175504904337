package net.gorillagroove.playlist

import kotlinx.datetime.Instant
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.db.DbPlaylist

class Playlist (
    val id: PlaylistId,
    var name: String,
    val createdAt: Instant,
    val updatedAt: Instant,
    val currentUserPermission: PlaylistOwnershipType,
    val userCount: Int,
) {
    val isShared get() = userCount > 1
}

internal fun DbPlaylist.toPlaylist(
    permission: PlaylistOwnershipType,
    userCount: Int,
) = Playlist(id, name, createdAt, updatedAt, permission, userCount)
