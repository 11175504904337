package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.SyncStatusId

public data class DbSyncStatus(
  public val id: SyncStatusId,
  public val syncType: String,
  public val lastSuccessfulFullSync: Instant,
  public val lastSyncedDown: Instant
) {
  public override fun toString(): String = """
  |DbSyncStatus [
  |  id: $id
  |  syncType: $syncType
  |  lastSuccessfulFullSync: $lastSuccessfulFullSync
  |  lastSyncedDown: $lastSyncedDown
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<SyncStatusId, Long>,
    public val lastSuccessfulFullSyncAdapter: ColumnAdapter<Instant, Long>,
    public val lastSyncedDownAdapter: ColumnAdapter<Instant, Long>
  )
}
