package net.gorillagroove.db

import com.juul.indexeddb.Key
import com.squareup.sqldelight.db.SqlDriver
import com.squareup.sqldelight.drivers.sqljs.*
import com.squareup.sqldelight.drivers.sqljs.Database as JsDatabase
import kotlinx.coroutines.await
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.IndexedDb
import net.gorillagroove.util.IndexedDbByteData
import net.gorillagroove.util.jsObject
import org.khronos.webgl.Int8Array
import org.khronos.webgl.Uint8Array

internal actual object PlatformDatabase {
    private var jsDb: JsDatabase? = null

    const val INDEXED_DB_KEY = "groove.sqlite"
    private const val INDEXED_DB_ITEM_ID = 1

    actual suspend fun createDriver(): SqlDriver {
        val existingData = if (!shouldSave()) null else retrieveDatabase()?.asUint8Array()

        // This CDN works, and fixes the "double request" bug where this thing tries to load the WASM
        // file twice in a row when it's not pointed at a fully qualified URL for some reason. I don't get it.
        // But I don't know how to implement a fallback to the local version since the "locateFile" function
        // just takes a function that returns a URL. I don't know how to return a different URL if the first one
        // fails. So I am self-hosting the file, but have now added a Cache-Control header so that the
        // "double request" bug no longer really matters, as it's just hitting the cache.
//        val config = js("""{
//            locateFile: function(file) { return "https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.6.2/sql-wasm.wasm" }
//        }""").unsafeCast<Config>()

        val config = js("""{
            locateFile: function(file) { return "/sql-wasm-1.6.2.wasm" }
        }""").unsafeCast<Config>()

        val db = initSqlJs(config).then { sqlJs ->
            if (existingData != null) {
                sqlJs.Database(existingData)
            } else {
                sqlJs.Database()
            }
        }.await()

        val driver = JsSqlDriver(db)
        if (existingData == null) {
            GGDatabase.Schema.create(driver)
        }

        jsDb = db
        return driver
    }

    actual fun getDbAsByteArray(): ByteArray {
        val db = jsDb ?: run {
            logError("Tried to get DB as byte array but it was bad")
            throw IllegalStateException("Database does not exist! Cannot read as byte array")
        }

        // https://youtrack.jetbrains.com/issue/KT-30098
        return Int8Array(db.export().buffer).asByteArray()
    }

    actual suspend fun close(driver: SqlDriver, forceSave: Boolean) {
        if (forceSave) {
            forceSave()
        }
        driver.close()
    }

    actual suspend fun delete() {
        if (!shouldSave()) {
            return
        }

        val database = IndexedDb.getDatabase()

        database.writeTransaction(INDEXED_DB_KEY) {
            val store = objectStore(INDEXED_DB_KEY)
            store.clear()
        }
    }

    actual suspend fun forceSave() {
        if (!shouldSave()) {
            return
        }

        val database = IndexedDb.getDatabase()
        val data = getDbAsByteArray()

        database.writeTransaction(INDEXED_DB_KEY) {
            val store = objectStore(INDEXED_DB_KEY)

            val jsData = jsObject<IndexedDbByteData> {
                this.id = INDEXED_DB_ITEM_ID
                byteData = data.asInt8Array()
            }

            store.put(jsData)
        }
    }

    private fun shouldSave(): Boolean {
        return !GGCommonInternal.isIntegrationTesting || GGCommonInternal.integrationTestDatabaseSave
    }

    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    private suspend fun retrieveDatabase(): ByteArray? {
        val database = IndexedDb.getDatabase()
        return database.transaction(INDEXED_DB_KEY) {
            val store = objectStore(INDEXED_DB_KEY)

            store.get(Key(INDEXED_DB_ITEM_ID)) as? IndexedDbByteData
        }?.byteData?.asByteArray()
    }
}

fun Int8Array.asByteArray() = this.unsafeCast<ByteArray>()
fun ByteArray.asInt8Array() = this.unsafeCast<Int8Array>()
fun ByteArray.asUint8Array() = this.unsafeCast<Uint8Array>()
