package net.gorillagroove.db

import kotlin.Long
import kotlin.String

public data class GetCachedTrackSizeBytes(
  public val bytes: Long?
) {
  public override fun toString(): String = """
  |GetCachedTrackSizeBytes [
  |  bytes: $bytes
  |]
  """.trimMargin()
}
