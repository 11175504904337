@file:Suppress("UNCHECKED_CAST")

import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.html.CommonAttributeGroupFacade
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.Document
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventTarget
import org.w3c.dom.events.MouseEvent
import kotlin.time.Duration

fun EventTarget.addDebounceListener(eventType: String, timeoutMs: Long, handler: (Event) -> Unit) {
    var job: Job? = null

    val callback: (Event) -> Unit = { event ->
        job?.cancel()
        job = CoroutineScope(Dispatchers.Default).launch {
            delay(timeoutMs)
            handler(event)
        }
    }

    this.addEventListener(eventType, callback)
}

// For some reason the IDE doesn't like using the "addEventListener" function, so I wrap it so that it can be found better.
fun EventTarget.addEventListener(eventType: String, handler: (Event) -> Unit) {
    this.addEventListener(eventType, handler)
}

fun setInterval(duration: Duration, handler: () -> Unit) {
    window.setInterval(handler = handler, timeout = duration.inWholeMilliseconds.toInt())
}

fun Element.hide() {
    if (!classList.contains("d-none")) {
        this.classList.add("d-none")
    }
}
fun Element.show() {
    this.classList.remove("d-none")
}
fun Element.isHidden(): Boolean {
    return this.classList.contains("d-none")
}
fun Element.toggleHidden() {
    if (isHidden()) {
        show()
    } else {
        hide()
    }
}
fun Element.setHidden(hidden: Boolean) {
    if (hidden) this.hide() else this.show()
}

fun Element.invisible() {
    this.classList.add("invisible")
}
fun Element.visible() {
    this.classList.remove("invisible")
}
fun Element.setVisible(isVisible: Boolean) {
    if (isVisible) {
        visible()
    } else {
        invisible()
    }
}

fun MouseEvent.isRightClick(): Boolean = this.button.toInt() == 2

fun HTMLElement.removeChildren() {
    while (this.hasChildNodes()) {
        this.removeChild(this.lastChild!!)
    }
}

var CommonAttributeGroupFacade.onClickSuspend: suspend (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onClick")
    set(newValue) {
        onClickFunction = { event ->
            mainScope.launch {
                newValue(event)
            }
        }
    }

var CommonAttributeGroupFacade.onChangeSuspend: suspend (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onClick")
    set(newValue) {
        onChangeFunction = { event ->
            mainScope.launch {
                newValue(event)
            }
        }
    }

var CommonAttributeGroupFacade.onInputSuspend: suspend (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onClick")
    set(newValue) {
        onInputFunction = { event ->
            mainScope.launch {
                newValue(event)
            }
        }
    }

var CommonAttributeGroupFacade.onSubmitSuspend: suspend (Event) -> Unit
    get()  = throw UnsupportedOperationException("You can't read variable onClick")
    set(newValue) {
        onSubmitFunction = { event ->
            event.preventDefault()
            mainScope.launch {
                newValue(event)
            }
        }
    }

inline fun<T : Element> Element.query(queryString: String): T {
    val el = this.querySelector(queryString) ?: throw NullPointerException("No element found with query selector '$queryString'!")
    return el as T
}
inline fun<T : Element> Element.queryAll(queryString: String): List<T> {
    val els = this.querySelectorAll(queryString)
    return els.asList().map { it as T }
}
inline fun<T : Element> Document.query(query: String): T {
    val el = this.querySelector(query) ?: throw NullPointerException("No element found with query selector '$query'!")
    return el as T
}
inline fun<T : Element> Document.queryId(id: String): T {
    val el = this.getElementById(id) ?: throw NullPointerException("No element found by ID: '$id'!")
    return el as T
}

fun getVolumeIconClasses(volume: Double, muted: Boolean): String {
    val fontIcon = if (muted) {
        "fa-volume-xmark"
    } else if (volume > 0.5) {
        "fa-volume-high"
    } else if (volume > 0) {
        "fa-volume-low"
    } else {
        "fa-volume-off"
    }

    return "fa-solid $fontIcon"
}

