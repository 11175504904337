package components

import net.gorillagroove.track.TrackSortable
import net.gorillagroove.util.Formatter
import org.w3c.dom.HTMLElement

object TrackCounter {
    fun update(element: HTMLElement, tracks: List<TrackSortable>, selectedIndexes: Set<Int> = emptySet()) {
        val totalTime = Formatter.getDurationDisplayFromSeconds(tracks.sumOf { it.asTrack().length })

        val selectedTracks = selectedIndexes.map { tracks[it] }
        val selectedTime = Formatter.getDurationDisplayFromSeconds(selectedTracks.sumOf { it.asTrack().length })

        val selectedText = if (selectedTracks.isEmpty()) "" else "(${selectedTracks.size } selected, $selectedTime)"

        element.innerText = "${tracks.size} ${tracks.toWord()}, $totalTime $selectedText".trim()
    }

    private fun List<TrackSortable>.toWord() = if (this.size == 1) "track" else "tracks"
}
