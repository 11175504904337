package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.PlaylistId

public data class DbPlaylist(
  public val id: PlaylistId,
  public val name: String,
  public val createdAt: Instant,
  public val updatedAt: Instant
) {
  public override fun toString(): String = """
  |DbPlaylist [
  |  id: $id
  |  name: $name
  |  createdAt: $createdAt
  |  updatedAt: $updatedAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<PlaylistId, Long>,
    public val createdAtAdapter: ColumnAdapter<Instant, Long>,
    public val updatedAtAdapter: ColumnAdapter<Instant, Long>
  )
}
