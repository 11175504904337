package net.gorillagroove.sync

import kotlinx.serialization.Serializable


internal interface Pageable {
    val pageable: EntityPagination
}

@Serializable
data class EntityChangeResponse<T>(
    val content: EntityChangeContent<T>,
    override val pageable: EntityPagination,
) : Pageable {
    @Serializable
    data class EntityChangeContent<T>(
        val new: List<T>,
        val modified: List<T>,
        val removed: List<Long>,
    ) {
        val newAndModified get() = new + modified
    }
}

@Serializable
data class EntityFetchResponse<T>(
    val content: List<T>,
    override val pageable: EntityPagination,
) : Pageable

@Serializable
data class EntityPagination(
    val offset: Int,
    val pageSize: Int,
    val pageNumber: Int,
    val totalPages: Int,
    val totalElements: Int,
)
