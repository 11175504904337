@file:Suppress("FunctionName")

package components

import PageRouter
import getVolumeIconClasses
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.dom.removeClass
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.*
import kotlinx.html.li
import mainScope
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.discovery.ImportService
import net.gorillagroove.track.NowPlayingService
import net.gorillagroove.track.PublicTrackInfo
import net.gorillagroove.track.TrackService
import net.gorillagroove.user.UserService
import net.gorillagroove.util.Formatter
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import query
import queryAll
import queryId
import show
import toggleHidden

fun TrackLinkPage() = document.create.div {
    id = "track-link-page"

    val idParam = PageRouter.currentPathVariables.getValue("trackId").toLongOrNull() ?: run {
        + "Invalid track link"
        return@div
    }

    val embeddedMode = PageRouter.getQueryParam("mode") == "fancy"

    val trackId = TrackApiId(idParam)
    // I don't know why this wouldn't exist anymore, but it's not technically needed if signed in.
    val accessToken = PageRouter.getQueryParam("anonymousAccessToken") ?: ""

    audio {
        id = "track-link-audio"
    }

    div("space-around") {
        id = "player-wrapper"
    }

    ActionButton("add-to-library-button", "Add to Library", classes = "d-none flat mt-12") {
        actionButtonChangeState("add-to-library-button", isUpdating = true)

        try {
            ImportService.importUserTracks(listOf(trackId))
        } catch (e: Exception) {
            actionButtonChangeState("add-to-library-button", isUpdating = false)

            Toast.error("Failed to import track")
            GGLog.logError("Failed to import track!", e)

            return@ActionButton
        }

        Toast.success("Track imported")
        document.queryId<HTMLElement>("add-to-library-button").hide()
    }

    mainScope.launch {
        val data = try {
            TrackService.getTrackPreview(trackId, accessToken)
        } catch (e: Exception) {
            Toast.error("Failed to get track info!")
            return@launch
        }

        val audioEl = document.queryId<HTMLAudioElement>("track-link-audio")
        audioEl.src = data.trackLink
        audioEl.volume = if (AuthService.isAuthenticated()) NowPlayingService.volume else 1.0

        if (AuthService.isAuthenticated() && data.ownerId != UserService.getCurrentUserId()) {
            document.queryId<HTMLButtonElement>("add-to-library-button").show()
        }

        val player: HTMLElement

        if (embeddedMode) {
            player = EmbeddedPlayer(
                onPlay = { audioEl.play() },
                onPause = { audioEl.pause() },
                onSeek = { audioEl.currentTime = it * data.length },
                onVolumeChange = { audioEl.volume = it },
                data = data,
            )
        } else {
            player = MiniPlayer(
                onPlay = { audioEl.play() },
                onPause = { audioEl.pause() },
                onSeek = { audioEl.currentTime = it * data.length },
                onVolumeChange = { audioEl.volume = it },
            )
            // TODO this should make the button go back to "play" probably
//        onEndedFunction = {  }

            val list = player.query<HTMLUListElement>(".track-information ul")
            list.append {
                listOf(data.name, data.artist, data.album, data.releaseYear?.toString()).forEach { entry ->
                    if (!entry.isNullOrBlank()) {
                        li { +entry }
                    }
                }
            }

            val albumArt = player.query<HTMLDivElement>(".album-art")
            data.albumArtLink?.let { albumArt.style.backgroundImage = """url("$it")""" }

            val timeIndicator = player.query<HTMLElement>(".time-indicators")
            timeIndicator.innerText = "0:00 / ${Formatter.getDurationDisplayFromSeconds(data.length)}"
        }

        document.queryId<HTMLElement>("player-wrapper").append(player)
        audioEl.ontimeupdate = { handleTimeUpdate(it, data, player) }
    }
}

fun MiniPlayer(
    onPlay: () -> Unit,
    onPause: () -> Unit,
    onSeek: (newPercent: Double) -> Unit,
    onVolumeChange: (newPercent: Double) -> Unit,
) = document.create.div("mini-player mt-12") {
    div("space-between") {
        div("album-art") {

        }
        div("track-information") {
            ul { }
        }
    }

    div("space-between bottom-section") {
        div("playback-controls") {
            button(classes = "icon") {
                id = "play-pause-button"

                onClickFunction = { event ->
                    val button = event.currentTarget as HTMLElement

                    val visibleIcon = button.querySelector("i:not(.d-none)") as HTMLElement
                    val hiddenIcon = button.querySelector("i.d-none") as HTMLElement

                    val isPlaying = visibleIcon.classList.contains("fa-pause")

                    if (isPlaying) {
                        onPause()
                    } else {
                        onPlay()
                    }

                    visibleIcon.toggleHidden()
                    hiddenIcon.toggleHidden()
                }

                i("fa-solid fa-play")
                i("fa-solid fa-pause d-none")
            }
        }

        div("slider-section") {
            div("time-slider-wrapper") {
                div("time-indicators")
                input(InputType.range, classes = "time-slider") {
                    min = "0"
                    max = "1"
                    step = "0.01"
                    value = "0"

                    onInputFunction = { event ->
                        onSeek((event.currentTarget as HTMLInputElement).value.toDouble())
                    }
                }
            }

            div("bottom-controls") {
                i("fa-solid fa-volume-high")
                input(InputType.range, classes = "volume-slider") {
                    min = "0"
                    max = "1"
                    step = "0.01"
                    value = "1"

                    onInputFunction = { event ->
                        onVolumeChange((event.currentTarget as HTMLInputElement).value.toDouble())
                    }
                }
            }
        }
    }
}

private fun EmbeddedPlayer(
    onPlay: () -> Unit,
    onPause: () -> Unit,
    onSeek: (newPercent: Double) -> Unit,
    onVolumeChange: (newPercent: Double) -> Unit,
    data: PublicTrackInfo,
) = document.create.div {
    val stickyOverlayClass = if (data.albumArtLink?.isBlank() == true) "sticky-overlay" else ""

    var isPlaying = false
    val play by lazy { document.querySelector(".embedded-player .fa-play")!! }
    val pause by lazy { document.querySelector(".embedded-player .fa-pause")!! }
    val volumeIcon by lazy { document.querySelector(".embedded-player .volume-icon")!! }

    fun renderPlayPause() {
        if (isPlaying) {
            play.hide()
            pause.show()
        } else {
            play.show()
            pause.hide()
        }
    }

    fun renderVolumeIcon(volume: Double) {
        volumeIcon.className = "volume-icon ${getVolumeIconClasses(volume, false)}"
    }

    div("embedded-player") {
        div("main-player-container") {
            div("album-art") {
                style = "background-image:url(${data.albumArtLink})"

                progress("time-slider mini-slider slider") {
                    value = "0.0"
                    max = "1"
                }

                div("player-overlay text-left $stickyOverlayClass") {
                    div("track-name") { +data.name }
                    div("track-artist") { +data.artist }

                    div("playback-controls") {
                        button(classes = "icon") {
                            i("fas fa-play")
                            i("fas fa-pause d-none")

                            onClickFunction = {
                                isPlaying = !isPlaying
                                renderPlayPause()

                                if (isPlaying) {
                                    onPlay()
                                } else {
                                    onPause()
                                }
                            }
                        }
                    }

                    div("volume-controls") {
                        i("volume-icon " + getVolumeIconClasses(1.0, false))

                        div("volume-slider-container") {
                            onMouseDownFunction = { event ->
                                event as MouseEvent
                                val percentage = event.offsetX / (event.currentTarget as HTMLElement).offsetWidth
                                onVolumeChange(percentage)

                                val slider = document.querySelector(".embedded-player .volume-slider") as HTMLProgressElement
                                slider.value = percentage

                                renderVolumeIcon(percentage)
                            }

                            progress("volume-slider slider") {
                                max = "1"
                            }
                        }
                    }

                    div("time-indicators") {
                        +("0:00 / ${Formatter.getDurationDisplayFromSeconds(data.length)}")
                    }

                    progress("time-slider slider") {
                        value = "0"
                        max = "1"
                        onMouseUpFunction = { event ->
                            event as MouseEvent
                            val percentage = event.offsetX / (event.currentTarget as HTMLElement).offsetWidth
                            onSeek(percentage)
                        }
                    }
                }
            }
        }
    }
}

private fun handleTimeUpdate(event: Event, data: PublicTrackInfo, player: HTMLElement) {
    val currentTime = (event.currentTarget as HTMLAudioElement).currentTime

    // This code is hacky and because I ported a bunch of code from the old frontend, the fancy player uses progresses and the
    // normal biggie player uses inputs. But I want to update all of them, if they exist, in the same function. So here we are.
    val inputBars = player.queryAll<HTMLInputElement>("input.time-slider")
    val progressBars = player.queryAll<HTMLInputElement>("progress.time-slider")

    val timeIndicator = player.query<HTMLElement>(".time-indicators")

    val percentDone = currentTime / data.length
    inputBars.forEach { it.value = percentDone.toString() }
    progressBars.forEach { it.value = percentDone.toString() }

    val timeDisplayText = "${Formatter.getDurationDisplayFromSeconds(currentTime.toInt())} / ${Formatter.getDurationDisplayFromSeconds(data.length)}"
    timeIndicator.textContent = timeDisplayText
}
