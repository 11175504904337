@file:Suppress("FunctionName")

package components

import Dialog
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.*
import onSubmitSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import query

private var nextActionButtonId: Int = 0

fun GenericDialog(
    message: String,
    acceptText: String,
    rejectText: String,
    destructive: Boolean = false,
    onAccept: () -> Unit = {},
    onReject: (() -> Unit)? = null,
) = document.create.div("generic-dialog") {
    div("dialog-message") {
        + message
    }

    div("space-between") {
        button(classes = "flat") {
            + rejectText

            onClickFunction = {
                onReject?.invoke()
                Dialog.remove()
            }
        }

        button(classes = if (destructive) "danger" else "primary") {
            + acceptText

            onClickFunction = {
                onAccept()
                Dialog.remove()
            }
        }
    }
}

fun GenericActionDialog(
    message: String?,
    acceptText: String?,
    rejectText: String?,
    destructive: Boolean = false,
    onAccept: suspend () -> Unit,
    onReject: (() -> Unit)? = null,
) = document.create.div("generic-dialog") {
    if (message != null) {
        div("dialog-message pre-line") {
            +message
        }
    }

    val buttonSpacing = if (acceptText == null || rejectText == null) "space-around" else "space-between"
    div(buttonSpacing) {
        if (rejectText != null) {
            button(classes = "flat") {
                +rejectText

                onClickFunction = {
                    onReject?.invoke()
                    Dialog.remove()
                }
            }
        }

        if (acceptText != null) {
            val actionId = "generic-action-button-${++nextActionButtonId}"
            val classes = if (destructive) "danger" else "primary"

            ActionButton(id = actionId, text = acceptText, classes = classes) {
                actionButtonChangeState(actionId, isUpdating = true)

                try {
                    onAccept()
                } catch (e: Exception) {
                    actionButtonChangeState(actionId, isUpdating = false)
                    return@ActionButton
                }
                Dialog.remove()
            }
        }
    }
}

fun InputDialog(
    message: String,
    acceptText: String,
    onAccept: (String) -> Unit,
) = document.create.div("input-dialog") {
    h4("dialog-message") {
        + message
    }

    var inputValue = ""

    form {
        onSubmitFunction = { event ->
            event.preventDefault()

            onAccept(inputValue)
            Dialog.remove()
        }

        div {
            input {
                onInputFunction = {
                    val input = it.currentTarget as HTMLInputElement
                    inputValue = input.value
                }
            }
        }

        div("mt-12") {
            button(type = ButtonType.submit, classes = "flat") {
                +acceptText
            }
        }
    }
}

fun InputActionDialog(
    message: String,
    defaultValue: String = "",
    acceptText: String = "Save",
    buttonClasses: String = "primary",
    onAccept: suspend (value: String) -> Unit,
) = document.create.div("input-dialog") {
    h4("dialog-message") {
        + message
    }

    val actionId = "generic-action-button-${++nextActionButtonId}"

    form {
        onSubmitSuspend = submit@{ event ->
            val input = (event.currentTarget as HTMLElement).query<HTMLInputElement>("input")

            actionButtonChangeState(actionId, isUpdating = true)

            try {
                onAccept(input.value)
            } catch (e: Exception) {
                actionButtonChangeState(actionId, isUpdating = false)
                return@submit
            }
            Dialog.remove()
        }

        div {
            input {
                value = defaultValue
            }
        }

        div("mt-12") {
            ActionButton(id = actionId, text = acceptText, classes = buttonClasses)
        }
    }
}

fun SelectDialog(
    message: String,
    acceptText: String,
    options: List<Pair<String, String>>,
    defaultValue: String,
    label: String,
    onAccept: (String) -> Unit,
) = document.create.div("select-dialog") {
    h4("dialog-message") {
        + message
    }

    var inputValue = options.first { it.second == defaultValue }.second

    form {
        onSubmitFunction = { event ->
            event.preventDefault()

            onAccept(inputValue)
            Dialog.remove()
        }

        div {
            label {
                span("mr-12") { + label }

                select {
                    options.forEach { (displayName, value) ->
                        option {
                            +displayName

                            this.value = value

                            selected = value == defaultValue
                        }
                    }

                    onChangeFunction = { event ->
                        val select = event.currentTarget as HTMLSelectElement
                        inputValue = select.value
                    }
                }
            }
        }

        div("mt-12") {
            button(type = ButtonType.submit, classes = "primary") {
                +acceptText
            }
        }
    }
}
