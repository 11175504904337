import kotlinx.browser.document
import kotlinx.browser.window
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.Theme
import net.gorillagroove.util.ThemeMode
import org.w3c.dom.HTMLElement

enum class AppColor(
    val cssName: String,
    val light: String,
    val dark: String = light,
    val classic: String = light,
) {
    PRIMARY("--primary", "#1D87BA"),
    PRIMARY_LIGHT("--primary-light", "#60B7ED", "#60B7ED"),
    PRIMARY_DARK("--primary-dark", "#1D87BA", "#333333"),
    BACKGROUND("--background", "#FFFFFF", PRIMARY_DARK.dark, "#C8EDFF"),
    FOREGROUND("--foreground", "#333333", "#FFFFFF"),
    SECONDARY("--secondary", "#91F9E2"),
    COLOR_ACCENT("--color-accent", "#91F9E2", classic = "#1D87BA"),
    REALLY_DARK_BLUE("--really-dark-blue", "#1D87BA", "#005A8A", "#99D5F1"),
    GREY4("--grey4", "#BDBDBD"),
    GREY5("--grey5", "#E0E0E0"),

    TABLE_HEADER_BORDER("--table-header-border", FOREGROUND.light, GREY4.dark),
    TABLE_BACKGROUND("--table-background", "#FFFFFF", "#333333", "#C8EDFF"),
    TABLE_BACKGROUND_ALT("--table-background-alt", "#F2F2F2", "#4F4F4F", REALLY_DARK_BLUE.classic),
    TABLE_SELECTED_ROW_BACKGROUND("--table-selected-background", REALLY_DARK_BLUE.light, REALLY_DARK_BLUE.dark, COLOR_ACCENT.classic),
    NAV_TEXT("--nav-text", "#FFFFFF", "#FFFFFF", FOREGROUND.classic),
    NAV_TOP("--nav-top", REALLY_DARK_BLUE.dark, REALLY_DARK_BLUE.light, "#C8EDFF"),
    NAV_BOTTOM("--nav-bottom", REALLY_DARK_BLUE.light, REALLY_DARK_BLUE.dark, REALLY_DARK_BLUE.classic),
    PROGRESS_BAR("--progress-bar", "#BDBDBD", "#828282"),
    STANDARD_BUTTON("--standard-button", TABLE_BACKGROUND_ALT.light, TABLE_BACKGROUND_ALT.dark),
    STANDARD_BUTTON_HOVER("--standard-button-hover", GREY5.light, "#5D5D5D"),
    POWERFUL_BUTTON("--powerful-button", "#1D87BA", "#1D87BA"),
    POWERFUL_BUTTON_HOVER("--powerful-button-hover", "#046EA1", "#046EA1"),
    INPUT_GROUP_BORDER("--input-group-border", "#BDBDBD", BACKGROUND.dark),
    ACTIVE_CONTROL("--active-control", COLOR_ACCENT.light, COLOR_ACCENT.dark),
    ACTIVE_CONTROL_HOVER("--active-control-hover", "#34FAE8", "#34FAE8"),
    ACTIVE_TRACK_CONTROL("--active-track-control", FOREGROUND.light, FOREGROUND.dark),
    INACTIVE_TRACK_CONTROL("--inactive-track-control", "#3AA0CE", PRIMARY_LIGHT.dark),
    DANGER_RED("--danger-red", "#F44336"),
    DANGER_RED_HOVER("--danger-red-hover", "#E21B0C"),
    WARNING_YELLOW("--warning-yellow", "#FFC107"),
    SUCCESS_GREEN("--success-green", "#5BB47E"),
    SUCCESS_GREEN_HOVER("--success-green-hover", "#449564"),
    REJECT_COLOR("--reject-color", "#CC6666"),
    DEBUG_GREY("--debug-grey", "#666666", "#AAAAAA")
    ;

    companion object {
        init {
            window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change") {
                if (Theme.current == ThemeMode.SYSTEM) {
                    logInfo("System dark theme changed. Reassigning colors")
                    assignColors()
                } else {
                    logInfo("System dark theme changed, but we are not following system colors. Not updating theme.")
                }
            }
        }

        fun assignColors() {
            val themeToUse = if (Theme.current == ThemeMode.SYSTEM) {
                if (systemInDarkMode()) ThemeMode.DARK else ThemeMode.LIGHT
            } else {
                Theme.current
            }

            AppColor.entries.map { color ->
                val value = when (themeToUse) {
                    ThemeMode.LIGHT -> color.light
                    ThemeMode.DARK -> color.dark
                    ThemeMode.SYSTEM -> throw IllegalStateException("System is not a valid color")
                    ThemeMode.CLASSIC -> color.classic
                    ThemeMode.CUSTOM -> color.light
                }
                (document.documentElement as HTMLElement).style.setProperty(color.cssName, value)
            }
        }

        private fun systemInDarkMode(): Boolean {
            return window.matchMedia("(prefers-color-scheme:dark)").matches
        }
    }
}
