package components

import Dialog
import PageRouter
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.track.NowPlayingService
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement

private val leanRange get() = document.getElementById("lean-range") as HTMLInputElement
private val minPlaysInput get() = document.getElementById("min-plays-input") as HTMLInputElement
private val maxPlaysInput get() = document.getElementById("max-plays-input") as HTMLInputElement
private val descriptionText get() = document.getElementById("lean-description-text") as HTMLElement

@Suppress("FunctionName")
fun ShuffleLeanEdit() = document.create.div {
    id = "shuffle-lean-edit"

    val leanState = ShuffleLean()

    h1 {
        + "Shuffle Settings"
    }

    div {
        div {
            + "Play Count Lean"
        }
        div("label-container") {
            span("small-label") { + "Less" }
            span("small-label") { + "More" }
        }
        input(InputType.range) {
            id = "lean-range"

            min = "-1"
            max = "1"
            step = "0.01"
            value = leanState.lean.toString()
            onChangeFunction = { event ->
                val input = event.currentTarget!! as HTMLInputElement

                leanState.lean = input.value.toDouble()
                leanState.updateText()
            }
        }
    }

    div {
        id = "shuffle-section-two"

        div {
            + "Play Count Restriction"
        }
        div("label-container") {
            span("small-label") { + "Minimum "}
            span("small-label") { + "Maximum "}
        }

        div("space-between") {
            div {
                input(InputType.number) {
                    id = "min-plays-input"

                    min = "0"
                    value = leanState.minimumPlays.toString()

                    onChangeFunction = {
                        val value = minPlaysInput.value.toInt()

                        leanState.minimumPlays = value
                        if (leanState.maximumPlays < leanState.minimumPlays) {
                            leanState.maximumPlays = leanState.minimumPlays
                            maxPlaysInput.value = value.toString()
                        }

                        leanState.updateText()
                    }
                }
            }
            div {
                input(InputType.number) {
                    id = "max-plays-input"

                    min = "0"
                    value = leanState.getUserMaxPlays()

                    onChangeFunction = {
                        val value = maxPlaysInput.value.toInt()

                        leanState.maximumPlays = value
                        if (leanState.maximumPlays < leanState.minimumPlays) {
                            leanState.minimumPlays = leanState.maximumPlays
                            minPlaysInput.value = value.toString()
                        }

                        leanState.updateText()
                    }
                }
            }
        }
    }

    div {
        id = "lean-description-text"
    }

    div("space-between") {
        button(classes = "flat") {
            + "Cancel"

            onClickFunction = {
                Dialog.remove()
            }
        }

        button(classes = "flat") {
            + "Reset"

            onClickFunction = {
                leanState.reset()
            }
        }

        button(classes = "primary") {
            + "Save"

            onClickFunction = {
                leanState.save()
                Dialog.remove()

                NowPlayingService.reshuffle()
                if (PageRouter.currentViewMode == ViewMode.NOW_PLAYING) {
                    TrackTable.fullRender()
                }

                Footer.renderShuffleGear()
            }
        }
    }

    mainScope.launch {
        leanState.updateText()
    }
}

class ShuffleLean {
    var lean: Double = NowPlayingService.shuffleLean
    var minimumPlays: Int = NowPlayingService.shuffleMinimumPlayCount
    var maximumPlays: Int = NowPlayingService.shuffleMaximumPlayCount

    fun save() {
        NowPlayingService.shuffleLean = lean
        NowPlayingService.shuffleMinimumPlayCount = minimumPlays
        NowPlayingService.shuffleMaximumPlayCount = maximumPlays
    }

    fun reset() {
        lean = 0.0
        minimumPlays = 0
        maximumPlays = NowPlayingService.MAXIMUM_ALLOWED_SHUFFLE_PLAY_COUNT_LIMIT

        minPlaysInput.value = "0"
        maxPlaysInput.value = ""
        leanRange.value = "0"

        updateText()
    }

    fun getUserMaxPlays(): String {
        return if (maximumPlays == NowPlayingService.MAXIMUM_ALLOWED_SHUFFLE_PLAY_COUNT_LIMIT) {
            ""
        } else {
            maximumPlays.toString()
        }
    }

    fun updateText() {
        descriptionText.innerText = NowPlayingService.getShuffleDescription(lean, minimumPlays, maximumPlays)
    }
}
