package net.gorillagroove.db

import kotlin.String
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.api.TrackId

public data class FindApiIdForLocalId(
  public val id: TrackId,
  public val apiId: TrackApiId?
) {
  public override fun toString(): String = """
  |FindApiIdForLocalId [
  |  id: $id
  |  apiId: $apiId
  |]
  """.trimMargin()
}
