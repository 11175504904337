package net.gorillagroove.util

import net.gorillagroove.util.GGLog.logDebug

internal actual object PlatformLogger {
    actual fun logLine(level: LogLevel, tag: String, line: String) {
        // For whatever reason, at least in Karma's tests via IntelliJ, there are no newlines using
        // println unless we add an extra \n. Probably this will be fixed at some point, and we can remove
        // this check, as it seems like a bug somewhere.
        val logLine = line + "\n"

        when (level) {
            LogLevel.DEBUG -> console.debug(logLine)
            LogLevel.INFO -> console.info(logLine)
            LogLevel.WARNING -> console.warn(logLine)
            LogLevel.ERROR, LogLevel.CRITICAL -> console.error(logLine)
        }
    }
}

external val console: Console

// The external interface defined in KotlinJS does not define "debug" as a function.
// I don't know how it got missed, but it did. So I have to define the interface my damn self.
// As far as I know, you can't just add another external function to someone else's external interface,
// so I need to declare the entire thing?
external interface Console {
    fun error(vararg o: Any?)
    fun info(vararg o: Any?)
    fun log(vararg o: Any?)
    fun warn(vararg o: Any?)
    fun debug(vararg o: Any?)
}
