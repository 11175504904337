package net.gorillagroove.util

import kotlinx.browser.window
import kotlinx.datetime.TimeZone
import kotlin.random.Random
import kotlin.random.nextInt

actual object PlatformUtil {
    actual fun generateUuid(): String {
        var d2 = window.performance.now().toInt()

        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace("[xy]".toRegex()) {
            val r = Random.nextInt(0 until 16)
            val r2 = (d2 + r) % 16
            d2 /= 16

            if (it.value == "x") {
                r2
            } else {
                (r2 and 0x3 or 0x8)
            }.toString(16)
        }
    }

    actual fun getIanaTimezone(): String {
        // "Intl" doesn't seem to have a KotlinJS binding yet.
        return js("""Intl.DateTimeFormat().resolvedOptions().timeZone""") as String
    }

    internal actual fun isNoInternetException(e: Throwable): Boolean {
        return false
    }
}
