package net.gorillagroove.track

import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import net.gorillagroove.api.Api
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.api.TrackId
import net.gorillagroove.api.isBenignException
import net.gorillagroove.db.DbTrack
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.track.TrackLinkType.*
import net.gorillagroove.util.GGLog.logCrit
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logWarn

object TrackCacheService {

    suspend fun getCacheItemIfAvailable(trackId: TrackId, cacheType: TrackLinkType): ByteArray? {
        // If we don't have a DB record for this track, then we know it can't be cached
        if (!UserTrackService.isTrackInDatabase(trackId)) {
            return null
        }

        // Always want to look up the track ourselves to make sure the data we're getting isn't stale
        val track = TrackService.findDbById(trackId) ?: run {
            logError("No track with ID ${trackId.value} found when checking cache availability!")
            return null
        }

        when (cacheType) {
            AUDIO_MP3 -> if (track.audioCachedAt == null) return null
            AUDIO_OGG -> if (track.audioCachedAt == null) return null
            ART_PNG -> if (track.artCachedAt == null) return null
            THUMBNAIL_PNG -> if (track.thumbnailCachedAt == null) return null
        }

        PlatformTrackCacheService.getCachedTrackData(track.id, cacheType)?.let { cachedFile ->
            return cachedFile
        } ?: run {
            logError("Track ${track.id.value} was missing cacheType $cacheType! Marking track $cacheType as not available offline")
            val refreshedTrack = TrackService.findDbById(track.id) ?: run {
                logError("Could not find refreshed track with ID: ${track.id.value}!")
                return null
            }

            refreshedTrack.updateCacheDate(cacheType, null)

            TrackService.broadcastTrackChange(listOf(refreshedTrack.toTrack()), ChangeType.UPDATED)

            return null
        }
    }

    internal suspend fun cacheTrack(trackId: TrackId, serverUrl: String, cacheType: TrackLinkType): Int {
        if (!UserTrackService.isTrackInDatabase(trackId)) {
            return -1
        }

        return try {
            val downloadedFile = Api.download(serverUrl).data

            return saveTrackByteData(trackId, downloadedFile, cacheType)
        } catch (e: Exception) {
            if (e.isBenignException()) {
                logError("Track ${trackId.value} failed to download $cacheType for a benign reason", e)
            } else {
                logCrit("Track ${trackId.value} failed to download $cacheType from URL: $serverUrl", e)
            }
            -1
        }
    }

    internal suspend fun deleteAllCacheOnDisk(trackId: TrackId) {
        entries.forEach { cacheType ->
            deleteCacheOnDisk(trackId, cacheType)
        }
    }

    internal suspend fun deleteCacheOnDisk(trackId: TrackId, cacheType: TrackLinkType) {
        logDebug("Removing cached track data for Track ${trackId.value} and type $cacheType")
        PlatformTrackCacheService.deleteCachedTrackData(trackId, cacheType)
    }

    // Seemingly every consumer is able to turn a ByteArray into usable data.
    // Here is converting Swift "Data" to ByteArray: https://stackoverflow.com/a/72865245
    suspend fun saveTrackByteData(trackId: TrackId, data: ByteArray, cacheType: TrackLinkType): Int {
        if (!UserTrackService.isTrackInDatabase(trackId)) {
            return -1
        }

        if (!OfflineModeService.offlineStorageEnabled && cacheType != THUMBNAIL_PNG) {
            logWarn("Not saving cache data for track: ${trackId.value} and type $cacheType as offline storage is not enabled")
            return -1
        }

        val track = TrackService.findDbById(trackId) ?: run {
            logError("Failed to find track with ID ${trackId.value} while saving $cacheType cache!")
            deleteCacheOnDisk(trackId, cacheType)
            return -1
        }

        if (track.offlineAvailability.asEnumeratedType() == OfflineAvailabilityType.ONLINE_ONLY && cacheType != THUMBNAIL_PNG) {
            logWarn("Not saving cache data for track: ${trackId.value} and type $cacheType as it is online only")
            return -1
        }

        val updatedTrack = track.updateCacheDate(cacheType, now())

        val expectedByteNum = when (cacheType) {
            AUDIO_MP3 -> track.filesizeAudioMp3
            AUDIO_OGG -> track.filesizeAudioOgg
            ART_PNG -> track.filesizeArtPng
            THUMBNAIL_PNG -> track.filesizeThumbnailPng
        }

        if (expectedByteNum != data.size) {
            // TODO what should I actually do here? Do I care? Yes. But how much do I care?
            logError("The number of saved bytes (${data.size} did not match the expected amount ($expectedByteNum)!")
        }

        PlatformTrackCacheService.saveTrackDataToDisk(trackId, data, cacheType)

        TrackService.broadcastTrackChange(listOf(updatedTrack.toTrack()), ChangeType.UPDATED)

        return data.size
    }

    private fun DbTrack.updateCacheDate(cacheType: TrackLinkType, time: Instant?): DbTrack {
        return this.copy(
            audioCachedAt = if (cacheType == AUDIO_OGG || cacheType == AUDIO_MP3) time else audioCachedAt,
            artCachedAt = if (cacheType == ART_PNG) time else artCachedAt,
            thumbnailCachedAt = if (cacheType == THUMBNAIL_PNG) time else thumbnailCachedAt,
        ).also { trackDao.upsert(it) }
    }
}

internal expect object PlatformTrackCacheService {
    // These are 'suspend' functions to appease JS, that has to use callback-based code
    // in order to interact with IndexedDb. JS dragging all the other code down. F.
    suspend fun saveTrackDataToDisk(trackId: TrackId, data: ByteArray, cacheType: TrackLinkType)

    suspend fun getCachedTrackData(trackId: TrackId, cacheType: TrackLinkType): ByteArray?

    suspend fun deleteCachedTrackData(trackId: TrackId, cacheType: TrackLinkType)

    suspend fun deleteAllData()
}
