package components

import kotlinx.browser.document
import kotlinx.html.br
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.id
import kotlinx.html.js.div
import net.gorillagroove.authentication.AuthService
import org.w3c.dom.HTMLDivElement

@Suppress("FunctionName")
fun PrivacyPolicy(): HTMLDivElement {
    return document.create.div {
        id = "privacy-policy"

        + "Ensuring your privacy is important to us. We do not share your personal information with third parties under any circumstance."

        br()
        br()

        + "Your email address is only used for password resets, and your location data can stop being collected or deleted at any time."

        br()
        br()

        + "Thank you for choosing us as your source of Grooving."

        br()
        br()

        + "-- The Groove Team"
    }
}
