package net.gorillagroove.playlist

import kotlinx.datetime.Instant
import net.gorillagroove.api.*
import net.gorillagroove.db.DbPlaylistTrack
import net.gorillagroove.db.DbPlaylistUser

class PlaylistTrack (
    val id: PlaylistTrackId,
    val playlistId: PlaylistId,
    val trackId: TrackId,
    val sortOrder: Int,
    val createdAt: Instant
)

internal fun DbPlaylistTrack.toPlaylistTrack() = PlaylistTrack(id, playlistId, trackId, sortOrder, createdAt)
internal fun List<DbPlaylistTrack>.toPlaylistTracks() = this.map { it.toPlaylistTrack() }
