package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.UserSettingApiId
import net.gorillagroove.api.UserSettingId

public data class DbUserSetting(
  public val id: UserSettingId,
  public val apiId: UserSettingApiId?,
  public val key: String,
  public val value_: String,
  public val synchronized: Boolean,
  public val updatedAt: Instant
) {
  public override fun toString(): String = """
  |DbUserSetting [
  |  id: $id
  |  apiId: $apiId
  |  key: $key
  |  value_: $value_
  |  synchronized: $synchronized
  |  updatedAt: $updatedAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<UserSettingId, Long>,
    public val apiIdAdapter: ColumnAdapter<UserSettingApiId, Long>,
    public val updatedAtAdapter: ColumnAdapter<Instant, Long>
  )
}
