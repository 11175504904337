package net.gorillagroove.sync

import kotlinx.datetime.Instant
import net.gorillagroove.api.Api
import net.gorillagroove.db.DbSyncStatus
import net.gorillagroove.util.GGLog.logError

internal sealed interface SyncStrategy {
    val syncType: SyncableEntity
}

internal interface SyncUpStrategy : SyncStrategy {
    suspend fun syncUp()
}

internal interface SyncDownStrategy : SyncStrategy {
    suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler = { _, _ ->})
}

internal interface BidirectionalStrategy : SyncDownStrategy, SyncUpStrategy

internal const val SYNC_PAGE_SIZE = 400

fun generateFullSyncUrl(
    syncableEntity: SyncableEntity,
    minTime: Instant,
    page: Int,
    pageSize: Int = SYNC_PAGE_SIZE
): String {
    val min = minTime.toEpochMilliseconds()

    return "sync/entity-type/${syncableEntity.apiName}/minimum/$min?page=$page&size=$pageSize"
}

internal suspend inline fun<reified T> fetchSyncEntities(
    syncableEntity: SyncableEntity,
    syncStatus: DbSyncStatus,
    crossinline onPageSyncedHandler: PageSyncHandler = { _, _ -> },
    changeSetHandler: (EntityChangeResponse.EntityChangeContent<T>) -> Unit,
) {
    val min = syncStatus.lastSyncedDown.toEpochMilliseconds()

    val url = "sync/entity-type/${syncableEntity.apiName}/minimum/$min"
    fetchPageable<EntityChangeResponse<T>>(url, pageSize = SYNC_PAGE_SIZE) { response ->
        changeSetHandler(response.content)

        val percentComplete = (response.pageable.pageNumber.toDouble() + 1) / response.pageable.totalPages

        onPageSyncedHandler(syncableEntity, percentComplete)
    }
}

internal suspend inline fun<reified T : Pageable> fetchPageable(
    url: String,
    pageSize: Int,
    queryParams: Map<String, Any?> = emptyMap(),
    onPageFinishHandler: (T) -> Unit,
) {
    var page = 0
    var pagesToGet: Int

    do {
        val params = queryParams + mapOf("page" to page, "size" to pageSize)

        val response: T = try {
            Api.get(url, params)
        } catch (e: Exception) {
            SyncCoordinator.logError("Failed to fetch URL: $url", e)
            throw e
        }

        pagesToGet = response.pageable.totalPages
        page++

        onPageFinishHandler(response)
    } while (page < pagesToGet)
}
