package net.gorillagroove.discovery

import net.gorillagroove.api.Api

object SpotifySearchService {
    suspend fun searchByArtist(artist: String): MetadataSearchResponse {
        require(artist.isNotBlank()) { "Artist must not be blank!" }

        return Api.get("search/spotify/artist/${artist.trim()}")
    }

    suspend fun autocompleteArtistName(partialName: String): AutocompleteResult {
        require(partialName.isNotBlank()) { "Artist must not be blank!" }

        return Api.get("search/autocomplete/spotify/artist-name/${partialName.trim()}")
    }
}
