package net.gorillagroove.util

// I am being probably too forward-thinking with this file.
// But I have tentative plans to allow users to customize the colors
// of GG if they really want to. And for frontend web in particular,
// I plan to let users have "classic" colors for the rewrite if they want.
object Theme {
    var current: ThemeMode
        get() = ThemeMode.valueOf(Settings.getString(SettingType.THEME_MODE, ThemeMode.SYSTEM.name))
        set(value) = Settings.setString(SettingType.THEME_MODE, value.name)
}

enum class ThemeMode(val displayName: String) {
    LIGHT("Light"),
    DARK("Dark"),
    SYSTEM("System"),
    CLASSIC("Classic"),
    CUSTOM("Custom"),
    ;

    companion object {
        fun findByDisplayName(displayName: String): ThemeMode {
            return entries.find { it.displayName == displayName }
                ?: throw IllegalArgumentException("No ThemeMode found for displayName $displayName!")
        }
    }
}
