package net.gorillagroove.playlist

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.serialization.Serializable
import net.gorillagroove.util.GGLog.logError
import kotlin.jvm.JvmInline

enum class PlaylistOwnershipType(
    val permissionLevel: Int,
    val displayName: String,
) {
    OWNER(4, "Owner"),
    WRITER(3, "Write access"),
    READER(2, "Read access"),
    NONE(1, "No access"),
    UNKNOWN(0, "Unknown"),
    ;

    fun toRawType() = RawPlaylistOwnershipType(this.name)

    companion object {
        fun findByDisplayName(displayName: String): PlaylistOwnershipType {
            return entries.find { it.displayName == displayName }
                ?: throw IllegalArgumentException("No PlaylistOwnershipType found for displayName $displayName!")
        }

        fun getSelectableTypes(): List<PlaylistOwnershipType> {
            return entries.toSet()
                .minus(UNKNOWN)
                .sortedByDescending { it.permissionLevel }
        }
    }
}

@Serializable
@JvmInline
value class RawPlaylistOwnershipType(val value: String) {
    fun asEnumeratedType(): PlaylistOwnershipType {
        return try {
            PlaylistOwnershipType.valueOf(value)
        } catch (e: Throwable) {
            this.logError("Unknown enumerated type value '$value' for enum '${PlaylistOwnershipType::class.simpleName}'")
            PlaylistOwnershipType.UNKNOWN
        }
    }
}

object RawPlaylistOwnershipTypeAdapter : ColumnAdapter<RawPlaylistOwnershipType, String> {
    override fun decode(databaseValue: String) = RawPlaylistOwnershipType(databaseValue)
    override fun encode(value: RawPlaylistOwnershipType) = value.value
}
