package net.gorillagroove.util

fun <K, V> Map<K, V>.reversed() = HashMap<V, K>().also { newMap ->
    entries.forEach { newMap[it.value] = it.key }
}

fun Boolean.toLong(): Long = if (this) 1 else 0

// I hate that the Kotlin "indexOf" functions follow the olden principles of returning -1.
// Just turn null like any sane function trying to find something should return.
fun<T> List<T>.findIndex(predicate: (T) -> Boolean): Int? {
    return this.indexOfFirst(predicate).takeIf { it >= 0 }
}
fun String.findIndex(substring: String, startIndex: Int = 0, ignoreCase: Boolean = false): Int? {
    return this.indexOf(substring, startIndex, ignoreCase).takeIf { it >= 0 }
}

fun String.indexOfAll(substring: String): List<Int> {
    val indexes = mutableListOf<Int>()

    var currentIndex = 0
    while (currentIndex + substring.length <= this.length) {
        val subsequence = this.subSequence(currentIndex, currentIndex + substring.length)
        if (subsequence == substring) {
            indexes.add(currentIndex)
        }
        currentIndex++
    }

    return indexes
}

fun String.withoutExtension(): String {
    return this.split('.')
        .dropLast(1)
        .joinToString(".")
}
