import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.i
import kotlinx.html.id
import kotlinx.html.js.div
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.math.roundToLong

object Toast {
    private val toastContainer get() = document.getElementById("toast-container") as HTMLElement

    fun init() {
        document.body!!.append {
            div {
                id = "toast-container"
            }
        }
    }

    private fun createToast(colorClass: String, message: String): HTMLDivElement {
        return document.create.div("$colorClass toast") {
            i("close-icon fa-solid fa-xmark")
            + message
        }
    }

    private fun HTMLDivElement.display(message: String) {
        this.classList.add("entering")

        toastContainer.append(this)

        // Add some additional time for the message based off how long it is.
        // Apparently the average person can read like 4 words per second, and the average
        // word is like 5 characters long... so that's how I came up with this random math.
        val delay = 3000 + (1000 * (message.length / 20.0)).roundToLong()

        mainScope.launch {
            delay(delay)

            this@display.classList.remove("entering")
            this@display.classList.add("leaving")

            delay(3000)

            this@display.remove()
        }
    }

    fun success(message: String) {
        createToast("success", message).display(message)
    }

    fun info(message: String) {
        createToast("info", message).display(message)
    }

    fun error(message: String) {
        createToast("error", message).display(message)
    }
}
