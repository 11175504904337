package net.gorillagroove.discovery

import kotlinx.coroutines.*
import kotlinx.serialization.Serializable
import net.gorillagroove.GGCommonInternal
import net.gorillagroove.api.Api
import net.gorillagroove.api.BackgroundTaskId
import net.gorillagroove.api.BackgroundTaskSocketResponse
import net.gorillagroove.api.EventType
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.sync.SyncableEntity.*
import net.gorillagroove.util.CoroutineUtil.CancelledJob
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.GGLog.logWarn
import net.gorillagroove.util.Lock
import net.gorillagroove.util.use
import kotlin.jvm.JvmInline

typealias BackgroundTaskChangeHandler = (taskItem: BackgroundTaskItem) -> Unit

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object BackgroundTaskService {
    internal var pollingDelay = 20_000L
    private val coroutineScope = CoroutineScope(Dispatchers.Default)

    private val lock: Lock = Lock()

    internal val idToTask = mutableMapOf<BackgroundTaskId, BackgroundTaskItem>()

    val tasks get() = idToTask.values.sortedByDescending { it.status.sortOrder }

    var currentDownload = 0
        private set
    var totalDownloads = 0
        private set

    internal var nextClientSideId = Int.MAX_VALUE

    private var pollingJob: Job? = null

    private var taskChangeHandler: BackgroundTaskChangeHandler? = null

    fun registerBackgroundTaskChangeHandler(taskChangeHandler: BackgroundTaskChangeHandler) {
        BackgroundTaskService.taskChangeHandler = taskChangeHandler
    }

    internal fun addBackgroundTasks(tasks: List<BackgroundTaskItem>): Job {
        logDebug("Adding new background tasks with IDs ${tasks.map { it.id }}")

        lock.use {
            tasks.forEach { task ->
                idToTask[task.id] = task
            }

            totalDownloads += tasks.size

            // If the device is set up to listen for background tasks, then we don't
            // really need to poll. It's just unnecessary.
            if (EventType.getDefaultTypes().contains(EventType.BACKGROUND_TASK)) {
                return CancelledJob()
            }

            val pollingJob = pollingJob ?: run {
                logDebug("Not already polling for tasks. Starting a new poll")
                currentDownload = 0
                return coroutineScope.launch {
                    pollUntilAllProcessed()
                }.also { pollingJob = it }
            }

            logDebug("Already polling for tasks. Not starting a new poll")
            return pollingJob
        }
    }

    internal fun changeClientOnlyBackgroundItem(
        clientTaskId: BackgroundTaskId,
        status: BackgroundTaskStatus? = null,
        serverTask: BackgroundTaskItem? = null,
    ) {
        // This may not exist anymore if the socket already processed the message
        val clientTask = idToTask[clientTaskId] ?: return

        val finalTask = if (serverTask != null) {
            idToTask.remove(clientTaskId)
            taskChangeHandler?.invoke(clientTask.copy(status = BackgroundTaskStatus.REMOVED))

            idToTask[serverTask.id] = serverTask
            serverTask
        } else if (status != null) {
            val updatedTask = clientTask.copy(status = status)
            idToTask[clientTaskId] = updatedTask
            updatedTask
        } else {
            throw IllegalArgumentException("Either 'status' or 'serverTask' must not be null!")
        }

        taskChangeHandler?.invoke(finalTask)
    }

    fun pollIfNeeded(): Job = lock.use {
        return if (idToTask.isNotEmpty() && pollingJob == null) {
            coroutineScope.launch {
                pollUntilAllProcessed()
            }
        } else {
            return pollingJob ?: CancelledJob()
        }
    }

    private suspend fun pollUntilAllProcessed() {
        val ids = idToTask.values
            .filter { item ->
                (item.status == BackgroundTaskStatus.PENDING || item.status == BackgroundTaskStatus.RUNNING) &&
                        !item.clientSideOnly
            }
            .map { it.id.value }

        // It can be empty if everything we are polling for is clientSideOnly
        if (ids.isEmpty()) {
            delay(pollingDelay)
            pollUntilAllProcessed()
            return
        }

        logDebug("Polling for tasks with IDs $ids")

        val tasks: BackgroundTaskResponseInternal = try {
            Api.get("background-task", mapOf("ids" to ids))
        } catch (e: Exception) {
            // This will eventually be restarted by pollIfNeeded() being invoked. Probs not ideal but whatevs.
            logError("Could not get background tasks!", e)
            pollingJob?.cancel()
            pollingJob = null

            return
        }

        val items = tasks.getConvertedItems()

        val changedTasks = items.filter { serverTask ->
            val clientTask = idToTask.getValue(serverTask.id)
            clientTask.status != serverTask.status
        }
        items.forEach { task ->
            if (task.status == BackgroundTaskStatus.FAILED) {
                logError("Failed to download '${task.description}'")
            }
            idToTask[task.id] = task
        }

        var allDone = handleTasksDidChange()

        lock.use {
            allDone = areAllTasksDone()

            logDebug("Polling is ${if (allDone) "done" else "not done"}")

            handleTasksDidChange()
        }

        changedTasks.forEach { taskChangeHandler?.invoke(it) }

        if (!allDone) {
            delay(pollingDelay)
            pollUntilAllProcessed()
        }
    }

    suspend fun refreshTaskState() {
        val tasks = Api.get<BackgroundTaskResponseInternal>("background-task/unfinished").getConvertedItems()
        addBackgroundTasks(tasks)
    }

    internal fun processSocketResponse(decoded: BackgroundTaskSocketResponse) {
        val clientItem = idToTask.values.find { it.description == decoded.task.description }
        if (clientItem != null) {
            idToTask.remove(clientItem.id)
        }

        val task = decoded.task.asItem()

        logInfo("Processing socket response to task: $task")

        idToTask[decoded.task.id] = task

        handleTasksDidChange()

        taskChangeHandler?.invoke(task)
    }

    private fun handleTasksDidChange(): Boolean = lock.use {
        val allDone = areAllTasksDone()
        if (allDone) {
            pollingJob = null
            idToTask.clear()

            if (!GGCommonInternal.isIntegrationTesting) {
                coroutineScope.launch {
                    SyncCoordinator.sync(types = setOf(TRACK, REVIEW_SOURCE))
                }
            }
        }

        currentDownload = idToTask.filter { it.value.status.finished }.size
        totalDownloads = idToTask.size

        return@use allDone
    }

    private fun areAllTasksDone(): Boolean {
        return idToTask.values.all { item -> item.status.finished }
    }

    suspend fun cancelAll() {
        val taskIds = idToTask.values.filter { it.status.cancelable }.map { it.id }

        cancelTasks(taskIds)
    }

    suspend fun cancelTasks(taskIds: Collection<BackgroundTaskId>) {
        logInfo("Cancelling background tasks with IDs: ${taskIds.map { it.value }}")

        val serverTasksToDelete = mutableSetOf<BackgroundTaskId>()

        taskIds.forEach { id ->
            val task = idToTask[id] ?: run {
                logWarn("No task found for ID ${id.value} when cancelling")
                return@forEach
            }

            // I don't remove "UPLOADING" tasks because I don't have a good way to stop them.
            // If I remove them here, then they'll still create a server-side task when they
            // are done with a new ID. And since I don't have that new ID yet, I can't cancel
            // the server one. I'd need to flag it and delete it later or something, and I don't wanna.
            when (task.status) {
                BackgroundTaskStatus.PENDING_UPLOAD -> {
                    val updatedTask = task.copy(status = BackgroundTaskStatus.CANCELLED)
                    idToTask[updatedTask.id] = updatedTask

                    logDebug("Cancelled local background task: ${updatedTask.description}")
                    handleTasksDidChange()
                    taskChangeHandler?.invoke(updatedTask)
                }
                BackgroundTaskStatus.PENDING, BackgroundTaskStatus.RUNNING -> serverTasksToDelete.add(id)
                else -> { }
            }
        }

        if (serverTasksToDelete.isNotEmpty()) {
            Api.delete<Unit>("background-task", mapOf("ids" to serverTasksToDelete.map { it.value }))
            handleTasksDidChange()
        }
    }

    internal fun resetState() {
        idToTask.clear()
        currentDownload = 0
        totalDownloads = 0
        pollingJob?.cancel()
        pollingJob = null
        taskChangeHandler = null
    }
}

data class BackgroundTaskItem(
    val id: BackgroundTaskId,
    val status: BackgroundTaskStatus,
    val type: BackgroundTaskType,
    val description: String,

    // This is used to differentiate between tasks that are on the device vs ones that are on the server.
    // The reason for this is that file uploads can have a significant client-side delay before the
    // server ever gets to know about them. This property is NOT included in the JSON from the API!
    val clientSideOnly: Boolean = false,
)

@Serializable
internal data class BackgroundTaskItemResponse(
    val id: BackgroundTaskId,
    val status: RawBackgroundTaskStatus,
    val type: RawBackgroundTaskType,
    val description: String,
) {
    fun asItem() = BackgroundTaskItem(
        id = id,
        status = status.asEnumeratedType(),
        type = type.asEnumeratedType(),
        description = description,
        clientSideOnly = false
    )
}

enum class BackgroundTaskStatus(
    val displayName: String,
    internal val sortOrder: Int,
    internal val finished: Boolean = false,
    val cancelable: Boolean = false,
) {
    PENDING("Pending", 2, cancelable = true),
    RUNNING("Running", 3, cancelable = true),
    COMPLETE("Complete", 4, finished = true),
    FAILED("Failed", 5, finished = true),
    PENDING_UPLOAD("Awaiting upload", 0, cancelable = true),
    UPLOADING("Uploading", 1),
    CANCELLING("Cancelling", 1, finished = true),
    CANCELLED("Cancelled", 1, finished = true),

    // This is used to signal that a client-side task has been replaced by a server-side one and should be removed from any list.
    REMOVED("Removed", 1, finished = true),
    UNKNOWN("Unknown", 100),
    ;

    fun toRawType() = RawBackgroundTaskStatus(this.name)
}

enum class BackgroundTaskType {
    YT_DOWNLOAD,
    NAMED_IMPORT,
    FILE_UPLOAD,
    UNKNOWN,
    ;

    fun toRawType() = RawBackgroundTaskType(this.name)
}

@Serializable
@JvmInline
value class RawBackgroundTaskStatus(val value: String) {
    fun asEnumeratedType(): BackgroundTaskStatus {
        return try {
            BackgroundTaskStatus.valueOf(value)
        } catch (e: Throwable) {
            this.logError("Unknown enumerated type value '$value' for enum '${BackgroundTaskStatus::class.simpleName}'")
            BackgroundTaskStatus.UNKNOWN
        }
    }
}

@Serializable
@JvmInline
value class RawBackgroundTaskType(val value: String) {
    fun asEnumeratedType(): BackgroundTaskType {
        return try {
            BackgroundTaskType.valueOf(value)
        } catch (e: Throwable) {
            this.logError("Unknown enumerated type value '$value' for enum '${BackgroundTaskType::class.simpleName}'")
            BackgroundTaskType.UNKNOWN
        }
    }
}
