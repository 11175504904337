package net.gorillagroove.reporting

import io.ktor.http.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.datetime.*
import kotlinx.datetime.Clock.System.now
import kotlinx.serialization.encodeToString
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import net.gorillagroove.api.Api
import net.gorillagroove.api.CrashReportId
import net.gorillagroove.api.DownloadResponse
import net.gorillagroove.api.UserId
import net.gorillagroove.authentication.AuthService
import net.gorillagroove.authentication.VersionService
import net.gorillagroove.db.Database
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.RawDeviceType
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.user.User
import net.gorillagroove.user.UserService
import net.gorillagroove.util.*
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.Lock
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.use

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object ProblemReportService {
    internal const val LAST_MANUAL_REPORT_KEY = "last_manual_report"
    internal const val LAST_AUTOMATED_REPORT_KEY = "last_automated_report"

    var automaticErrorReporting
        get() = Settings.getBoolean(SettingType.AUTOMATIC_ERROR_REPORTING, true)
        set(value) = Settings.setBoolean(SettingType.AUTOMATIC_ERROR_REPORTING, value)

    var showCriticalErrors
        get() = Settings.getBoolean(SettingType.SHOW_CRITICAL_ERRORS, false)
        set(value) = Settings.setBoolean(SettingType.SHOW_CRITICAL_ERRORS, value)

    private val lock: Lock = Lock()

    suspend fun sendProblemReport() = withContext(Dispatchers.Default) {
        val binaryData = Database.getDbAsByteArray()

        // If we are authenticated, then the API gets most of the data from the user's auth token.
        // So, if we aren't authenticated, we need to provide some additional data with the request.
        if (AuthService.isAuthenticated()) {
            Api.upload<Unit>("crash-report", "file", "crash-report.db", binaryData)
        } else {
            val jsonData = CrashReportMetadata(
                deviceType = DeviceUtil.getDeviceType(),
                applicationVersion = VersionService.currentDeviceVersion
            )

            Api.partialBinaryUpload(
                url = "crash-report/public",
                method = HttpMethod.Post,
                dataKey = "file",
                data = binaryData,
                binaryPayloadName = "crash-report.db",
                jsonPayloadName = "uploadMetadata",
                jsonPayloadData = Json.encodeToString(jsonData)
            )
        }

        LocalStorage.writeLong(LAST_MANUAL_REPORT_KEY, now().toEpochMilliseconds())
    }

    internal suspend fun sendAutomatedProblemReport() = withContext(Dispatchers.Default) {
        var previousReportTime: Long? = null

        lock.use {
            if (!automaticErrorReporting) {
                this@ProblemReportService.logDebug("Automatic error reporting is disabled. Not sending automatic report")
                return@withContext
            }

            LocalStorage.readLong(LAST_AUTOMATED_REPORT_KEY)?.let { lastReportMillis ->
                previousReportTime = lastReportMillis

                val lastAutomatedReport = Instant.fromEpochMilliseconds(lastReportMillis)
                val hours = lastAutomatedReport.until(now(), DateTimeUnit.HOUR, TimeZone.UTC).toInt()
                if (hours < 8) {
                    this@ProblemReportService.logInfo("Not sending automated problem report. Last report was sent $hours hour(s) ago")
                    return@withContext
                }
            }

            // Write the key before we actually send the report, just so we can exit this lock
            // and not potentially tie up a lot of threads if a lot of crits happen in a row
            LocalStorage.writeLong(LAST_AUTOMATED_REPORT_KEY, now().toEpochMilliseconds())
        }

        try {
            Api.upload<Unit>("crash-report", "file", "crash-report.db", Database.getDbAsByteArray())
        } catch (e: Exception) {
            this@ProblemReportService.logError("Failed to send problem report. Reverting last problem report sent value")

            lock.use {
                previousReportTime?.let {
                    LocalStorage.writeLong(LAST_AUTOMATED_REPORT_KEY, it)
                } ?: LocalStorage.delete(LAST_AUTOMATED_REPORT_KEY)
            }
        }
    }

    // This isn't really very important to be precise. It is not user facing. So just keep it in-memory.
    internal var lastPopUpShown = Instant.fromEpochMilliseconds(0)

    internal fun showErrorDialog(error: String) {
        lock.use {
            val hours = lastPopUpShown.until(now(), DateTimeUnit.HOUR, TimeZone.UTC).toInt()
            if (hours < 2) {
                logInfo("User encountered a critical error, but the last popup was shown too recently. Not displaying this popup")
                return
            }
            lastPopUpShown = now()
        }

        val notifiedMsg = if (automaticErrorReporting) " Gorilla Groove staff have been notified." else ""

        val dialogEvent = DialogEventData(
            title = "Critical Error Encountered",
            message = "A critical error occurred.$notifiedMsg Show error message?",
            yesText = "Show",
            noText = "No thanks",
            yesAction = {
                val detailedDialogEvent = DialogEventData(
                    message = error,
                    noText = "Oof",
                )
                DialogEventBus.broadcast(detailedDialogEvent)
            },
        )

        DialogEventBus.broadcast(dialogEvent)
    }

    fun getLastProblemReportMessage(): String {
        val currentTime = now()

        val lastManualReport = LocalStorage.readLong(LAST_MANUAL_REPORT_KEY)?.let { Instant.fromEpochMilliseconds(it) }
        val lastAutomatedReport = LocalStorage.readLong(LAST_AUTOMATED_REPORT_KEY)?.let { Instant.fromEpochMilliseconds(it) }

        val lastManualReportMessage = lastManualReport?.let { "Your last manual problem report was sent ${it.toTimeAgoString(currentTime)}" }
        val lastAutomatedReportMessage = lastAutomatedReport?.let { "The last automated problem report was sent ${it.toTimeAgoString(currentTime)}" }

        return listOfNotNull(lastManualReportMessage, lastAutomatedReportMessage).joinToString(". ")
    }

    private fun Instant.toTimeAgoString(currentTimeMillis: Instant): String {
        val hours = this.until(currentTimeMillis, DateTimeUnit.HOUR, TimeZone.UTC).toInt()
        val timeString = when {
            hours < 1 -> "less than one hour"
            hours == 1 -> "one hour"
            hours < 24 -> "${hours.toEnglishWord()} hours"
            else -> {
                val daysAgo = hours / 24

                when {
                    daysAgo == 1 -> "one day"
                    daysAgo < 30 -> "${daysAgo.toEnglishWord()} days"
                    else -> "more than a month"
                }
            }
        }

        return "$timeString ago"
    }

    // Because I spent most of my life learning you're "supposed" to spell out numbers under 10 in most situations. Anal, sure. But here we are.
    private fun Int.toEnglishWord(): String {
        return when (this) {
            1 -> "one"
            2 -> "two"
            3 -> "three"
            4 -> "four"
            5 -> "five"
            6 -> "six"
            7 -> "seven"
            8 -> "eight"
            9 -> "nine"
            else -> this.toString()
        }
    }

    suspend fun getCrashReports(): List<CrashReport> {
        val responses = Api.get<CrashReportGetResponse>("crash-report").items

        val users = UserService.findAll().associateBy { it.id }

        return responses.map { it.toCrashReport(users) }
    }

    suspend fun getCrashReportLog(id: CrashReportId): String {
        return Api.get<CrashReportLogResponse>("crash-report/${id.value}/log").deviceLog
    }

    suspend fun getCrashReportDatabase(id: CrashReportId): DownloadResponse {
        return Api.download(Api.BASE_URL + "crash-report/${id.value}/db")
    }

    suspend fun deleteCrashReport(id: CrashReportId) {
        Api.delete<Unit>("crash-report/${id.value}")
    }
}

@Suppress("unused") // This stuff is used in tests, but indirectly due to the weird nature of multipart uploads.
@Serializable
private class CrashReportMetadata(
    val deviceType: DeviceType,
    val applicationVersion: String,
)

@Serializable
internal data class CrashReportResponse(
    val id: CrashReportId,
    val userId: UserId?,
    val version: String,
    val sizeKb: Int,
    val deviceType: RawDeviceType,
    val createdAt: Instant,
) {
    fun toCrashReport(users: Map<UserId, User>) = CrashReport(
        id = id,
        user = userId?.let { users[it] },
        version = version,
        sizeKb = sizeKb,
        deviceType = deviceType.asEnumeratedType(),
        createdAt = createdAt,
    )
}

data class CrashReport(
    val id: CrashReportId,
    val user: User?,
    val version: String,
    val sizeKb: Int,
    val deviceType: DeviceType,
    val createdAt: Instant,
) {
    val username: String get() = user?.name ?: "Login Report"
    val displayDate: String get() = Formatter.formatToUserDateFormat(createdAt)
}

@Serializable
internal class CrashReportGetResponse(val items: List<CrashReportResponse>)

@Serializable
internal class CrashReportLogResponse(val deviceLog: String)
