package net.gorillagroove.util

import com.juul.indexeddb.Database
import com.juul.indexeddb.KeyPath
import com.juul.indexeddb.openDatabase
import net.gorillagroove.db.PlatformDatabase
import net.gorillagroove.track.TrackLinkType
import org.khronos.webgl.Int8Array

object IndexedDb {
    suspend fun getDatabase(): Database {
        return openDatabase("groove.db", 1) { database, oldVersion, _ ->
            if (oldVersion < 1) {
                database.createObjectStore(PlatformDatabase.INDEXED_DB_KEY, KeyPath("id"))
                database.createObjectStore(TrackLinkType.AUDIO_OGG.name, KeyPath("id"))
                database.createObjectStore(TrackLinkType.AUDIO_MP3.name, KeyPath("id"))
                database.createObjectStore(TrackLinkType.ART_PNG.name, KeyPath("id"))
                database.createObjectStore(TrackLinkType.THUMBNAIL_PNG.name, KeyPath("id"))
            }
        }
    }
}

external interface IndexedDbByteData {
    var id: Int
    var byteData: Int8Array
}

inline fun <T> jsObject(init: dynamic.() -> Unit): T {
    val o = js("{}")
    init(o)
    return o as T
}

