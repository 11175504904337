package net.gorillagroove

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
internal object GGCommonInternal {
    var isIntegrationTesting = false

    // This is hella dumb, but I have serious issues with test stability when I save stuff to the
    // indexDb in JS tests everywhere, so I turn it off for tests. But this test is testing to make
    // sure that this actually works, so I turn integration testing off just for this test. I'm lazy.
    // I'm tired of dealing with this. It's been like 5 hours of fuckery. I'm done.
    var integrationTestDatabaseSave = false
}
