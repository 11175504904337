package net.gorillagroove.review

import kotlinx.datetime.Instant
import net.gorillagroove.api.ReviewSourceId
import net.gorillagroove.db.DbReviewSource
import net.gorillagroove.sync.OfflineAvailabilityType


class ReviewSource(
    val id: ReviewSourceId,
    val sourceType: ReviewSourceType,
    val displayName: String,
    val offlineAvailability: OfflineAvailabilityType,
    val updatedAt: Instant
)

internal fun DbReviewSource.toReviewSource() = ReviewSource(id, sourceType.asEnumeratedType(), displayName, offlineAvailability.asEnumeratedType(), updatedAt)
