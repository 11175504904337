package components

import net.gorillagroove.api.*
import net.gorillagroove.authentication.AuthService

object SocketUtil {
    fun init() {
        ApiSocket.registerSocketEventHandler { event ->
            if (!AuthService.isAuthenticated()) {
                return@registerSocketEventHandler
            }

            when (event) {
                is ConnectionEstablishedSocketResponse, is ConnectionLostSocketResponse -> {
                    Footer.renderSocketConnectionIcon()
                    LeftNav.updateUserListenState()
                }
                is NowListeningSocketResponse -> {
                    LeftNav.updateUserListenState()
                }
                else -> {}
            }
        }
    }

    fun connect() {
        ApiSocket.connect {}
    }

    fun disconnect() {
        ApiSocket.disconnect()
    }
}
