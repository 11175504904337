package net.gorillagroove.sync


class SyncTask(
    val type: TaskQueueEntity,
    val explicitPriority: SyncPriority = SyncPriority.STANDARD,
    val syncDirection: SyncDirection,
    val dependentTasks: MutableList<SyncTask> = mutableListOf(),
) {
    // The priority of a sync task is sorted with 3 criteria
    // 1) An explicit high priority task takes precedent over one that isn't
    // 2) An explicit high priority sync entity takes precedent over one that isn't
    // 3) The larger the quantity of dependent tasks, the higher the priority
    val totalPriority: Int
        get() {
            val ownPriority = (explicitPriority.value shl 16) + (type.implicitPriority.value shl 8)

            // If we have a dependent with a higher priority than we have, then we need to use that
            // priority as our own. However, we need to add the number of dependent tasks as a tiebreaker
            // so that we are synced before our dependents are. You could add +1 instead and it would
            // be okay, but in the event of ties, we want the things with more dependents to sync first
            // as they are blocking a larger number of things that could be in the way of multi-threading
            return maxOf(ownPriority, highestDependentPriority) + dependentTasks.size
        }

    private val highestDependentPriority get() = dependentTasks.maxOfOrNull { it.totalPriority } ?: 0

    fun supports(syncDirection: SyncDirection): Boolean {
        return this.syncDirection == syncDirection || this.syncDirection == SyncDirection.BOTH
    }

    override fun toString(): String {
        return "SyncTask($type $syncDirection ($explicitPriority))"
    }
}
