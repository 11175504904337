package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.datetime.Instant
import net.gorillagroove.api.*


internal object SyncStatusIdAdapter : ColumnAdapter<SyncStatusId, Long> {
    override fun decode(databaseValue: Long) = SyncStatusId(databaseValue)
    override fun encode(value: SyncStatusId) = value.value
}
internal object FailedListenIdAdapter : ColumnAdapter<FailedListenId, Long> {
    override fun decode(databaseValue: Long) = FailedListenId(databaseValue)
    override fun encode(value: FailedListenId) = value.value
}
internal object TrackIdAdapter : ColumnAdapter<TrackId, Long> {
    override fun decode(databaseValue: Long) = TrackId(databaseValue)
    override fun encode(value: TrackId) = value.value
}
internal object TrackApiIdAdapter : ColumnAdapter<TrackApiId, Long> {
    override fun decode(databaseValue: Long) = TrackApiId(databaseValue)
    override fun encode(value: TrackApiId) = value.value
}
internal object UserIdAdapter : ColumnAdapter<UserId, Long> {
    override fun decode(databaseValue: Long) = UserId(databaseValue)
    override fun encode(value: UserId) = value.value
}
internal object PlaylistIdAdapter : ColumnAdapter<PlaylistId, Long> {
    override fun decode(databaseValue: Long) = PlaylistId(databaseValue)
    override fun encode(value: PlaylistId) = value.value
}
internal object PlaylistTrackIdAdapter : ColumnAdapter<PlaylistTrackId, Long> {
    override fun decode(databaseValue: Long) = PlaylistTrackId(databaseValue)
    override fun encode(value: PlaylistTrackId) = value.value
}
internal object PlaylistUserIdAdapter : ColumnAdapter<PlaylistUserId, Long> {
    override fun decode(databaseValue: Long) = PlaylistUserId(databaseValue)
    override fun encode(value: PlaylistUserId) = value.value
}
internal object ReviewSourceIdAdapter : ColumnAdapter<ReviewSourceId, Long> {
    override fun decode(databaseValue: Long) = ReviewSourceId(databaseValue)
    override fun encode(value: ReviewSourceId) = value.value
}
internal object LogLineIdAdapter : ColumnAdapter<LogLineId, Long> {
    override fun decode(databaseValue: Long) = LogLineId(databaseValue)
    override fun encode(value: LogLineId) = value.value
}
internal object UserSettingIdAdapter : ColumnAdapter<UserSettingId, Long> {
    override fun decode(databaseValue: Long) = UserSettingId(databaseValue)
    override fun encode(value: UserSettingId) = value.value
}
internal object UserFavoriteIdAdapter : ColumnAdapter<UserFavoriteId, Long> {
    override fun decode(databaseValue: Long) = UserFavoriteId(databaseValue)
    override fun encode(value: UserFavoriteId) = value.value
}
internal object UserPermissionIdAdapter : ColumnAdapter<UserPermissionId, Long> {
    override fun decode(databaseValue: Long) = UserPermissionId(databaseValue)
    override fun encode(value: UserPermissionId) = value.value
}
internal object UserSettingApiIdAdapter : ColumnAdapter<UserSettingApiId, Long> {
    override fun decode(databaseValue: Long) = UserSettingApiId(databaseValue)
    override fun encode(value: UserSettingApiId) = value.value
}
internal object InstantAdapter : ColumnAdapter<Instant, Long> {
    override fun decode(databaseValue: Long) = Instant.fromEpochMilliseconds(databaseValue)
    override fun encode(value: Instant) = value.toEpochMilliseconds()
}
