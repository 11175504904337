package net.gorillagroove.db

import kotlin.Long
import kotlin.String

public data class GetTotalBytesRequiredForFullCache(
  public val bytes: Long?
) {
  public override fun toString(): String = """
  |GetTotalBytesRequiredForFullCache [
  |  bytes: $bytes
  |]
  """.trimMargin()
}
