import components.SocketUtil
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.NowPlayingService
import net.gorillagroove.track.OfflineModeService
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

object AuthUtil {
    private var setupRun = false

    fun setupAuthenticatedUser() {
        if (!setupRun) {
            SocketUtil.init()
            setupMediaSession()

            setupRun = true
        }

        SocketUtil.connect()

        mainScope.launch {
            delay(4.seconds)
            SyncCoordinator.sync(throttle = 1.minutes)

            OfflineModeService.downloadAlwaysOfflineTracks()
        }
    }

    fun beforeLogout() {
        SocketUtil.disconnect()
    }
}

private fun setupMediaSession() {
    // mediaSession is not defined on the Kotlin navigator class at this time....
    val mediaSession = js("""window.navigator.mediaSession""") as MediaSession

    mediaSession.setActionHandler("play") { NowPlayingService.resume() }
    mediaSession.setActionHandler("pause") { NowPlayingService.pause() }
    mediaSession.setActionHandler("stop") { NowPlayingService.pause() }
    mediaSession.setActionHandler("previoustrack") { NowPlayingService.playPrevious() }
    mediaSession.setActionHandler("nexttrack") { NowPlayingService.playNext() }
}

external class MediaSession {
    fun setActionHandler(type: String, callback: () -> Unit)
}
