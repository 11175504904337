@file:Suppress("FunctionName")

package components

import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.reporting.CrashReport
import net.gorillagroove.reporting.ProblemReportService
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onClickSuspend
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.HTMLUListElement
import org.w3c.dom.asList
import query
import queryId
import show
import util.ByteUtil.download

fun CrashReportModal() = document.create.div {
    id = "crash-report-modal"

    h3 { + "Crash Reports" }

    fun DIV.crashReportLabel(label: String, id: String) {
        label {
            + "$label:"
            span {
                this.id = id
            }
        }
    }

    div("crash-report-split") {
        ul("bordered") {

        }

        div("flex-column bordered") {
            div("crash-report-details-section mt-6") {
                crashReportLabel("ID", "current-crash-report-id")
                crashReportLabel("User", "current-crash-report-user")
                crashReportLabel("Device", "current-crash-report-device")

                div {
                    button(classes = "primary slim") {
                        +"Download DB"

                        onClickSuspend = onClick@{
                            val response = try {
                                ProblemReportService.getCrashReportDatabase(CrashReports.currentReport!!.id)
                            } catch (e: Exception) {
                                Toast.error("Failed to download crash report")
                                GGLog.logError("Failed to download crash report!", e)
                                return@onClick
                            }

                            response.data.download(response.filename)
                        }
                    }
                }
            }
            div("crash-report-details-section") {
                crashReportLabel("Size", "current-crash-report-size")
                crashReportLabel("Reported", "current-crash-report-created")
                crashReportLabel("Version", "current-crash-report-version")

                div {
                    button(classes = "danger slim") {
                        +"Delete"

                        onClickSuspend = onClick@{
                            try {
                                ProblemReportService.deleteCrashReport(CrashReports.currentReport!!.id)

                                CrashReports.loadCrashReports()
                            } catch (e: Exception) {
                                Toast.error("Failed to delete crash report")
                                GGLog.logError("Failed to delete crash report!", e)
                                return@onClick
                            }
                        }
                    }
                }
            }
            div("p-relative flex-grow") {
                textArea {
                    id = "current-crash-report-log"
                    readonly = true
                }
                LoadingSpinner(id = "crash-log-loading-spinner", classes = "full-center")
            }
        }
    }

    mainScope.launch {
        CrashReports.loadCrashReports()
    }
}

private object CrashReports {
    var currentReport: CrashReport? = null

    private val list get() = document.query<HTMLUListElement>("#crash-report-modal ul")

    suspend fun loadCrashReports() {
        val reports = ProblemReportService.getCrashReports()

        if (reports.isNotEmpty()) {
            currentReport = reports.first()
            loadCrashReport(currentReport!!)
        }

        val list = list
        list.innerHTML = ""
        reports.forEachIndexed { index, report ->
            list.append {
                val classes = if (report.id == currentReport?.id) "selected" else ""
                li(classes) {
                    + "${report.username} (${report.displayDate})"

                    onClickFunction = {
                        selectCrashReport(report, index)
                    }
                }
            }
        }
    }

    suspend fun loadCrashReport(report: CrashReport) {
        document.queryId<HTMLElement>("current-crash-report-id").innerText = report.id.value.toString()
        document.queryId<HTMLElement>("current-crash-report-user").innerText = report.username
        document.queryId<HTMLElement>("current-crash-report-device").innerText = report.deviceType.name
        document.queryId<HTMLElement>("current-crash-report-size").innerText = "${report.sizeKb} kb"
        document.queryId<HTMLElement>("current-crash-report-created").innerText = report.displayDate
        document.queryId<HTMLElement>("current-crash-report-version").innerText = report.version

        val textarea = document.queryId<HTMLTextAreaElement>("current-crash-report-log")
        textarea.value = ""

        val spinner = document.queryId<HTMLElement>("crash-log-loading-spinner")
        spinner.show()

        val log = try {
            ProblemReportService.getCrashReportLog(report.id)
        } catch (e: Exception) {
            spinner.hide()
            Toast.error("Failed to fetch crash report log")
            return
        }

        spinner.hide()

        textarea.value = log
        textarea.scrollTop = textarea.scrollHeight.toDouble()
    }

    fun selectCrashReport(report: CrashReport, index: Int) {
        currentReport = report

        list.querySelectorAll(".selected").asList().forEach { node ->
            (node as HTMLElement).classList.remove("selected")
        }

        (list.childNodes.asList()[index] as HTMLElement).classList.add("selected")

        mainScope.launch {
            loadCrashReport(report)
        }
    }
}

