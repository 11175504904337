package net.gorillagroove.util

import kotlinx.coroutines.*
import kotlinx.datetime.Instant
import net.gorillagroove.util.TimeUtil.now
import kotlin.time.Duration

private val activeThrottles = mutableMapOf<Any, Instant>()
private val activeDebounces = mutableMapOf<Any, Job>()

private val lock = Lock()

// A "throttle" is when you want your action to happen immediately,
// but then any other attempt to do the same thing will be ignored for a period of time
fun throttle(key: Any, interval: Duration, handler: () -> Unit) = lock.use {
    val now = now()
    val lastAction = activeThrottles[key] ?: now

    if (now >= lastAction) {
        handler()
        activeThrottles[key] = now + interval
    } else {
        return@use
    }

    // Clean up any old throttles that may be expired.
    // Because we are holding onto weak references, this REALLY isn't a big deal. But may as well, right?
    // 4 bytes for a reference, and 8 bytes for the Long, probably a small amount more for the map implementation.
    // Only check if > 1, as the throttle we just added is of course, still valid
    if (activeThrottles.size > 1) {
        val expiredThrottles = activeThrottles.filterValues { it < now }.keys
        expiredThrottles.forEach { activeThrottles.remove(it) }
    }
}

private val coroutineScope = CoroutineScope(Dispatchers.Default)

// A "debounce" is when you want your action to not happen right away, but rather,
// when a period of time has elapsed since the last time an action was requested.
fun debounce(key: Any, interval: Duration, handler: suspend () -> Unit) = lock.use {
    activeDebounces[key]?.cancel()

    val job = coroutineScope.launch {
        delay(interval.inWholeMilliseconds)
        handler()
    }
    activeDebounces[key] = job

    if (activeDebounces.size > 1) {
        val expiredDebounces = activeDebounces.filterValues { it.isCancelled || it.isCompleted }.keys
        expiredDebounces.forEach { activeDebounces.remove(it) }
    }
}
