package net.gorillagroove.util

import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.*


// I find it tragic that kotlinx.serialization still does not let you serialize maps of Json.
// But this hero made something that seems reasonable to me:
// https://github.com/Kotlin/kotlinx.serialization/issues/746#issuecomment-791807112
fun Any?.toJsonElement(): JsonElement = when (this) {
    is Number -> JsonPrimitive(this)
    is Boolean -> JsonPrimitive(this)
    is String -> JsonPrimitive(this)
    is Array<*> -> this.toJsonArray()
    is List<*> -> this.toJsonArray()
    is Map<*, *> -> this.toJsonObject()
    is JsonElement -> this
    else -> JsonNull
}

fun Array<*>.toJsonArray() = JsonArray(map { it.toJsonElement() })
fun Iterable<*>.toJsonArray() = JsonArray(map { it.toJsonElement() })
fun Map<*, *>.toJsonObject() = JsonObject(mapKeys { it.key.toString() }.mapValues { it.value.toJsonElement() })

fun Map<String, Any>.toJsonString() = Json.encodeToString(this.toJsonElement())

val JsonElement.string get(): String {
    return (this as JsonPrimitive).contentOrNull!!
}
val JsonElement.long get(): Long {
    return (this as JsonPrimitive).longOrNull!!
}
