package net.gorillagroove.util

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job

internal object CoroutineUtil {
    @Suppress("FunctionName")
    fun CancelledJob() = Job().also { job ->
        job.cancel(CancellationException("Conditions for starting the Job were invalid"))
    }
}