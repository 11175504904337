package net.gorillagroove.localstorage

import net.gorillagroove.authentication.LoginResponse
import net.gorillagroove.api.UserId
import net.gorillagroove.util.Seconds

const val ONE_YEAR: Seconds = 31_536_000

internal actual object CurrentUserStore {
    actual fun setInfo(response: LoginResponse) {
        SiteCookies.addCookie("cookieToken", response.token, ONE_YEAR)
        SiteCookies.addCookie("loggedInEmail", response.email, ONE_YEAR)
        SiteCookies.addCookie("loggedInUserId", response.id.value.toString(), ONE_YEAR)
    }

    actual fun getInfo(): CurrentUserInfo? {
        val token = SiteCookies.getCookieValue("cookieToken") ?: return null
        val email = SiteCookies.getCookieValue("loggedInEmail") ?: return null
        val id = SiteCookies.getCookieValue("loggedInUserId")?.toLong() ?: return null

        return CurrentUserInfo(UserId(id), email, token)
    }

    actual fun clearInfo() {
        SiteCookies.deleteCookie("cookieToken")
        SiteCookies.deleteCookie("loggedInEmail")
        SiteCookies.deleteCookie("loggedInUserId")
    }
}
