package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Double
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.FailedListenId
import net.gorillagroove.api.TrackApiId

public data class DbFailedListen(
  public val id: FailedListenId,
  public val trackId: TrackApiId,
  public val timeListenedAt: Instant,
  public val ianaTimezone: String,
  public val latitude: Double?,
  public val longitude: Double?
) {
  public override fun toString(): String = """
  |DbFailedListen [
  |  id: $id
  |  trackId: $trackId
  |  timeListenedAt: $timeListenedAt
  |  ianaTimezone: $ianaTimezone
  |  latitude: $latitude
  |  longitude: $longitude
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<FailedListenId, Long>,
    public val trackIdAdapter: ColumnAdapter<TrackApiId, Long>,
    public val timeListenedAtAdapter: ColumnAdapter<Instant, Long>
  )
}
