package net.gorillagroove.track

import net.gorillagroove.db.DbTrack
import net.gorillagroove.hardware.PlatformDeviceUtil


// It's kind of annoying that there is no way for us to add functions onto the DB entities because they
// are all generated by SqlDelight. So here's where all those functions go instead.

internal val DbTrack.filesizeAudio get(): Int {
    return when (PlatformDeviceUtil.getDefaultAudioFormat()) {
        AudioFormat.OGG -> this.filesizeAudioOgg
        AudioFormat.MP3 -> this.filesizeAudioMp3
    }
}

internal val DbTrack.bytesNeedingDownload: Int get() {
    val audioBytes = if (this.audioCachedAt == null) this.filesizeAudio else 0
    val artBytes = if (this.artCachedAt == null) this.filesizeArtPng else 0
    val thumbnailBytes = if (this.thumbnailCachedAt == null) this.filesizeThumbnailPng else 0

    return audioBytes + artBytes + thumbnailBytes
}

internal val DbTrack.filesizeTotal get() = filesizeAudio + filesizeArtPng + filesizeThumbnailPng
internal val DbTrack.totalCachedBytes: Int get() = filesizeTotal - bytesNeedingDownload

internal fun DbTrack.hasDataForType(trackLinkType: TrackLinkType): Boolean {
    return when (trackLinkType) {
        TrackLinkType.AUDIO_MP3 -> this.filesizeAudioMp3 > 0
        TrackLinkType.AUDIO_OGG -> this.filesizeAudioOgg > 0
        TrackLinkType.ART_PNG -> this.filesizeArtPng > 0
        TrackLinkType.THUMBNAIL_PNG -> this.filesizeThumbnailPng > 0
    }
}
