package net.gorillagroove.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Long
import kotlin.String
import kotlinx.datetime.Instant
import net.gorillagroove.api.PlaylistId
import net.gorillagroove.api.PlaylistUserId
import net.gorillagroove.api.UserId
import net.gorillagroove.playlist.RawPlaylistOwnershipType

public data class DbPlaylistUser(
  public val id: PlaylistUserId,
  public val playlistId: PlaylistId,
  public val userId: UserId,
  public val ownershipType: RawPlaylistOwnershipType,
  public val createdAt: Instant,
  public val updatedAt: Instant
) {
  public override fun toString(): String = """
  |DbPlaylistUser [
  |  id: $id
  |  playlistId: $playlistId
  |  userId: $userId
  |  ownershipType: $ownershipType
  |  createdAt: $createdAt
  |  updatedAt: $updatedAt
  |]
  """.trimMargin()

  public class Adapter(
    public val idAdapter: ColumnAdapter<PlaylistUserId, Long>,
    public val playlistIdAdapter: ColumnAdapter<PlaylistId, Long>,
    public val userIdAdapter: ColumnAdapter<UserId, Long>,
    public val ownershipTypeAdapter: ColumnAdapter<RawPlaylistOwnershipType, String>,
    public val createdAtAdapter: ColumnAdapter<Instant, Long>,
    public val updatedAtAdapter: ColumnAdapter<Instant, Long>
  )
}
