package net.gorillagroove.track

import com.juul.indexeddb.Key
import net.gorillagroove.api.TrackId
import net.gorillagroove.db.asByteArray
import net.gorillagroove.db.asInt8Array
import net.gorillagroove.util.IndexedDb
import net.gorillagroove.util.IndexedDbByteData
import net.gorillagroove.util.jsObject

internal actual object PlatformTrackCacheService {

    actual suspend fun saveTrackDataToDisk(trackId: TrackId, data: ByteArray, cacheType: TrackLinkType) {
        val database = IndexedDb.getDatabase()
        database.writeTransaction(cacheType.name) {
            val store = objectStore(cacheType.name)

            // VERY IMPORTANT to make this an INT! If you don't, it serializes
            // as this bullshit:   "id":{"low_1":5,"high_1":0}
            val id = trackId.value.toInt()

            val jsData = jsObject<IndexedDbByteData> {
                this.id = id
                byteData = data.asInt8Array()
            }

//            logInfo(JSON.stringify(jsData))

            // Use .put and not .add as .add will throw an error if something already exists.
            // This really shouldn't happen, but can be inconvenient for local development
            // and doing a PUT is likely more resilient to other programming errors.
            store.put(jsData)
        }
    }

    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    actual suspend fun getCachedTrackData(trackId: TrackId, cacheType: TrackLinkType): ByteArray? {
        val database = IndexedDb.getDatabase()
        return database.transaction(cacheType.name) {
            val store = objectStore(cacheType.name)

            store.get(Key(trackId.value.toInt())) as? IndexedDbByteData
        }?.byteData?.asByteArray()
    }

    actual suspend fun deleteCachedTrackData(trackId: TrackId, cacheType: TrackLinkType) {
        val database = IndexedDb.getDatabase()
        database.writeTransaction(cacheType.name) {
            val store = objectStore(cacheType.name)
            store.delete(Key(trackId.value.toInt()))
        }
    }

    actual suspend fun deleteAllData() {
        val database = IndexedDb.getDatabase()
        TrackLinkType.entries.forEach { cacheType ->
            database.writeTransaction(cacheType.name) {
                val store = objectStore(cacheType.name)
                store.clear()
            }
        }
    }
}
