package net.gorillagroove.sync.strategies

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.UserPermissionId
import net.gorillagroove.db.Database
import net.gorillagroove.db.Database.userPermissionDao
import net.gorillagroove.db.DbSyncStatus
import net.gorillagroove.db.DbUserPermission
import net.gorillagroove.sync.*
import net.gorillagroove.sync.SyncDownStrategy
import net.gorillagroove.sync.fetchSyncEntities
import net.gorillagroove.user.permission.RawUserPermissionType

object UserPermissionSyncStrategy : SyncDownStrategy {
    override val syncType: SyncableEntity = SyncableEntity.USER_PERMISSION

    override suspend fun syncDown(syncStatus: DbSyncStatus, onPageSyncedHandler: PageSyncHandler) {
        fetchSyncEntities<UserPermissionResponse>(syncType, syncStatus, onPageSyncedHandler) { changeSet ->
            Database.db.transaction {
                changeSet.newAndModified.forEach {
                    val setting = it.asDbRecord()
                    userPermissionDao.upsert(setting)
                }
                changeSet.removed.forEach {
                    userPermissionDao.delete(UserPermissionId(it))
                }
            }
        }
    }
}

@Serializable
internal data class UserPermissionResponse(
    val id: UserPermissionId,
    val permissionType: RawUserPermissionType,
    val createdAt: Instant,
) {
    fun asDbRecord() = DbUserPermission(
        id = id,
        type = permissionType,
        createdAt = createdAt,
    )
}
