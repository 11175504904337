@file:Suppress("FunctionName")

package components

import kotlinx.browser.document
import kotlinx.html.FlowContent
import kotlinx.html.div
import kotlinx.html.dom.create
import kotlinx.html.id
import kotlinx.html.js.div

// https://loading.io/css/
fun LoadingSpinner() = document.create.div("loading-spinner") { }

fun FlowContent.LoadingSpinner(id: String? = null, classes: String = "", hidden: Boolean = true) {
    val hiddenClass = if (hidden) "d-none" else ""
    div("loading-spinner $hiddenClass $classes") {
        if (id != null) {
            this.id = id
        }
    }
}
