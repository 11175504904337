@file:Suppress("FunctionName")

package components.components

import hide
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import net.gorillagroove.util.debounce
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import queryId
import show
import kotlin.reflect.KMutableProperty0
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

private var nextSearchId = 0

fun TagConsumer<*>.SearchInput(
    state: KMutableProperty0<String>,
    delay: Duration = 150.milliseconds,
    onSearch: (term: String) -> Unit,
) {
    val searchId = ++nextSearchId

    val searchClearId = "search-clear-button-$searchId"
    val searchInputId = "search-input-$searchId"

    fun searchClearButton() = document.queryId<HTMLElement>(searchClearId)
    fun searchInput() = document.queryId<HTMLInputElement>(searchInputId)

    div("input-group") {
        i("fa-solid fa-magnifying-glass")
        input {
            id = searchInputId

            placeholder = "Search"

            onInputFunction = { event ->
                val newValue = (event.currentTarget!! as HTMLInputElement).value
                state.set(newValue)

                if (newValue.isNotEmpty()) {
                    searchClearButton().show()
                } else {
                    searchClearButton().hide()
                }

                debounce(searchId, delay) {
                    onSearch(newValue)
                }
            }

            value = state.get()
        }

        val visibleClass = if (state.get().isNotBlank()) "" else "d-none"
        i("fa-solid fa-circle-xmark $visibleClass clickable") {
            id = searchClearId

            onClickFunction = {
                state.set("")

                searchClearButton().hide()
                searchInput().apply {
                    this.value = ""
                    this.focus()
                }

                onSearch("")
            }
        }
    }
}
