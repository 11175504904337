@file:Suppress("FunctionName")

package components

import Dialog
import Toast
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import mainScope
import net.gorillagroove.sync.OfflineAvailabilityType
import net.gorillagroove.track.MetadataService
import net.gorillagroove.track.MetadataUpdateState
import net.gorillagroove.track.Track
import net.gorillagroove.util.GGLog.logError
import onSubmitSuspend
import org.w3c.dom.*
import queryId
import show
import util.ByteUtil.asByteArray
import util.ByteUtil.displayOnElement
import kotlin.reflect.KMutableProperty0

private val albumArt get() = document.querySelector("#track-properties .album-art") as HTMLElement?
private val albumArtChanger get() = document.querySelector("#track-properties .album-art-changer") as HTMLElement

class TrackPropertiesModal(private val tracks: List<Track>) {

    private val state = MetadataService.createMetadataUpdateState(tracks)

    private fun INPUT.updateState(stateProperty: KMutableProperty0<String?>) {
        onInputFunction = {
            val input = (it.currentTarget as HTMLInputElement)
            stateProperty.set(input.value)
        }
    }
    private fun INPUT.updateStateInt(stateProperty: KMutableProperty0<Int?>) {
        onInputFunction = {
            val input = (it.currentTarget as HTMLInputElement)
            val intVal = if (input.value.isBlank()) -1 else input.value.toInt()
            stateProperty.set(intVal)
        }
    }
    private fun INPUT.updateStateBoolean(stateProperty: KMutableProperty0<Boolean?>) {
        onInputFunction = {
            val input = (it.currentTarget as HTMLInputElement)
            stateProperty.set(input.checked)
        }
    }

    fun render() = document.create.form {
        id = "track-properties"

        onSubmitSuspend = onSubmit@ {
            if (isActionButtonUpdating("track-property-save")) {
                return@onSubmit
            }

            actionButtonChangeState("track-property-save", isUpdating = true)

            try {
                state.updateMetadata()

                Toast.success("Metadata updated")
                Dialog.remove()
            } catch (e: Exception) {
                Toast.error("Failed to update metadata")
                logError("Failed to update metadata state!", e)

                actionButtonChangeState("track-property-save", isUpdating = false)
            }
        }

        h3 {
            + "Track Properties"
        }

        label("flex-label") {
            span { + "Name" }

            input(InputType.text, classes = "long-property") {
                id = "track-name"
                value = state.name ?: ""

                updateState(state::name)
            }
        }

        label("flex-label") {
            span { + "Artist" }

            input(InputType.text, classes = "long-property") {
                id = "track-artist"
                value = state.artist ?: ""

                updateState(state::artist)
            }
        }


        label("flex-label") {
            span { + "Featuring" }

            input(InputType.text, classes = "long-property") {
                id = "track-featuring"
                value = state.featuring ?: ""

                updateState(state::featuring)
            }
        }

        label("flex-label") {
            span { + "Album" }

            input(InputType.text, classes = "long-property") {
                id = "track-album"
                value = state.album ?: ""

                updateState(state::album)
            }
        }

        div("album-wrapper") {
            div {
                label("flex-label") {
                    span { +"Genre" }

                    input(InputType.text, classes = "medium-property") {
                        id = "track-genre"
                        value = state.genre ?: ""

                        updateState(state::genre)
                    }
                }

                label("flex-label") {
                    span { +"Track #" }

                    input(InputType.number, classes = "short-property") {
                        id = "track-number"
                        value = state.trackNumber?.toString() ?: ""

                        updateStateInt(state::trackNumber)
                    }
                }

                label("flex-label") {
                    span { +"Year" }

                    input(InputType.number, classes = "short-property") {
                        id = "track-year"
                        value = state.releaseYear?.toString() ?: ""

                        updateStateInt(state::releaseYear)
                    }
                }

                label("flex-label") {
                    span { + "Private" }

                    input(InputType.checkBox) {
                        id = "track-private"

                        updateStateBoolean(state::private)
                    }
                }

                label("flex-label") {
                    span { + "Hidden" }

                    input(InputType.checkBox) {
                        id = "track-hidden"

                        updateStateBoolean(state::hidden)
                    }
                }

                label("flex-label") {
                    span { + "Availability" }

                    select {
                        OfflineAvailabilityType.displayedTypes().forEach { type ->
                            option {
                                value = type.name
                                + type.displayName

                                selected = state.offlineAvailability == type
                            }
                        }

                        option {
                            hidden = true
                            disabled = true

                            selected = state.offlineAvailability == null
                        }

                        onChangeFunction = { event ->
                            val select = event.currentTarget as HTMLSelectElement
                            val type = OfflineAvailabilityType.valueOf(select.value)

                            state.offlineAvailability = type
                        }
                    }
                }

                hr { }

                div("flex-label") {
                    + "Crop art to square?"

                    input(InputType.radio) {
                        id = "crop-yes"
                        name = "crop"
                        value = "true"
                        checked = false

                        updateStateBoolean(state::cropToSquare)
                    }
                    label {
                        htmlFor = "crop-yes"

                        +"Yes"
                    }

                    input(InputType.radio) {
                        id = "crop-no"
                        name = "crop"
                        value = "false"
                        checked = true

                        updateStateBoolean(state::cropToSquare)
                    }
                    label {
                        htmlFor = "crop-no"

                        +"No"
                    }
                }
            }

            div("album-art p-relative") {
                onClickFunction = {
                    albumArtChanger.show()
                }

                div("album-art-changer full-center d-none") {
                    h4 { + "Upload art" }

                    div("space-around full-width") {
                        button(type = ButtonType.button, classes = "flat") {
                            +"From URL"

                            onClickFunction = {
                                Dialog.show(InputDialog("Album Art URL", "Enter") { url ->
                                    state.albumArtUrl = url
                                    state.newBinaryImageData = null
                                    albumArt!!.style.backgroundImage = "url(\"$url\")"
                                    albumArtChanger.hide()
                                })
                            }
                        }

                        button(type = ButtonType.button, classes = "flat") {
                            +"From file"

                            onClickFunction = {
                                document.queryId<HTMLInputElement>("track-properties-file-upload").click()
                            }

                            input(InputType.file, classes = "d-none") {
                                id = "track-properties-file-upload"
                                accept = "image/*"
                                onChangeFunction = { e ->
                                    val input = e.currentTarget as HTMLInputElement

                                    val file = input.files!!.asList().first()

                                    mainScope.launch {
                                        val bytes = file.asByteArray()
                                        bytes.displayOnElement(tracks.first(), albumArt!!, forceDisplay = true)
                                        state.newBinaryImageData = bytes
                                        state.albumArtUrl = null

                                        albumArtChanger.hide()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div {
            label {
                span("full-width d-block text-left") { + "Note" }

                textArea(classes = "full-width d-block") {
                    id = "track-note"
                    + (state.note ?: "")

                    onInputFunction = {
                        val input = (it.currentTarget as HTMLTextAreaElement)
                        state.note = input.value
                    }
                }
            }
        }

        div("mt-12") {
            ActionButton("track-property-save", "Save")
        }

        mainScope.launch {
            asyncSetup(tracks, state)
        }
    }
}

private suspend fun asyncSetup(tracks: List<Track>, state: MetadataUpdateState) {
    // For whatever reason we can't access this indeterminate property during first render
    val privateCheckbox = document.getElementById("track-private") as HTMLInputElement
    if (state.private == null) {
        privateCheckbox.indeterminate = true
    } else {
        privateCheckbox.checked = state.private!!
    }

    val hiddenCheckbox = document.getElementById("track-hidden") as HTMLInputElement
    if (state.hidden == null) {
        hiddenCheckbox.indeterminate = true
    } else {
        hiddenCheckbox.checked = state.hidden!!
    }

    val data = state.loadAlbumArt()
    val albumArt = albumArt ?: return
    data?.displayOnElement(tracks.first(), albumArt, forceDisplay = true)
}
