package net.gorillagroove.user

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.DeviceId
import net.gorillagroove.api.UserId
import net.gorillagroove.db.Database.userDao
import net.gorillagroove.db.many
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.RawDeviceType
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.localstorage.NotSignedInException

object DeviceService {
    fun getCurrentDeviceId(): String {
        return DeviceUtil.getDeviceId()
    }

    suspend fun findAll(): List<Device> {
        return Api.get<DeviceGetResponse>("device")
            .items
            .map { it.toDevice() }
            .sortedByDescending { it.lastListenedOn }
    }

    suspend fun renameDevice(id: DeviceId, newName: String) {
        val request = UpdateDeviceRequest(newName)
        Api.put<Unit>("device/update/${id.value}", request)
    }

    suspend fun mergeDevices(id: DeviceId, targetId: DeviceId) {
        val request = MergeDeviceRequest(id = id, targetId = targetId)
        Api.put<Unit>("device/merge", request)
    }

    suspend fun archiveDevice(id: DeviceId) {
        // I apparently wrote this endpoint such that you could un-archive something if you wanted to.
        // I've never written a UI that lets you actually do this, and I have no intention to start now.
        // But that is why this parameter of "true" is hardcoded into this request....
        val request = ArchiveDeviceRequest(archived = true)
        Api.put<Unit>("device/archive/${id.value}", request)
    }
}

@Serializable
internal data class DeviceGetResponse(val items: List<DeviceResponse>)

@Serializable
internal data class DeviceResponse(
    val id: DeviceId,
    val userId: Long,
    val userName: String,
    val deviceType: RawDeviceType,
    val deviceId: String,
    val deviceName: String,
    val applicationVersion: String,
    val lastIp: String,
    val additionalData: String? = null,
    val partyEnabledUntil: Instant? = null,
    val createdAt: Instant,
    val updatedAt: Instant,
    val lastListenedOn: Instant,
) {
    fun toDevice() = Device(
        id = id,
        userId = userId,
        username = userName,
        deviceType = deviceType.asEnumeratedType(),
        deviceId = deviceId,
        deviceName = deviceName,
        applicationVersion = applicationVersion,
        lastIp = lastIp,
        additionalData = additionalData,
        partyEnabledUntil = partyEnabledUntil,
        createdAt = createdAt,
        updatedAt = updatedAt,
        lastListenedOn = lastListenedOn,
    )
}

data class Device(
    val id: DeviceId,
    val userId: Long,
    val username: String,
    val deviceType: DeviceType,
    val deviceId: String,
    val deviceName: String,
    val applicationVersion: String,
    val lastIp: String,
    val additionalData: String? = null,
    val partyEnabledUntil: Instant? = null,
    val createdAt: Instant,
    val updatedAt: Instant,
    val lastListenedOn: Instant,
)

@Serializable
internal data class UpdateDeviceRequest(val deviceName: String)

@Serializable
internal data class MergeDeviceRequest(
    val id: DeviceId,
    val targetId: DeviceId,
)

@Serializable
internal data class ArchiveDeviceRequest(val archived: Boolean)
