package net.gorillagroove.authentication

import kotlinx.coroutines.*
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.ApiSocket
import net.gorillagroove.api.UserId
import net.gorillagroove.db.Database.failedListenDao
import net.gorillagroove.db.Database.logLineDao
import net.gorillagroove.db.Database.playlistDao
import net.gorillagroove.db.Database.playlistTrackDao
import net.gorillagroove.db.Database.playlistUserDao
import net.gorillagroove.db.Database.reviewSourceDao
import net.gorillagroove.db.Database.syncStatusDao
import net.gorillagroove.db.Database.trackDao
import net.gorillagroove.db.Database.userDao
import net.gorillagroove.db.Database.userFavoriteDao
import net.gorillagroove.db.Database.userPermissionDao
import net.gorillagroove.db.Database.userSettingDao
import net.gorillagroove.hardware.DeviceUtil
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.localstorage.CurrentUserStore
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.sync.SyncCoordinator
import net.gorillagroove.track.NowPlayingService
import net.gorillagroove.track.OfflineModeService
import net.gorillagroove.track.PlatformTrackCacheService
import net.gorillagroove.util.GGLog.logDebug
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo
import net.gorillagroove.util.SettingType
import net.gorillagroove.util.Settings

object AuthService {
    suspend fun login(email: String, password: String) {
        val request = LoginRequest(
            email = email.trim(),
            password = password.trim(),
            deviceId = DeviceUtil.getDeviceId(),
            preferredDeviceName = DeviceUtil.getDeviceName()?.trim(),
            version = VersionService.currentDeviceVersion,
            deviceType = DeviceUtil.getDeviceType()
        )
        val response: LoginResponse = Api.post("authentication/login", request)

        VersionService.setLastPostedVersion()
        CurrentUserStore.setInfo(response)
    }

    fun logout(): Job {
        logInfo("User is logging out")

        ApiSocket.disconnect()
        OfflineModeService.abortDownload()
        SyncCoordinator.abortSync()
        NowPlayingService.stop()
        LocalStorage.delete(SyncCoordinator.HAS_FIRST_SYNCED_KEY)
        Settings.setBoolean(SettingType.HAS_FIRST_SYNCED, false)

        // Because we are sending the network request async and then clearing out the auth token right after,
        // make sure to grab the auth token first.
        val authToken = authToken
		val job = CoroutineScope(Dispatchers.Default).launch {
			// We don't really want to block the logout. If the network request hangs, they still should be able to log out.
			// So launch it in a new scope and return the Job in case anybody actually cares to wait for it
            if (authToken != null) {
                try {
                    Api.post<Unit>("authentication/logout", authToken = authToken)
                } catch (e: Exception) {
                    this@AuthService.logError("Failed to send logout request")
                }
            }

            PlatformTrackCacheService.deleteAllData()
            // We just potentially deleted a bunch of data. Vacuum the database to reduce the size.
            logLineDao.vacuum()
		}

        logDebug("Deleting database except for logs")
        deleteUserDatabaseData()

        logDebug("Clearing logged in user info")
		CurrentUserStore.clearInfo()

		return job
    }

    // NGL this is not really a super "smart" way to do this, because it's now a point of maintenance.
    // The idea is that I want to delete everything EXCEPT for the log table. Logs should persist.
    // But now every time I add a new table I have to basically remember to add it here, and it's error-prone.
    // Oh well. At least it's easy? For now?
    private fun deleteUserDatabaseData() {
        failedListenDao.deleteAll()
        playlistTrackDao.deleteAll()
        playlistUserDao.deleteAll()
        playlistDao.deleteAll()
        trackDao.deleteAll()
        syncStatusDao.deleteAll()
        reviewSourceDao.deleteAll()
        userSettingDao.deleteAll()
        userFavoriteDao.deleteAll()
        userPermissionDao.deleteAll()
        userDao.deleteAll()
    }

    internal val authToken: String? get() = CurrentUserStore.getInfo()?.token

    fun isAuthenticated() = authToken != null
}

@Serializable
internal data class LoginResponse(
    val id: UserId,
    val token: String,
    val email: String,
    val username: String,
)

@Serializable
internal data class LoginRequest(
    val email: String,
    val password: String,
    val deviceId: String,
    val preferredDeviceName: String?,
    val version: String,
    val deviceType: DeviceType
)
