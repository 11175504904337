package net.gorillagroove.db

import kotlin.Long
import kotlin.String
import net.gorillagroove.api.ReviewSourceId

public data class GetNeedingReviewTrackCountByQueue(
  public val id: ReviewSourceId,
  public val count: Long
) {
  public override fun toString(): String = """
  |GetNeedingReviewTrackCountByQueue [
  |  id: $id
  |  count: $count
  |]
  """.trimMargin()
}
