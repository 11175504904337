package net.gorillagroove.authentication

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.gorillagroove.api.Api
import net.gorillagroove.api.VersionHistoryId
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.localstorage.LocalStorage
import net.gorillagroove.track.OfflineModeService
import net.gorillagroove.util.GGLog.logError
import net.gorillagroove.util.GGLog.logInfo

@Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
object VersionService {
    lateinit var currentDeviceVersion: String
        internal set

    private const val LAST_POSTED_VERSION_KEY = "last_posted_version"

    internal fun setLastPostedVersion() {
        LocalStorage.writeString(LAST_POSTED_VERSION_KEY, currentDeviceVersion)
    }

    internal fun currentVersionWasSent(): Boolean {
        val lastPostedVersion = LocalStorage.readString(LAST_POSTED_VERSION_KEY)
        return lastPostedVersion == currentDeviceVersion
    }

    internal suspend fun postDeviceVersionIfNeeded() {
        if (OfflineModeService.offlineModeEnabled || currentVersionWasSent()) {
            return
        }

        val lastPostedVersion = LocalStorage.readString(LAST_POSTED_VERSION_KEY)
        logInfo("The API hasn't been told that we are running version $currentDeviceVersion. Our last posted value was $lastPostedVersion. Updating API")

        try {
            Api.put<Unit>("device/version", UpdateDeviceVersionRequest(currentDeviceVersion))
            setLastPostedVersion()
            logInfo("Posted version $currentDeviceVersion to the API")
        } catch (e: Throwable) {
            logError("Could not update device version!", e)
        }
    }

    suspend fun getVersionHistory(deviceType: DeviceType, limit: Int = 20): List<VersionHistory> {
        val response = Api.get<VersionHistoryResponse>(
            url = "version/history/device-type/${deviceType.name}",
            params = mapOf("limit" to limit)
        )
        return response.items
    }

    suspend fun createVersionHistory(deviceType: DeviceType, version: String, notes: String): VersionHistory {
        val request = CreateVersionHistoryRequest(version = version, notes = notes)
        return Api.post<VersionHistory>("version/history/device-type/${deviceType.name}", request)
    }
}

@Serializable
internal data class UpdateDeviceVersionRequest(val version: String)

@Serializable
internal data class VersionHistoryResponse(
    val items: List<VersionHistory>
)

@Serializable
internal data class CreateVersionHistoryRequest(
    val version: String,
    val notes: String
)

@Serializable
data class VersionHistory(
    val id: VersionHistoryId,
    val version: String,
    val notes: String,
    val createdAt: Instant,
)
