package components.contextmenu

import addEventListener
import invisible
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.MouseEvent
import query
import setVisible
import visible
import kotlin.math.max

object ContextMenu {
    init {
        document.body!!.addEventListener("click") {
            closeExistingMenu()
        }
    }

    private var contextMenu: HTMLElement? = null

    fun closeExistingMenu() {
        contextMenu?.remove()
        contextMenu = null
    }

    fun open(event: MouseEvent, actions: List<ContextMenuOption>) {
        event.preventDefault()

        closeExistingMenu()

        val menu = document.create.div(classes = "popup") {
            id = "context-menu"

            ul {
                actions.forEach { item ->
                    li {
                        + item.name

                        onClickFunction = { event ->
                            event.stopPropagation()

                            item.action()

                            closeExistingMenu()
                        }
                    }
                }
            }
        }

        menu.appendAtPosition(event)

        contextMenu = menu
    }

    fun openCheckboxMenu(
        event: MouseEvent,
        checkedIndexes: Set<Int>,
        options: List<String>,
        onSelected: (index: Int, isChecked: Boolean) -> Unit
    ) {
        event.preventDefault()

        closeExistingMenu()

        val checkedIndexesState = checkedIndexes.toMutableSet()

        val menu = document.create.div(classes = "popup") {
            id = "context-menu"

            ul {
                options.forEachIndexed { index, item ->
                    li {
                        span("context-checkbox") {
                            val classes = if (checkedIndexes.contains(index)) "" else "invisible"
                            i("fa-solid fa-check $classes")
                        }
                        span {
                            + item
                        }

                        onClickFunction = { event ->
                            event.stopPropagation()

                            val wasChecked = checkedIndexesState.contains(index)
                            if (wasChecked) {
                                checkedIndexesState.remove(index)
                            } else {
                                checkedIndexesState.add(index)
                            }

                            val checkbox = (event.currentTarget as HTMLElement).query<HTMLElement>(".context-checkbox i")
                            checkbox.setVisible(!wasChecked)

                            onSelected(index, !wasChecked)
                        }
                    }
                }
            }
        }

        menu.style.left = event.clientX.toString() + "px"
        menu.style.top = event.clientY.toString() + "px"
        document.body!!.append(menu)

        contextMenu = menu
    }
}

fun HTMLElement.positionAtEvent(event: MouseEvent, corner: Corner = Corner.TOP_LEFT) {
    // Add a little bit extra (+ 3) to the height (and width) so it isn't RIGHT up against the edge of the screen.
    // It somehow gives me mild anxiety.
    val finalY = if (corner == Corner.TOP_LEFT) {
        val height = this.clientHeight + 3
        val top = event.clientY
        val adjustmentY = (height + top) - window.innerHeight
        top - max(adjustmentY, 0)
    } else {
        val height = this.clientHeight
        val top = event.clientY - height - 8
        // I technically should be adjusting for going UP too much as well as DOWN too much....
        // But I'll do that when it becomes a problem. Right now it isn't possible given where the tooltips are used.
        val adjustmentY = (height + top) - window.innerHeight
        top - max(adjustmentY, 0)
    }

    val width = this.clientWidth + 3
    val left = event.clientX
    val adjustmentX = (width + left) - window.innerWidth
    val finalX = left - max(adjustmentX, 0)

    this.style.left = "${finalX}px"
    this.style.top = "${finalY}px"
}

// Hide the menu. Append it so it renders and gets its true height / width.
// Then position it and un-invisible it. Otherwise, the menu can be seen "jumping"
fun HTMLElement.appendAtPosition(event: MouseEvent, corner: Corner = Corner.TOP_LEFT) {
    this.invisible()

    document.body!!.append(this)
    this.positionAtEvent(event, corner)

    this.visible()
}

enum class Corner {
    TOP_LEFT, BOTTOM_LEFT
}
