@file:Suppress("FunctionName")

package components

import Dialog
import hide
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.js.div
import kotlinx.html.js.onClickFunction
import mainScope
import net.gorillagroove.authentication.VersionService
import net.gorillagroove.hardware.DeviceType
import net.gorillagroove.util.GGLog
import net.gorillagroove.util.GGLog.logError
import onSubmitSuspend
import org.w3c.dom.*
import queryId
import show

fun ChangelogModal() = document.create.div("p-relative") {
    id = "changelog-modal"

    div {
        h3 { +"Changelog" }

        button(classes = "flat") {
            id = "draft-release-button"

            + "Draft release"

            onClickFunction = {
                Dialog.remove()
                Dialog.show(DraftReleaseModal())
            }
        }
    }

    hr {  }

    div("p-relative") {
        LoadingSpinner(id = "changelog-loading-spinner", classes = "full-center")
        ul {
            id = "changelog-changes-container"
        }
    }

    mainScope.launch {
        val spinner = document.queryId<HTMLElement>("changelog-loading-spinner")

        val changes = try {
            spinner.show()
            VersionService.getVersionHistory(deviceType = DeviceType.WEB, limit = 20)
        } catch (e: Exception) {
            Toast.error("Failed to get changelog")
            GGLog.logError("Failed to get changelog!", e)
            spinner.hide()
            return@launch
        }
        spinner.hide()

        changes.forEach { history ->
            val container = document.queryId<HTMLUListElement>("changelog-changes-container")
            container.append {
                li {
                    h3 {
                        + history.version
                    }
                    p {
                        + history.notes
                    }
                }
            }
        }
    }
}

fun DraftReleaseModal() = document.create.div {
    id = "draft-release-modal"

    h3 {
        + "Draft Release"
    }

    form {
        onSubmitSuspend = onSubmit@{
            val select = document.queryId<HTMLSelectElement>("draft-release-device-type")

            val type = DeviceType.valueOf(select.value)
            val version = document.queryId<HTMLInputElement>("draft-release-version").value
            val notes = document.queryId<HTMLTextAreaElement>("draft-release-notes").value

            if (version.isBlank()) {
                Toast.info("Version is required")
                return@onSubmit
            }
            if (notes.isBlank()) {
                Toast.info("Version Notes are required")
                return@onSubmit
            }

            try {
                actionButtonChangeState("draft-release-button", isUpdating = true)
                VersionService.createVersionHistory(deviceType = type, version = version, notes = notes)

                Toast.success("Release notes created")
                Dialog.remove()
            } catch (e: Exception) {
                actionButtonChangeState("draft-release-button", isUpdating = false)
                Toast.error("Failed to create release notes")
                GGLog.logError("Failed to create release notes!", e)
            }
        }

        div("space-between") {
            label {
                + "Device type"
            }

            select {
                id = "draft-release-device-type"

                DeviceType.getSelectableTypes().forEach { deviceType ->
                    option {
                        value = deviceType.name
                        selected = deviceType == DeviceType.WEB

                        + deviceType.displayName
                    }
                }
            }
        }

        div("space-between mt-12") {
            label {
                + "Version"
            }

            input(InputType.text) {
                id = "draft-release-version"

                value = VersionService.currentDeviceVersion
            }
        }

        div("mt-12") {
            div { + "Version Notes" }
            textArea {
                id = "draft-release-notes"
            }
        }

        div("mt-12") {
            ActionButton(id = "draft-release-button", text = "Submit")
        }
    }
}
