package net.gorillagroove.favorite

import kotlinx.datetime.Instant
import net.gorillagroove.api.UserFavoriteId
import net.gorillagroove.db.DbUserFavorite
import net.gorillagroove.sync.UserFavoriteType

class UserFavorite(
    val id: UserFavoriteId,
    val type: UserFavoriteType,
    val value: String,
    val createdAt: Instant,
    val updatedAt: Instant,
)

internal fun DbUserFavorite.toUserFavorite() = UserFavorite(id, type.asEnumeratedType(), value_, createdAt, updatedAt)
