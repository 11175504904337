package net.gorillagroove.discovery

import kotlinx.serialization.Serializable
import net.gorillagroove.api.TrackApiId
import net.gorillagroove.sync.strategies.TrackResponse

@Serializable
data class MetadataSearchResponse(val items: List<MetadataResponseDTO>)

@Serializable
data class MetadataResponseDTO(
    val sourceId: String, // This would be, for example, the Spotify ID of the track
    val name: String,
    val artist: String,
    val album: String,
    val releaseYear: Int,
    val trackNumber: Int,
    val albumArtLink: String?,
    val length: Int,
    val previewUrl: String? // Not all tracks have this. Quite a few don't, actually
) {
    fun toImportRequest(artistQueueName: String?) = MetadataImportRequest(
        name = name,
        artist = artist,
        album = album,
        releaseYear = releaseYear,
        trackNumber = trackNumber,
        albumArtLink = albumArtLink,
        length = length,
        addToReview = artistQueueName != null,
        artistQueueName = artistQueueName
    )
}

@Serializable
data class AutocompleteResult(val suggestions: List<String>)

@Serializable
internal data class MultiTrackIdRequest(val trackIds: List<TrackApiId>)

@Serializable
data class MultiTrackResponse(val items: List<TrackResponse>)

@Serializable
internal data class BackgroundTaskResponseInternal(private val items: List<BackgroundTaskItemResponse>) {
    fun getConvertedItems(): List<BackgroundTaskItem> {
        return items.map { it.asItem() }
    }

    fun asPublic(): BackgroundTaskResponse {
        return BackgroundTaskResponse(items = getConvertedItems())
    }
}

data class BackgroundTaskResponse(val items: List<BackgroundTaskItem>)
